import { Link } from 'react-router-dom';
import './RegExpressHero.scss'
import { IoIosArrowDropleft, IoIosArrowDropright} from 'react-icons/io'
 import slider1 from "Assets/Images/tinified/regex-1-img-hero-slider.jpg" 
 import slider2 from "../../../../../Assets/Images/regex-2-img-hero-slider.png";
 import slider3 from "Assets/Images/tinified/regex-3-img-hero-slider.jpg";
import { vehicleServiceType } from '@/Models';
import { Key, useEffect, useState } from 'react';
import { vehicleService } from 'Services'
   
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';
// <LazyLoadImage
//       effect="blur"
//       src="image.jpg"
//       alt="Description"
//     />

const RegExpressHero = () => {

    const [serviceList, setServiceList] = useState<Array<vehicleServiceType>>()
    const [request, setRequest] = useState(false)
    
    const getList = async () => {
        setRequest(true)
       await vehicleService.serviceList().then((response:{ data: {data: Array<vehicleServiceType>}})=>{
          console.log("service response", response.data)
          setServiceList(response.data.data)  
          setRequest(false)
       }, error =>{
        console.log("serviceList error", error.response)
        setRequest(false) 
       })
    }

    useEffect(() => {
        getList()
    }, [])

    useEffect(() => {
        const carouselElement = document.querySelector('#carouselExampleCaptions');
        if (carouselElement) {
            const carousel = new (window as any).bootstrap.Carousel(carouselElement, {
                interval: 3000,
                ride: 'carousel'
            });
        }
    }, [serviceList]);
    
    return (
        <div className="container-fluid RegExpressHero">
            
            <div className='h-100'>
                <div id="carouselExampleCaptions" data-bs-interval="3000" className="carousel slide carousel-fade half-height-carousel h-100" data-bs-ride="carousel">
                    
                    <div className="carousel-inner">
                        {serviceList && serviceList.map((service: vehicleServiceType, index: Key) => {
                            return (
                                <div key={index} className={`carousel-item ${ index === 0 ? 'active' : ''}`}>
                                {/* <div key={index} className='carousel-item active'> */}
                                    <div className="overlay"></div>
                                    <img src={service.imageUrl} className="d-block w-100" alt="..."/> 
                                    <div className="carousel-caption w-100">
                                        <div className="container p-3">
                                            <h1 className="h1 hero-header mb-4">
                                                {service.name.split(' ')[0]} <span className='hero-header-style'>{service.name.split(' ').slice(1).join(' ')}</span> 
                                            </h1>
                                            <div className="hero-small-text mb-4">
                                                <div className="small-text">
                                                    {service.description}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                <Link className="nav-links mx-0 d-inline-block" to={`/registration-service/${service.uuid}`} role="button" aria-expanded="false">
                                                    <button className="btn hero-btn">Start {service.name}</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <div className="indicator-and-next-and-prev-main-container">
                        <div className="container"> 
                            <div className="carousel-indicators d-inline-block">
                                <div className="d-flex">
                                    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true"></li>
                                    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
                                    <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
                                </div>
                            </div> 
                            <div className="d-flex navig-icon-cont">
                                <div className="d-inline-block">
                                    <div className="d-flex"> 
                                        <button className="carousel-control-prev next-and-prev-btn me-4" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                            <IoIosArrowDropleft />
                                        </button>
                                        <button id="carousel-next-button" className="carousel-control-next next-and-prev-btn" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                            <IoIosArrowDropright />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>
    )
}

export default RegExpressHero;