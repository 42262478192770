
import { createVehicleInformationType } from "Models/vehicleServiceType"
import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
AxiosInterceptor()

export const vehicleService = {
   serviceList: async () => {
      return await axios.get(`/registration-services`)
   },  

   getServiceItem : async (service_id: string|undefined) => {
      return await axios.get(`/registration-services/${service_id}`)
   },
   
   serviceItem : async (service_id: string|undefined, limit:number, offset: number) => {
      return await axios.get(`/extra-service?id=${service_id}&limit=${limit}&offset=${offset}`)
   },
   
   serviceDocuments: async (serviceId: string|undefined) => {
      return await axios.put(`/documents/service`, {serviceId})
   }, 

   getMakeByTypeId: async (type_id: string) => {
      return await axios.get(`vehicle-makes/vehicle-type/${type_id}`)
   },

   getMake: async (limit:number, offset: number) => {
      return await axios.get(`/vehicle-makes`)
   },

   getModelsByMakeId: async (make_id: string) => {
      return await axios.get(`vehicle-models/vehicle-make/${make_id}`)
   },

   getModel: async (make_id:string, limit:number, offset: number)=>{
      return await axios.get(`/vehicle-models/${make_id}`)
   },

   getVehicleUse: async () => {
      return await axios.get(`/vehicle-use`)
   },

   getVehicleYear: async (limit:number, offset: number)=>{
      return await axios.get(`/vehicle-year`)
   },

   getVehicleClass: async (limit:number, offset: number)=>{
      // return await axios.get(`/vehicle-classes`)
      return await axios.get(`/vehicle-types`)
   },

   getVehicleColor: async (limit:number, offset: number)=>{
      return await axios.get(`/vehicle-color`)
   },
   getVehicleServices: async (limit:number, offset: number)=>{
      return await axios.get(`/extra-service?limit=${limit}&offset=${offset}`)
   },

   createVehicle : async (data: createVehicleInformationType[]) => {
      return await axios.post(`/vehicle`, data)
   },

   getVehicle : async (id:string, limit:number, offset: number) => {
      return await axios.get(`/vehicle?id=${id}&limit=${limit}&offset=${offset}`,)
   },

   getStates : async () => {
      return await axios.get(`/states`)
   },

   getLGAsByStateId : async (state_id: string) => {
      return await axios.get(`/local-governments/state/${state_id}`)
   },

   getDocumentsYouWillGet: async () => {
       return await axios.get('/registration-service-documents')
   }
}