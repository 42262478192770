import { driverLicenseRenewalType, driversLicenseRegistrationType } from "Models/driversLicenseRegistrationType"
import { vehicleRegistrationType } from "Models/vehicleRegistrationType"
import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
import { customizePlateType, dealersRegistrationType, guestUserType } from "Models"
import { registrationOrderFormType } from "Pages/Frontend/Interface/RegistrationService/RegistrationService"
import { OrderUploadedDocument } from "@/Models/orderUploadDocument"
AxiosInterceptor()

export const vehicleRegistrationService = {
    orderRegistrationService: async (data:registrationOrderFormType)=>{
        return await axios.post("order", data)
    }, 
    newVehicleRegistration: async (data:vehicleRegistrationType)=>{
        return await axios.post("vehicle/registration/new", data)
    }, 

    renewVehicleRegistration: async (data:vehicleRegistrationType)=>{
        return await axios.post("vehicle/registration/renewal", data)
    },

    changeOfOwnershipRegistration: async (data:vehicleRegistrationType)=>{
        return await axios.post("vehicle/registration/coo", data)
    },

    changeOfOwnershipDocument: async (data: Array<{
        registrationId: string|undefined,
        link: string|undefined,
        documentId: string|undefined
      }>)=>{
        return await axios.post("vehicle/registration/coo/document", data)
    },

    driversLicenseRegistration: async (data:driversLicenseRegistrationType)=>{
        console.log("driversLicenseRegistration", data)
        return await axios.post("drivers-license/registration", data)
    },

    getVehicleRegistration: async (ownerId: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`vehicle/registration/new?ownerId=${ownerId}&limit=${limit}&offset=${offset}`)
    },
    
    getCooRegistrationById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`vehicle/registration/coo?id=${id}&limit=${limit}&offset=${offset}`)
    },

    getVehicleRegistrationById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`vehicle/registration/new?id=${id}&limit=${limit}&offset=${offset}`)
    },
     
    getRenewalVehicleRegistrationById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`vehicle/registration/renewal?id=${id}&limit=${limit}&offset=${offset}`)
    },

    getNewVehicleRegistrationByVehicleInfoId: async (vehicleInformationId: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`vehicle/registration/new?vehicleInformationId=${vehicleInformationId}&limit=${limit}&offset=${offset}`)
    },

    dealersRegistration: async (data:dealersRegistrationType)=>{
        return await axios.post("plate-number/registration/dealer", data)
    },

    getDealershipRegistrationById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`plate-number/registration/dealer?id=${id}&limit=${limit}&offset=${offset}`)
    },
    getDriversRegistrationById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`drivers-license/registration?id=${id}&limit=${limit}&offset=${offset}`)
    },

    customizePlateNumber: async (data:customizePlateType)=>{
        return await axios.post("plate-number/registration/customized", data)
    },
    getCustomizePlateNumberById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`plate-number/registration/customized?id=${id}&limit=${limit}&offset=${offset}`)
    },

    driversLicenseRenewal: async (data:driverLicenseRenewalType)=>{
        return await axios.post("drivers-license/registration/renewal", data)
    },

    getDriversLicenseRenewalById: async (id: string|undefined, limit:number, offset: number)=>{ 
        return await axios.get(`drivers-license/registration/renewal?id=${id}&limit=${limit}&offset=${offset}`)
    },
    guestUser: async (data:guestUserType)=>{
        console.log(data)
        return await axios.post("/user/user/guest", data)
    }
}