import React, { useEffect, useState } from 'react'
import "./Tracking.scss"
import { RegExpressNavbar } from 'Layout'
import { JoinKitaniz } from '../../Components'
import { useForm } from 'react-hook-form'
import { orderService } from 'Services'
import Swal from 'sweetalert2'
import { orderDataType, ServiceOrderDataType } from '@/Models'
import { TimeConverter } from '../../../../Helpers/TimeConverter'
import { ReplaceCharacter } from '../../../../Helpers/ReplaceCharacter'
import { useSearchParams } from 'react-router-dom'

type formProps = {
    orderNo: string
}

const Tracking = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    console.log(searchParams.get('code'))
     
    const [request, setRequest] = useState(false)
    const [orderDetails, setOrderDetails] = useState<ServiceOrderDataType>()

    const { handleSubmit, register, setValue, formState: { errors }} = useForm<formProps>()

    // 87072124

    const submit = handleSubmit(async (data)=>{
        const payload = {
            reference: data.orderNo
        }
        fetchOrderData(payload)
    })

    const fetchOrderData = async (payload: {reference: string}) => {
        setRequest(true)
        await orderService.trackOrder(payload).then((response)=>{
            console.log("response", response)
            setOrderDetails(response.data.data)
            scrollToOrderDetails()
            setRequest(false)
         }, error =>{ 
              console.log("orderService error", error)
              setRequest(false)
              Swal.fire(
                  'Oops!',
                  Array.isArray(error.response?.data?.message) 
                      ? error.response.data.message[0] 
                      : error.response?.data?.error?.message || error.message,
                  'warning'
              );
               
         })
    }

    const scrollToOrderDetails = () => {
        const element:any = document.getElementById('track-order-details');
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    useEffect(() => {
        if(searchParams.get('code')){
            setValue('orderNo', `${searchParams.get('code')}`)
           fetchOrderData({reference: `${searchParams.get('code')}`})
        }
    }, [searchParams.get('code')])

    return (
        <div className='tracking'> 
            <RegExpressNavbar /> 

            <div className="header">
                <div className="container">
                    <div className="row h-100">
                        <div className="col-md-8 my-auto">
                           <div className="sub-title ">
                               TRACK ORDER
                           </div>
                           <h2>Stay updated on the progress of your vehicle registration!</h2> 
                           <div className="details">At Kitaniz, we understand how important it is for you to know the status of your vehicle registration. Use our tracking feature to get real-time updates on your order.</div>
                        </div>
                        <div className="col-md-4">
                            <svg className="w-100" width="377" height="377" viewBox="0 0 377 377" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.220463 212.4C-3.35658 235.326 37.4813 250.013 58.3474 254.49C95.012 274.192 90.5408 313.596 128.547 307.327C166.553 301.058 166.106 305.088 205.006 348.074C243.906 391.059 278.783 370.014 294.879 348.074C310.976 326.133 313.212 327.477 346.299 319.417C379.387 311.357 346.299 273.745 370.444 234.789C394.589 195.833 333.332 188.221 346.299 154.639C359.266 121.056 294.879 144.34 313.212 102.25C331.544 60.1599 302.033 44.9358 258.215 57.4733C214.396 70.0108 212.607 7.7709 179.073 0.606632C145.538 -6.55763 121.84 52.0998 90.5408 52.0998C59.2417 52.0998 10.0574 75.8314 33.3081 130.907C56.5589 185.982 4.69176 183.743 0.220463 212.4Z" fill="#F3CAAE"/>
                                <mask id="mask0_4683_43102" style={{ maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="377" height="373">
                                <path d="M0.220463 212.2C-3.35658 235.093 37.4813 249.759 58.3474 254.23C95.012 273.904 90.5408 313.252 128.547 306.992C166.553 300.732 166.106 304.756 205.006 347.681C243.906 390.605 278.783 369.59 294.879 347.681C310.976 325.771 313.212 327.113 346.299 319.064C379.387 311.016 346.299 273.457 370.444 234.557C394.589 195.656 333.332 188.055 346.299 154.52C359.266 120.986 294.879 144.236 313.212 102.206C331.544 60.1759 302.033 44.9735 258.215 57.4932C214.396 70.0128 212.607 7.86141 179.073 0.707332C145.538 -6.44675 121.84 52.1273 90.5408 52.1273C59.2417 52.1273 10.0574 75.8252 33.3081 130.822C56.5589 185.819 4.69176 183.584 0.220463 212.2Z" fill="#F3CAAE"/>
                                </mask>
                                <g mask="url(#mask0_4683_43102)">
                                <path d="M120.946 19.9355L389.671 180.455" stroke="white" stroke-width="14"/>
                                <path d="M17.2114 145.133L395.931 280.166" stroke="white" stroke-width="14"/>
                                <path d="M76.6802 167.935L215.738 23.0645" stroke="white" stroke-width="14"/>
                                <path d="M47.6167 264.518L323.943 97.291" stroke="white" stroke-width="14"/>
                                <path d="M142.408 382.56L412.922 218.463" stroke="white" stroke-width="14"/>
                                <path d="M296.668 44.5273C277.74 64.4992 253.744 94.1588 195.617 113.833C131.677 140.66 118.263 184.032 74.4445 203.259C39.3895 218.64 4.39414 240.072 -8.72168 248.866" stroke="#7BC0EA" stroke-width="14"/>
                                <path d="M63.266 175.984C54.6811 172.407 37.6305 166.148 30.1784 163.465L-5.59204 175.984L-9.61621 224.722C-0.375523 218.76 24.3657 205.316 49.405 199.235C80.7041 191.634 73.9971 180.456 63.266 175.984Z" fill="#BDC175"/>
                                <path d="M321.26 157.653C338.072 174.107 346.747 179.115 348.982 179.562C368.805 187.014 401.923 204.065 375.81 212.65C343.17 223.381 348.982 217.568 321.26 212.65C276.547 221.592 272.97 215.332 251.508 208.625C230.046 201.919 223.339 192.976 232.281 179.562C241.224 166.149 281.019 168.831 285.49 157.653C289.961 146.475 300.245 137.085 321.26 157.653Z" fill="#BDC175"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M120.945 115.621C122.734 115.621 140.619 95.2673 140.619 81.1915C142.408 67.7776 134.806 55.7051 120.945 55.7051C107.084 55.7051 99.9302 67.7776 101.272 81.1915C101.272 95.2673 119.157 115.621 120.945 115.621ZM120.498 86.1092C126.672 86.1092 131.676 81.1046 131.676 74.931C131.676 68.7574 126.672 63.7527 120.498 63.7527C114.324 63.7527 109.32 68.7574 109.32 74.931C109.32 81.1046 114.324 86.1092 120.498 86.1092Z" fill="#FA4634"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M262.398 304.31C264.187 304.31 282.072 283.957 282.072 269.881C283.861 256.467 276.259 244.395 262.398 244.395C248.537 244.395 241.383 256.467 242.725 269.881C242.725 283.957 260.61 304.31 262.398 304.31ZM261.951 274.799C268.125 274.799 273.129 269.794 273.129 263.62C273.129 257.447 268.125 252.442 261.951 252.442C255.777 252.442 250.773 257.447 250.773 263.62C250.773 269.794 255.777 274.799 261.951 274.799Z" fill="#FA4634"/>
                                <path d="M122.287 120.541L78.0215 167.043L341.381 260.94L268.499 305.206" stroke="#FA4634" stroke-width="5" stroke-linecap="round"/>
                                </g>
                                <path d="M209.922 259.892L72.0897 211L31 325.947L167.272 376.399L209.922 259.892Z" fill="#BB9B7E"/>
                                <path d="M174.374 255.186L75.723 218.69L65.7506 245.075L164.402 281.572L174.374 255.186Z" fill="#E3D9D9"/>
                                <path d="M174.374 255.186L75.723 218.69L65.7506 245.075L164.402 281.572L174.374 255.186Z" fill="#E3D9D9"/>
                                <path d="M130.143 241.054L77.3958 221.751L76.4358 223.991L129.183 243.293L130.143 241.054Z" fill="white"/>
                                <path d="M128.2 244.889L75.4681 225.984L74.5081 228.223L127.24 247.129L128.2 244.889Z" fill="white"/>
                                <path d="M120.475 247.127L73.5398 230.216L72.5799 232.456L119.515 249.367L120.475 247.127Z" fill="white"/>
                                <path d="M107.48 247.599L71.6116 234.451L70.6517 236.69L106.52 249.839L107.48 247.599Z" fill="white"/>
                                <circle cx="149.588" cy="334.27" r="14.0433" fill="#E8D7C5"/>
                                <path d="M167.792 346.259L143.32 335.83L137.625 349.327L162.097 359.756L167.792 346.259Z" fill="#F2F2F2"/>
                                <circle cx="96.1476" cy="355.872" r="20.1476" fill="#38C172"/>
                                <path d="M89.2241 356.803L93.0564 360.522L103.276 350.604" stroke="white" stroke-width="4.23077" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> 
                        </div>
                    </div>
                   <form  onSubmit={submit}>
                        <div className="search w-100">
                            <div className="row h-100">
                                <div className="col-12 col-md-9  col-lg-10 my-auto">
                                    <input type="text" placeholder='Order tracking ID' {...register('orderNo', { required: "field is required"})} className='form-control border-0 ps-4'/> 
                                </div>
                                <div className="col-12 col-md-3 col-lg-2 my-auto">
                                    <button className='btn me-4' type='submit'>Track Order {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                                </div>
                            </div>
                            {errors.orderNo && <small className='error text-danger'>{errors.orderNo.message}</small>} 
                             
                        </div>
                    </form>

                    <div className="row justify-content-center nav-bottom" onClick={scrollToOrderDetails}>
                        <div className="col-md-2 text-center">
                            <button className='btn border-0'>
                            <svg width="48" height="48" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_4683_43687)">
                                   <circle cx="34" cy="30" r="23" transform="rotate(90 34 30)" stroke="#EAECF0" stroke-width="2" shape-rendering="crispEdges"/>
                                </g>
                                <path d="M25.0902 26.0234L32.4252 33.3584C33.2914 34.2247 34.7089 34.2247 35.5752 33.3584L42.9102 26.0234" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <defs>
                                    <filter id="filter0_d_4683_43687" x="9.53674e-07" y="7.15256e-07" width="68" height="68" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="5"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4683_43687"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4683_43687" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <div id="track-order-details">
                {orderDetails && <div className="container mt-5 mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card track-order border-0 px-4">
                                <div className="card-header bg-white px-0">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="title">
                                            Track Order
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="trackerid">
                                            ID: {orderDetails.orderReferenceCode}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body order-tracker px-0">
                                    <div className="row">
                                        <div className="col-6 text-start">Registration Type</div>
                                        <div className="col-6 text-end">{orderDetails.registrationService.name}</div>
                                        <div className="col-6 text-start">Created Date </div>
                                        <div className="col-6 text-end">{TimeConverter(orderDetails.createdAt)}</div>
                                        <div className="col-6 text-start">Estimated Delivery Date </div>
                                        <div className="col-6 text-end">--</div>
                                    </div>
                                </div>
                            </div>

                            {orderDetails?.orderStatuses.length > 0 &&
                            <div className="card track-order border-0">
                                <div className="card-body">
                                    <div className="row">
                                        {orderDetails && orderDetails.orderStatuses.map(status => {
                                            return (
                                                <div key={status.uuid} className="col-6 col-md-3 text-center mb-2">
                                                    <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="12.5" cy="12" r="12" fill="#38C172"/>
                                                        <path d="M8.37646 12.5536L10.659 14.769L16.7457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <div className="time">
                                                        {TimeConverter(status.createdAt)} 
                                                    </div>
                                                    <div className="progress-label">
                                                        {ReplaceCharacter(status.name, '_', ' ')}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <div className="col-6 col-md-3 text-center mb-2">
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.5" cy="12" r="12" fill="#38C172"/>
                                                <path d="M8.37646 12.5536L10.659 14.769L16.7457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <div className="time">
                                            2024-07-05 09:07:17 
                                            </div>
                                            <div className="progress-label">
                                                In progress
                                            </div>
                                        </div>
                                        <div className="col-6  col-md-3 text-center mb-2">
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.5" cy="12" r="12" fill="#38C172"/>
                                                <path d="M8.37646 12.5536L10.659 14.769L16.7457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <div className="time">
                                            2024-07-05 09:07:17 
                                            </div>
                                            <div className="progress-label">
                                                Soft-copy ready
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 text-center mb-2">
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.5" cy="12" r="12" fill="#38C172"/>
                                                <path d="M8.37646 12.5536L10.659 14.769L16.7457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <div className="time">
                                            2024-07-05 09:07:17 
                                            </div>
                                            <div className="progress-label">
                                            In transit
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 text-center mb-2">
                                            <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12.5" cy="12" r="12" fill="#38C172"/>
                                                <path d="M8.37646 12.5536L10.659 14.769L16.7457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            <div className="time">
                                            2024-07-05 09:07:17 
                                            </div>
                                            <div className="progress-label">
                                            Delivered
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            }



                        </div>


                        <div className="col-md-6">
                            <div className="card track-order-documents border-0 px-4">
                                <div className="card-header bg-white px-0 py-2">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="title">
                                            Documents 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="card-body order-track-documents px-0">
                                    <div className="row">
                                        <div className="col-6 text-start">
                                            <svg width="15" height="17" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.56098 22H17.9946C18.8567 22 19.5556 21.3011 19.5556 20.439V11V5.5L14.6667 0H9.77778H1.56097C0.698872 0 0 0.698871 0 1.56097V20.439C0 21.3011 0.698873 22 1.56098 22Z" fill="#E52C00"/>
                                                <path d="M4.84474 16V11.7533H6.59194C6.85887 11.7533 7.09345 11.8079 7.29567 11.9171C7.50194 12.0223 7.66169 12.1719 7.77494 12.3661C7.89222 12.5602 7.95087 12.7907 7.95087 13.0577V13.1426C7.95087 13.4055 7.8902 13.636 7.76887 13.8342C7.65158 14.0283 7.4898 14.18 7.28354 14.2892C7.08131 14.3944 6.85078 14.4469 6.59194 14.4469H5.64554V16H4.84474ZM5.64554 13.7189H6.51307C6.70316 13.7189 6.85685 13.6664 6.97414 13.5612C7.09142 13.456 7.15007 13.3125 7.15007 13.1305V13.0698C7.15007 12.8878 7.09142 12.7442 6.97414 12.6391C6.85685 12.5339 6.70316 12.4813 6.51307 12.4813H5.64554V13.7189ZM8.36103 16V15.2963H8.91917V12.4571H8.36103V11.7533H10.1082C10.6785 11.7533 11.1113 11.8989 11.4065 12.1901C11.7058 12.4773 11.8554 12.906 11.8554 13.4763V14.2771C11.8554 14.8473 11.7058 15.2781 11.4065 15.5693C11.1113 15.8564 10.6785 16 10.1082 16H8.36103ZM9.71997 15.272H10.1204C10.4439 15.272 10.6805 15.1871 10.8302 15.0172C10.9798 14.8473 11.0546 14.6087 11.0546 14.3013V13.452C11.0546 13.1406 10.9798 12.902 10.8302 12.7361C10.6805 12.5663 10.4439 12.4813 10.1204 12.4813H9.71997V15.272ZM12.5229 16V11.7533H15.1922V12.4813H13.3237V13.5066H15.0466V14.2346H13.3237V16H12.5229Z" fill="white"/>
                                                <path d="M16.2278 5.5H19.5557L14.6668 -4.76837e-07V3.93903C14.6668 4.80113 15.3656 5.5 16.2278 5.5Z" fill="#D9D9D9"/>
                                            </svg> <span className='ms-2'>Vehicle License</span>
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="ready-doc">
                                                <span>Ready! </span>
                                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M9 11.5098L12 14.5098L15 11.5098" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M12 14.5098V6.50977" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg> 
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="card-footer bg-white text-center start-download py-4">
                                    <svg width="16" height="16" viewBox="0 0 24 24" className='me-1' fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M9 11.5098L12 14.5098L15 11.5098" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 14.5098V6.50977" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> Download All Ready Documents
                                </div>
                            </div>



                            <button className="btn btn-confirm-tracker w-100 mt-4" disabled>
                                Confirm Delivery
                            </button>

                            <div className="tracker-question mt-4"> 
                            If you have any questions or encounter any issues while tracking your order, feel free to contact our support team at  <span className='text-primary'>support@kitaniz.com. </span>
                            </div> 

                        </div>
                    </div>

                    {/* <div className="search-result">
                        <div className="card tracking-id">
                            <div className="card-body">
                            <div className="row h-100">
                                <div className="col-6 my-auto title">Track Order O-NG240705915488</div>
                                <div className="col-6 text-end my-auto date">Created 2024-07-05 09:07:17</div>
                            </div>
                            </div>
                        </div>

                        <div className="card tracking-item">
                            <div className="card-body">
                            <div className="row h-100">
                                <div className="col-12 col-md-6 my-auto title">
                                
                                    <div className="date"> 
                                        <div>
                                            <svg className="me-3" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#38C172"/>
                                                <path d="M7.87646 12.5536L10.159 14.769L16.2457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg> 2024-07-05 09:07:17
                                        </div>
                                        <div className='tracking-status'>
                                        Loaded at Lagos Office
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>



                        <div className="card tracking-item">
                            <div className="card-body">
                            <div className="row h-100">
                                <div className="col-12 col-md-6 my-auto title">
                                
                                    <div className="date"> 
                                        <div>
                                            <svg className="me-3" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#38C172"/>
                                                <path d="M7.87646 12.5536L10.159 14.769L16.2457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg> 2024-07-05 09:07:17
                                        </div>
                                        <div className='tracking-status'>
                                            Document Ready for Delivery
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>





                        <div className="card tracking-item">
                            <div className="card-body">
                            <div className="row h-100">
                                <div className="col-12 col-md-6 my-auto title">
                                
                                    <div className="date"> 
                                        <div>
                                            <svg className="me-3" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#38C172"/>
                                                <path d="M7.87646 12.5536L10.159 14.769L16.2457 8.86133" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg> 2024-07-05 09:07:17
                                        </div>
                                        <div className='tracking-status'>
                                        Delivery Successful
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>
    
                    </div> */}

                </div> }
            </div>
            <JoinKitaniz />
        </div>
    )
}

export default Tracking
