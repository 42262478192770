import { OrderUploadedDocument } from "@/Models/orderUploadDocument"
import axios from "axios"
import { AxiosInterceptor } from "Config/AxiosInterceptor"
AxiosInterceptor()

export const orderService = {
   getOrder: async (limit:number, offset: number, id: string | undefined) => {
       return await axios.get(`/owner/order/aggregation?ownerId=${id}&limit=${limit}&offset=${offset}`)
   },
   
    getOrderId: async (limit:number, offset: number, id: string | undefined) => {
      return await axios.get(`/owner/order/aggregation?id=${id}&limit=${limit}&offset=${offset}`)
    },

    getOrders: async (limit:number, offset: number) => {
      return await axios.get(`/insurance/provider?limit=${limit}&offset=${offset}`)
    },

    trackOrder: async (payload: {reference: string}) => {
      return await axios.post(`/order/tracking`, payload)
    },

    getServiceOrderId: async ( id: string | undefined) => {
        return await axios.get(`/order/${id}`)
      },
     
    initiatePayment: async ( payload: {orderId: string, totalOrderAmount: number} ) => {
      return await axios.post('/payments/invoice-link', payload)
    },
    orderUploadDocument: async (payload: OrderUploadedDocument) => {
        return await axios.post("order/order-uploaded-documents", payload)
    },
    addDocumentToAnOrder: async (payload: OrderUploadedDocument) => {
      return await axios.post("order/registration-service-document-orders", payload)
    },
}
