import React, { Fragment, Key, useEffect, useState } from 'react'
import { firebaseStorage } from "Services/firebaseInitService";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import "./RegistrationService.scss"
import { makeType, RegistrationServiceType, ServiceOrderDataType } from 'Models';
import { modelType } from 'Models/modelType';
import { orderService, vehicleService } from 'Services'; 
import Swal from 'sweetalert2';  
import { Controller, useForm } from 'react-hook-form'; 
import { VehicleClassType } from 'Models/vehicleClassType';
import { VehicleColorType } from 'Models/vehicleColorType';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { vehicleRegistrationService } from 'Services/vehicleRegistrationService';
import { useAuth } from 'Components/AuthProvider/AuthProvider';
import Skeleton from 'react-loading-skeleton'
import { FileUploader } from 'react-drag-drop-files';
import jpg from 'Assets/Icons/jpg.png';
import png from 'Assets/Icons/png.png';
import pdf from 'Assets/Icons/pdf.svg';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { VehicleYear } from '@/Models/vehicleYear';
import { VehicleUse } from '@/Models/vehicleUse';
import { Lga, State } from '@/Models/location';
import { CHANGE_OF_OWNERSHIP_SERVICE_ID, LAGOS_STATE_ID, NEW_REGISTRATION_SERVICE_ID, RENEW_REGISTRATION_SERVICE_ID } from '../../../../Constants/RegistrationConstant';
import { OrderUploadedDocument } from '@/Models/orderUploadDocument';
import PhoneNumberInput from '../../../../Components/PhoneNumberInput/PhoneNumberInput';

export type registrationOrderFormType = { 
        registrationService: string | undefined,
        vehicleMake: string,
        vehicleModel: string,
        vehicleYear: string|number,
        vehicleType: string,
        vehicleUse: string,
        vehicleColor: string,
        chasisNumber: string,
        engineNumber: string,
        requiredCRM: string,
        nin: string,
        stateLiencingOffice: string,
        stateRequestingOffice: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        state: string,
        localGovernment: string,
        address: string,
        vehicleIdentificationNumber?: string,
        plateNumber? : string
        newOwnerFirstName?: string;
        newOwnerLastName?: string;
        newOwnerPhoneNumber?: string;
        newOwnerAddress?: string;
        newOwnerEmail?: string;
}

const RegistrationService = () => {

    const [searchParams, setSearchParams] = useSearchParams();

   const { service_id } = useParams<{service_id: string}>()
    const limit = 100;
    const offset = 0; 
    const navigate = useNavigate(); 
    const { user } = useAuth(); 
     
    const [make, setMake] = useState<Array<makeType>>()
    const [model, setModel] = useState<Array<modelType>>()
    const [vehicleClass, setVehicleClass] = useState<Array<VehicleClassType>>()
    const [vehicleColor, setVehicleColor] = useState<Array<VehicleColorType>>()
    const [vehicleUse, setVehicleUse] = useState<Array<VehicleUse>>()

    const [states, setStates] = useState<Array<State>>()
    const [lgas, setLgas] = useState<Array<Lga>>()
    
    const [checkColor, setCheckColor] = useState<string>('d-none')

    const [vehicleYear, setVehicleYear] = useState<VehicleYear[]>() 
    const [greyButton, setGreyButton] = useState<boolean>(false) 

    const getMake = async (type_id: string) =>{
        await vehicleService.getMakeByTypeId(type_id).then((response) =>{
            setMake(response.data.data)
        }, error => {

            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
 
     const getYear = async () =>{
         await vehicleService.getVehicleYear( limit, offset).then((response) =>{
             setVehicleYear(response.data.data)
         }, error => {
 
             console.log("error make", error)
             Swal.fire({
                 position: "top-end",
                 icon: "warning",
                 text: error.response.data.message,
                 showConfirmButton: false,
                 timer: 3000
             });
         })
     }

     const getStates = async () =>{
        await vehicleService.getStates().then((response) =>{
            setStates(response.data.data)
        }, error => {

            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }

     const getVehicleUse = async () =>{
        await vehicleService.getVehicleUse().then((response) =>{
            setVehicleUse(response.data.data)
        }, error => {

            console.log("error make", error)
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
        })
    }
 
     const getModel = async (make_id:string) =>{
        await vehicleService.getModelsByMakeId(make_id).then((response) =>{
            setModel(response.data.data) 
        }, error => {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
            console.log("error model", error)
        })
    }
 

    const getVehicleClass = async () =>{
        await vehicleService.getVehicleClass(limit, offset).then((response) =>{
            setVehicleClass(response.data.data) 
        }, error => {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
            console.log("error model", error)
        })
    }
 

    const getVehicleColors = async () =>{
        await vehicleService.getVehicleColor(limit, offset).then((response) =>{
            setVehicleColor(response.data.data) 
        }, error => {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                text: error.response.data.message,
                showConfirmButton: false,
                timer: 3000
            });
            console.log("error getVehicleCcolor", error)
        })
    }
 
  

     useEffect(() => {
         getYear()  
         getVehicleClass()
         getVehicleColors()
         getVehicleUse()
         getStates()
     }, [])
     const [request, setRequest] = useState(false)
     const { control, register, watch, handleSubmit, getValues, setValue, formState: { errors } } = useForm<registrationOrderFormType>()

     useEffect(() => {
     },[
        watch('requiredCRM'),
     ])
     
     const onSubmit = async (data: registrationOrderFormType) => {

        console.log(data)

        setRequest(true)
            const newData:registrationOrderFormType = { 
                ...data,
                state: data.state, 
                registrationService: service_id, 
                vehicleMake: data.vehicleMake,  
                vehicleYear: data.vehicleYear,                
            }

            setGreyButton(true)

            console.log(newData)

            // processFilesForUpload('jsncjkndcsd')


            // return

            await vehicleRegistrationService.orderRegistrationService(newData).then((response)=>{
                console.log(response)
                const orderId: string = response.data.data.uuid
                // 8c6e6f78-5960-4c7c-9bd9-4aa71746b786
                setRequest(false)
                if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
                    processFilesForUpload(orderId)
                }
                navigate(user ? `/myaccount/registration/${orderId}/registration-summary`: `/registration/${orderId}/registration-summary`, { replace: true});
            
                setGreyButton(false)
            }, error =>{
                console.log("onSubmit error", newData)
                setRequest(false)
                setGreyButton(false)

                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    text: error.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                });
            })
        
     };

    const test = () => {
        console.log(cooFiles)
    }

    const [hasUploadedAllFilesForCOO, setHasUploadedAllFilesForCOO] = useState(false)

    useEffect(() => {

    }, [hasUploadedAllFilesForCOO])

    const processFilesForUpload = (orderId: string) => {
        for(const key in cooFiles){
            const processedFile = cooFiles[key]
            try { 
                const storageRef = ref(firebaseStorage, `files/${processedFile.file.name}`); 
                const uploadTask = uploadBytesResumable(storageRef, processedFile.file);
     
                uploadTask.on("state_changed",
                    (snapshot) => {
                        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
                    },(error) => {
                        console.log(error)
                        alert(error);
                    },() => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

                        console.log(orderId)

                        const payload: OrderUploadedDocument = {
                            documentName: processedFile.name,
                            documentUrl: downloadURL,
                            orderUuid: orderId
                        }

                        uploadCOODocuments(payload)
    
                            console.log('File uploaded successfully.', downloadURL);
                            // saveFile(downloadURL)  
                        });
                    }
                    );
    
              } catch (error) {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    text: "Error occured while uploading file, please try again.",
                    showConfirmButton: false,
                    timer: 3000
                });
                console.error('Error uploading file:', error);
              }
        }
     }

     const uploadCOODocuments = async (payload: OrderUploadedDocument) => {
        console.log(payload)
        await orderService.orderUploadDocument(payload).then((response)=>{
            console.log(response)
        }, error =>{
            console.log(error)
        })
     }
  
 
     
     useEffect(()=>{ 
         setGreyButton(false)
       }, [watch])
  
    const handleState = async (e: any) => {
        const state_id = e.target.value;  
        await vehicleService.getLGAsByStateId(state_id).then((response)=>{
            setLgas(response.data.data)
          }, error=>{
           console.log("getService error", error)
           Swal.fire({
               position: "top-end",
               icon: "warning",
               text: error.response.data.message,
               showConfirmButton: false,
               timer: 3000
           });
          })
      }
      
      //check if it is COO service
    const totalStep = service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 3 : 2;

    const [step, setStep] = useState(1)
    // const [step, setStep] = useState(2)

      const addStep = () => { 
          if(step <= 2){
              setStep(step + 1);
          }  
      }
  
      const remStep = () => { 
          if(step >= 1){
              setStep(step - 1);
          } 
      }

    const [serviceDetail, setServiceDetail] = useState<RegistrationServiceType>()
    const [serviceRequest, setServiceRequest] = useState<boolean>()

    const getService = async () =>{
        setServiceRequest(true)
       await vehicleService.getServiceItem(service_id).then((response)=>{
         console.log("getService", response)
         setServiceDetail(response.data.data)
         setServiceRequest(false)
       }, error=>{
        setServiceRequest(false)
        console.log("getService error", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
       })
    }

    useEffect(()=>{
        getService()
    }, [service_id])

    const [documentCount, setDocumentCount] = useState([0, 1, 2, 3, 4]);
    const [files, setFile] = useState<File[]>([]);
    const [processedFiles, setProcessedFiles] = useState<{name: string, file: File}[]>([]);

    const fileTypes = ["JPG", "PNG"];
    
    const fileIcon:any = {
        'image/png': png,
        'image/jpg': jpg,
        'image/jpeg': jpg,
        'image/pdf': pdf
    }

    const [cooFiles, setCooFiles] = useState<any>()


    const handleChange = (file: FileList, document:any) => {
        let fileName = document.name
        setCooFiles({...cooFiles, [fileName]:{name: fileName, file: file[0]} })
    };

    const removeCooFile = (name: string) => {
        const updatedCooFiles = { ...cooFiles };
        delete updatedCooFiles[name];
        setCooFiles(updatedCooFiles);
    }

    const removeFile =(indexToDelete: number)=>{ 

        const updatedFiles = files.filter((_, index) => index !== indexToDelete);
        const updatedProcFiles = processedFiles.filter((_, index) => index !== indexToDelete);
        setFile(updatedFiles); 
        setProcessedFiles(updatedProcFiles)
    }

    const processFileName =(data: {name: string, file: File})=>{ 
        const checkExist = processedFiles.some(fileItem => fileItem.name === data.name);
        
        if(!checkExist){
           setProcessedFiles([...processedFiles, data])
        }
    }

    const doesFileNameExist = (fileName: string): boolean => {
        return processedFiles.some(fileItem => fileItem.name === fileName);
    };

    useEffect(() => {
        if((processedFiles.length < documentCount.length) && totalStep === 3){
            setGreyButton(true)
        }
    }, [processedFiles])

    const [greyNextOne, setGreyNextOne] = useState(false)

    useEffect(() => {
        if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
            const fields: Array<keyof registrationOrderFormType> = ['vehicleIdentificationNumber', 'plateNumber', 'vehicleType', 'vehicleMake', 'vehicleModel'];
            const allFieldsFilled = fields.every(field => !!watch(field));
        
            setGreyNextOne(!allFieldsFilled);
        }
        else{
            if(getValues('requiredCRM')){
                const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear', 'nin', 'stateLiencingOffice', 'stateRequestingOffice'];
                console.log(fields)
                const allFieldsFilled = fields.every(field => !!watch(field));
                console.log(!allFieldsFilled)
                setGreyNextOne(!allFieldsFilled);
            }
            else{
                const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear'];
                console.log(fields)
                const allFieldsFilled = fields.every(field => !!watch(field));
                console.log(!allFieldsFilled)
            
                setGreyNextOne(!allFieldsFilled);
            }
        }
      }, [
        watch('vehicleIdentificationNumber'),
        watch('plateNumber'),
        watch('vehicleMake'),
        watch('vehicleModel'),
        watch('vehicleType'),
        watch('vehicleColor'),
        watch('vehicleUse'),
        watch('vehicleYear'),
        watch('nin'),
        watch('chasisNumber'),
        watch('requiredCRM'),
        watch('stateLiencingOffice'),
        watch('stateRequestingOffice')
      ]);

    const [greyNextTwo, setGreyNextTwo] = useState(false)

    const [cooDocumentsToUpload, setCooDocumentsToUpload] = useState<any[]>([
        {
            name: 'PROOF_OF_OWNERSHIP_CERTIFICATE',
            label: 'Proof of Ownership'
        },
        {
            name: 'AUTOVIN',
            label: 'Autovin'
        },
        {
            name: 'PICTURE_OF_THE_CHASIS_NUMBER',
            label: 'Picture of the Chasis Number'
        },
        {
            name: 'VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER',
            label: 'Means of ID of New Owner'
        },
        {
            name: 'VEHICLE_LICENSE',
            label: 'Vehicle License (Up-to-Date)'
        }
    ])

    const fetchLgasbyStateId = async (state_id: string) => {
        await vehicleService.getLGAsByStateId(state_id).then((response)=>{
            setLgas(response.data.data)
          }, error=>{
           console.log("getService error", error)
           Swal.fire({
               position: "top-end",
               icon: "warning",
               text: error.response.data.message,
               showConfirmButton: false,
               timer: 3000
           });
          })
    }

    const [existingOrderDetails, setExistingOrderDetails] = useState<ServiceOrderDataType>()

    const fetchOrderDetails = async (orderId: string) => {
        await orderService.getServiceOrderId(orderId).then((response) =>{
                console.log(response)
                setExistingOrderDetails(response.data.data)
                fetchDropdownData(response.data.data)
               prefillForm(response.data.data)
           }, error => {
    
               console.log("error vehicleServices", error)
               Swal.fire({
                   position: "top-end",
                   icon: "warning",
                   text: error.response.data.message,
                   showConfirmButton: false,
                   timer: 3000
               });
           })
    }

    const fetchDropdownData = (data: ServiceOrderDataType) => {
        getMake(data.vehicleType.uuid)
        getModel(data.vehicleMake.uuid)
        fetchLgasbyStateId(LAGOS_STATE_ID)
    }

    const prefillForm = (data: ServiceOrderDataType) => {
        console.log(data)
        // setValue('vehicleIdentificationNumber', data.vehicleIdentificationNumber)
        setValue('plateNumber', data?.plateNumber)
        setValue('vehicleType', data.vehicleType?.uuid)
        setValue('vehicleYear', data.vehicleYear?.uuid)
        setValue('vehicleUse', data.vehicleUse?.uuid)
        setValue('vehicleColor', data.vehicleColor)
        setValue('engineNumber', data.engineNumber)
        setValue('chasisNumber', data.chasisNumber)
        setValue('requiredCRM', data.requiredCRM)
        setValue('nin', data.nin)
        setValue('stateLiencingOffice', data.stateLiencingOffice?.uuid)
        setValue('stateRequestingOffice', data.stateRequestingOffice?.uuid)
        setValue('firstName', data.firstName)
        setValue('lastName', data.lastName)
        setValue('email', data.email)
        setValue('phoneNumber', data.phoneNumber)
        setValue('address', data.address)
        setValue('state', LAGOS_STATE_ID)
        setValue('localGovernment', data.localGovernment?.id)
        setValue('newOwnerFirstName', data.newOwnerFirstName)
        setValue('newOwnerLastName', data.newOwnerLastName)
        setValue('newOwnerPhoneNumber', data.newOwnerPhoneNumber)
        setValue('newOwnerAddress', data.newOwnerAddress)
    }

    useEffect(() => {
        const fields: Array<keyof registrationOrderFormType> = ['firstName', 'lastName', 'phoneNumber', 'address'];
        const allFieldsFilled = fields.every(field => !!watch(field));
    
        setGreyNextTwo(!allFieldsFilled);
      }, [
        watch('firstName'),
        watch('lastName'),
        watch('phoneNumber'),
        watch('address'),
        watch('email'),
        watch('localGovernment'),
        watch('state')
      ]);

      const [serviceType, setServiceType] = useState<string>()

      useEffect(() => {
        if(service_id === RENEW_REGISTRATION_SERVICE_ID){
            setServiceType('renew-registration')
        }
        else if(service_id === NEW_REGISTRATION_SERVICE_ID){
            setServiceType('new-registration')
        }
        else if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
            setServiceType('change-of-ownership')
        }
      },[service_id])

      useEffect(() => {
        if(searchParams.get('step')){
            setStep(Number(searchParams.get('step')))
        }
      }, [searchParams.get('step')])

      const [existingOrderId, setExistingOrderId] = useState<string>()

      useEffect(() => {
        if(searchParams.get('orderId')){
            let orderId = String(searchParams.get('orderId'))
            setExistingOrderId(orderId)
            fetchOrderDetails(orderId)
        }
      }, [searchParams.get('orderId')])

      useEffect(() => {
        if(existingOrderId){
            setValue('vehicleMake', String(existingOrderDetails?.vehicleMake?.uuid))
        }
      }, [make])

      useEffect(() => {
        if(existingOrderId){
            setValue('vehicleModel', String(existingOrderDetails?.vehicleModel?.uuid))
        }
      }, [model])

      useEffect(() => {
        if(existingOrderId){
            setValue('localGovernment', String(existingOrderDetails?.localGovernment?.id))
        }
      }, [lgas])

    return (
        <div className='registration-service-page'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9"> 
                        <div className="top-header py-4 px-0">
                            {!serviceRequest && <div className="title">{serviceDetail && serviceDetail?.name}</div>}
                            {serviceRequest && <div className="title"><Skeleton width="50%" height={20}/></div>}
                            <div className="desc d-none">Fill the form below to register your newly purchased vehicle or foreign used vehicle.</div>
                        </div>
                        <div className="card px-3 px-md-4">
                           
                            <div className="card-body px-0"> 
                            {step <= 2 && <div className="row pb-0 mb-0">
                                    <div className="col-md-6 pb-0 mb-0">
                                        <div className="section-title">
                                           Vehicle {step === 2 && "Owner"} Information
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="section-pagination">
                                            {step} of {totalStep}
                                        </div>
                                    </div>
                                </div>}

                            {step === 3 && <div className="row pb-0 mb-0">
                                    <div className="col-md-6 pb-0 mb-0">
                                        <div className="section-title">
                                            Upload Documents
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="section-pagination">
                                            {step} of {totalStep}
                                        </div>
                                    </div>
                                </div>}
                               
                                <form onSubmit={handleSubmit(onSubmit)} className='VehicleTypeForm'>
                                    <div className="row"> 
                                       {step ===1 && <Fragment>

                                        {totalStep === 3 && <Fragment>
                                            <div className="col-md-6">  
                                                <div className="form-group">
                                                    <label htmlFor="vehicleIdentificationNumber">Vehicle Identification Number (VIN)</label> 
                                                    <input type="text" id="vehicleIdentificationNumber" className={`form-control ${errors.vehicleIdentificationNumber ? 'is-invalid' : ''}`} {...register("vehicleIdentificationNumber", { required: "field can not be empty" })}/>
                                                    {errors.vehicleIdentificationNumber && <small className='error'>{errors.vehicleIdentificationNumber.message}</small>} 
                                                </div>  
                                            </div> 
                                            <div className="col-md-6">  
                                                <div className="form-group">
                                                    <label htmlFor="licensePlateNumber">License Plate Number</label> 
                                                    <input type="text" id="licensePlateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
                                                    {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
                                                </div>  
                                            </div>  
                                        </Fragment>}

                                        {serviceType === 'renew-registration' &&
                                            <div className="col-md-6"> 
                                                <div className="form-group">
                                                    <label htmlFor="nin">Plate Number</label>
                                                    <input type="text" id="plateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
                                                    {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
                                                </div>  
                                            </div>
                                        }

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="vehicleClass">Vehicle Class</label>
                                                <select id="vehicleClass" className={`form-control ${errors.vehicleType ? 'is-invalid' : ''}`} {...register("vehicleType", { required: "field can not be empty", onChange: (e)=>getMake(e.target.value) })}>
                                                    <option value="">select class</option>
                                                    {vehicleClass && vehicleClass.map((vehicleCla: VehicleClassType, index:Key)=>{
                                                       return <option key={index} value={vehicleCla.uuid}>{vehicleCla.name}</option>
                                                    })}
                                                </select>
                                                {errors.vehicleType && <small className='error'>{errors.vehicleType.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6">  
                                            <div className="form-group">
                                                <label htmlFor="vehicleMakeId">Make</label>
                                                <select id="vehicleMakeId" className={`form-control ${errors.vehicleMake ? 'is-invalid' : ''}`} {...register("vehicleMake", { required: "field can not be empty", onChange: (e)=>getModel(e.target.value)})}>
                                                    <option value="">select make</option> 
                                                    {make && make.map((mk: makeType, index:Key)=>{
                                                      return <option key={index} value={mk.uuid}>{mk.name}</option>
                                                    })}
                                                </select>
                                                {errors.vehicleMake && <small className='error'>{errors.vehicleMake.message}</small>} 
                                            </div>
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="vehicleModel">Model</label>
                                                <select id="vehicleModel" className={`form-control ${errors.vehicleModel ? 'is-invalid' : ''}`} {...register("vehicleModel", { required: "field can not be empty" })}>
                                                    <option value="">select model</option> 
                                                    {model && model.map((mod: modelType, index:Key)=>{
                                                    return <option key={index} value={mod.uuid}>{mod.name}</option>
                                                    })}
                                                </select>
                                                {errors.vehicleModel && <small className='error'>{errors.vehicleModel.message}</small>} 
                                            </div>
                                        </div>

                                         {totalStep !== 3 && <Fragment>
                                            <div className="col-md-6"> 
                                                <div className="form-group">
                                                    <label htmlFor="year">Vehicle Year</label>
                                                    <select id="year" className={`form-control ${errors.vehicleYear ? 'is-invalid' : ''}`} {...register("vehicleYear", { required: "field can not be empty" })}>
                                                        <option value="">select year</option>
                                                        {vehicleYear && vehicleYear.map((year: VehicleYear, index:Key)=>{
                                                        return <option key={index} value={year.uuid}>{year.year}</option>
                                                        })}
                                                    </select>
                                                    {errors.vehicleYear && <small className='error'>{errors.vehicleYear.message}</small>} 
                                                </div>  
                                            </div>
                                        

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="vehicleUse">Vehicle Use</label>
                                                <select id="vehicleUse" className={`form-control ${errors.vehicleUse ? 'is-invalid' : ''}`}{...register("vehicleUse", { required: "field can not be empty" })}>
                                                    <option value="">select use</option> 
                                                    {vehicleUse && vehicleUse.map((vehicleUse: VehicleUse, index:Key)=>{
                                                       return <option key={index} value={vehicleUse.uuid}>{vehicleUse.name}</option>
                                                    })}
                                                </select>
                                                {errors.vehicleUse && <small className='error'>{errors.vehicleUse.message}</small>} 
                                            </div>  
                                        </div>


                                        <div className="col-md-6">  

                                            <div className="form-group row">
                                                <label htmlFor="color">Vehicle Color</label>
                                                <div className={ checkColor === 'd-block' ? 'col-6 pe-3' : 'col-12'}>
                                                    <select {...checkColor === 'd-none' ? {...register("vehicleColor")} : ''} 
                                                    onChange={(e) => {e.target.value === 'custom' ? setCheckColor('d-block') : setCheckColor('d-none')}}
                                                    id="color" className={`form-control ${errors.vehicleColor ? 'is-invalid' : ''}`}>
                                                        <option value="">select color</option> 
                                                        <option value="custom">Custom Color</option>
                                                        {vehicleColor && vehicleColor.map((vehicleCol: VehicleColorType, index:Key)=>{
                                                        return <option key={index} value={vehicleCol.color}>{vehicleCol.color}</option>
                                                        })}
                                                    </select>
                                                    {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
                                                </div>

                                                <div className={ checkColor === 'd-block' ? `${checkColor} col-6 ps-3` : `${checkColor}`}>
                                                    <input type="text" {...checkColor === 'd-block' ? {...register("vehicleColor")} : ''} id="customColor" className='form-control' placeholder='Type color, e.g purple'/> 
                                                    {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
                                                </div>
                                            </div> 
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="engineNumber">Engine Number</label>
                                                <input type="text" id="engineNumber" className={`form-control ${errors.engineNumber ? 'is-invalid' : ''}`} {...register("engineNumber", { required: "field can not be empty" })}/>
                                                {errors.engineNumber && <small className='error'>{errors.engineNumber.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="chasisNumber">Chasis Number</label>
                                                <input type="text" id="chasisNumber" className={`form-control ${errors.chasisNumber ? 'is-invalid' : ''}`} {...register("chasisNumber", { required: "field can not be empty" })}/>
                                                {errors.chasisNumber && <small className='error'>{errors.chasisNumber.message}</small>} 
                                            </div>  
                                        </div>

                                        </Fragment>}
                                

                                        <div className="col-md-12"> 
                                            <div className="form-group d-flex form-crm-input">
                                                <input type="checkbox" id="requiredCRM" className='my-auto' {...register("requiredCRM")}/> 
                                                <label htmlFor="requiredCRM" className='my-auto'>CMR(Central Motor Registry)</label>
                                                {errors.requiredCRM && <small className='error'>{errors.requiredCRM.message}</small>} 
                                            </div>  
                                        </div>

                                        {/* {totalStep !== 3 && <Fragment> */}


                                            { getValues('requiredCRM') &&
                                                <>
                                                    <div className="col-md-6"> 
                                                        <div className="form-group">
                                                            <label htmlFor="nin">NIN</label>
                                                            <input type="text" id="nin" className={`form-control ${errors.nin ? 'is-invalid' : ''}`} {...register("nin", { required: "field can not be empty" })}/>
                                                            {errors.nin && <small className='error'>{errors.nin.message}</small>} 
                                                        </div>  
                                                    </div>

                                                    <div className="col-md-6"> 
                                                        <div className="form-group">
                                                            <label htmlFor="stateLiencingOffice">State licensing office</label>
                                                            <select id="stateLiencingOffice" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('stateLiencingOffice', { required: "This field is required"})}>
                                                                <option>select state</option>
                                                                {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
                                                            </select>
                                                            {errors.stateLiencingOffice && <small className='error'>{errors.stateLiencingOffice.message}</small>} 
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6"> 
                                                        <div className="form-group">
                                                            <label htmlFor="stateRequestingOffice">State Requesting office</label>
                                                            <select id="stateRequestingOffice" className={`form-control ${errors.stateRequestingOffice ? 'is-invalid' : ''}`} {...register('stateRequestingOffice', { required: "This field is required"})}>
                                                                <option>select state</option>
                                                                {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
                                                            </select>
                                                            {errors.stateRequestingOffice && <small className='error'>{errors.stateRequestingOffice.message}</small>} 
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        {/* </Fragment>} */}

                                        
                                    </Fragment>}


                                    {step === 2 && <Fragment>
                                        {totalStep === 3 && <Fragment> 
                                        <div className="tagline--sub-heading mt-3 fw-bolder text-dark">CURRENT OWNER’S DETAILS</div>
                                             
                                        </Fragment>}

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" id="firstName" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} {...register("firstName", { required: "field can not be empty" })}/>
                                                {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" id="lastName" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} {...register("lastName", { required: "field can not be empty" })}/>
                                                {errors.lastName && <small className='error'>{errors.lastName.message}</small>} 
                                            </div>  
                                        </div>

                                         {totalStep !== 3 &&<div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="email">Email Address</label>
                                                <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
                                                {errors.email && <small className='error'>{errors.email.message}</small>} 
                                            </div>  
                                        </div>}

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Phone Number</label>
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    rules={{ required: "Phone number is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                                    <>
                                                        <PhoneNumberInput
                                                            value={value}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            className={`form-control ${error ? "is-invalid" : ""}`}
                                                        />
                                                        {error && <div className="invalid-feedback">{error.message}</div>}
                                                    </>
                                                    )}
                                                />
                                            </div>
                                        </div>


                                        {/* <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Phone Number</label>
                                                <input type="number" id="phoneNumber" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} {...register("phoneNumber", { required: "field can not be empty" })}/>
                                                {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
                                            </div>  
                                        </div> */}


                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="address">Address</label>
                                                <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register("address", { required: "field can not be empty" })}/>
                                                {errors.address && <small className='error'>{errors.address.message}</small>} 
                                            </div>  
                                        </div>
 
                                        {totalStep !== 3 && <Fragment>
                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="state">State</label>
                                                <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => handleState(e)})}>
                                                    <option>select state</option>
                                                    {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
                                                </select>
                                                {errors.state && <small className='error'>{errors.state.message}</small>} 
                                            </div>
                                        </div>
                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="lga">L.G.A</label>
                                                <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment', { required: "This field is required"})}>
                                                    <option>select L.G.A</option>
                                                    {lgas?.map((lgaItem:Lga, index: Key)=><option key={index} value={lgaItem.id}>{lgaItem.name}</option>)}
                                                </select>
                                                {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
                                            </div>  
                                        </div> 
                                        </Fragment>}

                                        {totalStep === 3 && <Fragment> 
                                        <div className="tagline--sub-heading mt-3 fw-bolder text-dark mt-4">NEW OWNER’S DETAILS</div>
                                            
                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" id="firstName" className={`form-control ${errors.newOwnerFirstName ? 'is-invalid' : ''}`} {...register("newOwnerFirstName", { required: "field can not be empty" })}/>
                                                {errors.newOwnerFirstName && <small className='error'>{errors.newOwnerFirstName.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" id="lastName" className={`form-control ${errors.newOwnerLastName ? 'is-invalid' : ''}`} {...register("newOwnerLastName", { required: "field can not be empty" })}/>
                                                {errors.newOwnerLastName && <small className='error'>{errors.newOwnerLastName.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="email">Email Address</label>
                                                <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
                                                {errors.email && <small className='error'>{errors.email.message}</small>} 
                                            </div>  
                                        </div>

                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Phone Number</label>
                                                <Controller
                                                    name="newOwnerPhoneNumber"
                                                    control={control}
                                                    rules={{ required: "Phone number is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                                    <>
                                                        <PhoneNumberInput
                                                            value={value || ''}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            className={`form-control ${error ? "is-invalid" : ""}`}
                                                        />
                                                        {error && <div className="invalid-feedback">{error.message}</div>}
                                                    </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="phoneNumber">Phone Number</label>
                                                <input type="number" id="phoneNumber" className={`form-control ${errors.newOwnerPhoneNumber ? 'is-invalid' : ''}`} {...register("newOwnerPhoneNumber", { required: "field can not be empty" })}/>
                                                {errors.newOwnerPhoneNumber && <small className='error'>{errors.newOwnerPhoneNumber.message}</small>} 
                                            </div>  
                                        </div> */}


                                        <div className="col-md-6"> 
                                            <div className="form-group">
                                                <label htmlFor="address">Address</label>
                                                <input type="text" id="address" className={`form-control ${errors.newOwnerAddress ? 'is-invalid' : ''}`} {...register("newOwnerAddress", { required: "field can not be empty" })}/>
                                                {errors.newOwnerAddress && <small className='error'>{errors.newOwnerAddress.message}</small>} 
                                            </div>  
                                        </div>
                                        </Fragment>
                                        }
                                    </Fragment>} 

                                    
                                    {step === 3 && <Fragment>
                                        <div className="tagline-upload mt-3">Upload the necessary documents. Supported file formats include PDF, JPG, and PNG.</div>

                                        <div className="row mt-5">
                                            {cooDocumentsToUpload.map(doc => {
                                                return (
                                                    <div className="col-6 mb-5" key={doc.name}>
                                                        <div className="d-flex document-title-container">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z" fill="#00163A"/>
                                                                <path d="M9.00141 17.7514C8.81141 17.7514 8.62141 17.6814 8.47141 17.5314L6.47141 15.5314C6.18141 15.2414 6.18141 14.7614 6.47141 14.4714C6.76141 14.1814 7.24141 14.1814 7.53141 14.4714L9.53141 16.4714C9.82141 16.7614 9.82141 17.2414 9.53141 17.5314C9.38141 17.6814 9.19141 17.7514 9.00141 17.7514Z" fill="#00163A"/>
                                                                <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#00163A"/>
                                                                <path d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z" fill="#00163A"/>
                                                            </svg>
                                                            <p className="text-14px font-bold ml-4">{doc.label}</p>
                                                        </div>
                                                        <FileUploader handleChange={(e:any) => handleChange(e, doc)} name="file" types={fileTypes}  multiple={true} classes="text-center w-100  mt-3">
                                                            <div className="file-uploader-container cursor-pointer">
                                                                <svg width="20" height="20" className='me-2' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_4956_44565)">
                                                                        <path d="M16.5004 16.5014L12.5004 12.5014M12.5004 12.5014L8.50045 16.5014M12.5004 12.5014V21.5014M20.8904 18.8914C21.8658 18.3597 22.6363 17.5183 23.0803 16.5001C23.5244 15.4818 23.6167 14.3447 23.3427 13.2681C23.0686 12.1916 22.4439 11.237 21.5671 10.5549C20.6903 9.87283 19.6113 9.50218 18.5004 9.50145H17.2404C16.9378 8.33069 16.3736 7.24378 15.5904 6.32244C14.8072 5.4011 13.8253 4.6693 12.7185 4.18206C11.6118 3.69481 10.409 3.46481 9.20057 3.50933C7.99213 3.55385 6.80952 3.87175 5.74163 4.43911C4.67374 5.00648 3.74836 5.80855 3.03507 6.78503C2.32178 7.76151 1.83914 8.88699 1.62343 10.0768C1.40772 11.2667 1.46455 12.49 1.78966 13.6547C2.11477 14.8194 2.69969 15.8953 3.50045 16.8014" stroke="#00163A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_4956_44565">
                                                                            <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg> 
                                                                Upload File
                                                            </div>
                                                        </FileUploader>
                                                        {
                                                            cooFiles && cooFiles[doc.name] &&  
                                                            <div className="d-flex mt-2 uploaded-file-name-container">
                                                                <div className="file-type-icon-container"> 
                                                                    <img src={fileIcon[cooFiles[doc.name].file.type]} alt="" height="15px"/>
                                                                </div>
                                                                <p className="text-14px">{cooFiles[doc.name].file.name}</p>
                                                                <span onClick={()=>removeCooFile(doc.name)} className="cursor-pointer">
                                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                        <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>  
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })

                                            }
                                        </div>
                                           

                                        

                                        <div className="file-upload-list mt-4">
                                            {files.length > 0 && documentCount.map((item:number)=>{
                                                return <div className="row-custom">
                                                    <div className="col-11-custom">
                                                        <div key={item} className="card mb-2" data-aos="fade-up">
                                                            <div className="card-body  py-1">
                                                                <div  className="row"> 
                                                                    <div className="col-md-8"> 
                                                                        {files[item]? <div className="row h-100">
                                                                            <div className="col-1 icon text-center my-auto"> 
                                                                                <img src={fileIcon[files[item].type]} alt=""/>
                                                                            </div>

                                                                            <div className="col-11 col-sm-10 col-lg-8 name my-auto">
                                                                               {files[item].name}
                                                                            </div>  

                                                                            <div className="col-12 col-lg-3 size my-auto text-lg-end mt-1 mt-md-auto">
                                                                               {(files[item].size/100000)}MB
                                                                            </div>

                                                                        </div>: <Skeleton></Skeleton>}
                                                                    </div> 

                                                                    <div className="col-md-4"> 
                                                                        <select className="form-control mb-3 mb-md-0 mt-1 mt-md-0" onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>processFileName({name: e.target.value, file: files[item]})}>
                                                                            <option>Select Document</option>
                                                                            {<Fragment>
                                                                                <option value={'AUTOVIN'}>Autovin</option>
                                                                                <option value={'PROOF_OF_OWNERSHIP_CERTIFICATE'}>Proof of Ownership Certificate</option>
                                                                                <option value={'PICTURE_OF_THE_CHASIS_NUMBER'}>Picture of the Chasis Number</option>
                                                                                <option value={'VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER'}>Valid Means of Identification of the new owner</option>
                                                                                <option value={'VEHICLE_LICENSE'}>Vehicle License</option> 
                                                                            </Fragment>}
                                                                        </select> 
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                    <div className="col-1-custom remove my-auto text-center"> 
                                                        <span onClick={()=>removeFile(Number(item))}>
                                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                                <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>  
                                                        </span>
                                                    </div>  
                                                </div>
                                               
                                            })}
                                        </div>
                                    </Fragment>} 
                                </div> 

                                    <div className="row h-100"> 
                                        <div className="col-md-8 my-auto">
                                        {step === 3 && processedFiles.length > 0 && <div className="circular-progress">
                                                <div className='inner my-auto'>
                                                    <CircularProgressbar value={processedFiles.length} maxValue={0.5} minValue={0.1} text={`${0.5 * 100}%`} />
                                                </div>
                                                <div className='ms-2 my-auto'>
                                                  {processedFiles.length} of 5 files uploaded Successfully 
                                                </div>
                                            </div>}
                                        </div>
                                        <div className="col-6 col-md-2">  
                                            <div className="form-group"> 
                                                <button className={step >= (totalStep===3? (totalStep - 1): totalStep) ? 'btn btn-primary': "btn btn-primary-outline disabled"} type='button' onClick={remStep}>Prev</button>
                                            </div>
                                        </div>
                                        {step < totalStep && <div className="col-6 col-md-2">  
                                            <div className="form-group"> 
                                                <button className={step <= totalStep ? "btn btn-primary": "btn btn-primary d-none"}  type='button' onClick={addStep} disabled={greyNextOne}>Next</button>
                                            </div>
                                        </div>}
                                        {step === totalStep  && <div className="col-6 col-md-2">  
                                            <div className="form-group"> 
                                                {
                                                    service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 
                                                    <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit'>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> :
                                                    <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={greyNextTwo}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button>
                                                }
                                            </div>
                                        </div>}
                                    </div>
                                </form>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegistrationService




// import React, { Fragment, Key, useEffect, useState } from 'react'
// import { firebaseStorage } from "Services/firebaseInitService";
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import "./RegistrationService.scss"
// import { makeType, RegistrationServiceType, ServiceOrderDataType } from 'Models';
// import { modelType } from 'Models/modelType';
// import { orderService, vehicleService } from 'Services'; 
// import Swal from 'sweetalert2';  
// import { useForm } from 'react-hook-form'; 
// import { VehicleClassType } from 'Models/vehicleClassType';
// import { VehicleColorType } from 'Models/vehicleColorType';
// import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import { vehicleRegistrationService } from 'Services/vehicleRegistrationService';
// import { useAuth } from 'Components/AuthProvider/AuthProvider';
// import Skeleton from 'react-loading-skeleton'
// import { FileUploader } from 'react-drag-drop-files';
// import jpg from 'Assets/Icons/jpg.png';
// import png from 'Assets/Icons/png.png';
// import pdf from 'Assets/Icons/pdf.svg';
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import { VehicleYear } from '@/Models/vehicleYear';
// import { VehicleUse } from '@/Models/vehicleUse';
// import { Lga, State } from '@/Models/location';
// import { CHANGE_OF_OWNERSHIP_SERVICE_ID, LAGOS_STATE_ID, NEW_REGISTRATION_SERVICE_ID, RENEW_REGISTRATION_SERVICE_ID } from '../../../../Constants/RegistrationConstant';
// import { OrderUploadedDocument } from '@/Models/orderUploadDocument';

// export type registrationOrderFormType = { 
//         registrationService: string | undefined,
//         vehicleMake: string,
//         vehicleModel: string,
//         vehicleYear: string|number,
//         vehicleType: string,
//         vehicleUse: string,
//         vehicleColor: string,
//         chasisNumber: string,
//         engineNumber: string,
//         requiredCRM: string,
//         nin: string,
//         stateLiencingOffice: string,
//         stateRequestingOffice: string,
//         firstName: string,
//         lastName: string,
//         phoneNumber: string,
//         email: string,
//         state: string,
//         localGovernment: string,
//         address: string,
//         vehicleIdentificationNumber?: string,
//         plateNumber? : string
//         newOwnerFirstName?: string;
//         newOwnerLastName?: string;
//         newOwnerPhoneNumber?: string;
//         newOwnerAddress?: string;
//         newOwnerEmail?: string;
// }

// const RegistrationService = () => {

//     const [searchParams, setSearchParams] = useSearchParams();

//    const { service_id } = useParams<{service_id: string}>()
//     const limit = 100;
//     const offset = 0; 
//     const navigate = useNavigate(); 
//     const { user } = useAuth(); 
     
//     const [make, setMake] = useState<Array<makeType>>()
//     const [model, setModel] = useState<Array<modelType>>()
//     const [vehicleClass, setVehicleClass] = useState<Array<VehicleClassType>>()
//     const [vehicleColor, setVehicleColor] = useState<Array<VehicleColorType>>()
//     const [vehicleUse, setVehicleUse] = useState<Array<VehicleUse>>()

//     const [states, setStates] = useState<Array<State>>()
//     const [lgas, setLgas] = useState<Array<Lga>>()
    
//     const [checkColor, setCheckColor] = useState<string>('d-none')

//     const [vehicleYear, setVehicleYear] = useState<VehicleYear[]>() 
//     const [greyButton, setGreyButton] = useState<boolean>(false) 

//     const getMake = async (type_id: string) =>{
//         await vehicleService.getMakeByTypeId(type_id).then((response) =>{
//             console.log("response make", response.data.data)
//             setMake(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }
 
//      const getYear = async () =>{
//          await vehicleService.getVehicleYear( limit, offset).then((response) =>{
//              console.log("response year", response.data.data)
//              setVehicleYear(response.data.data)
//          }, error => {
 
//              console.log("error make", error)
//              Swal.fire({
//                  position: "top-end",
//                  icon: "warning",
//                  text: error.response.data.message,
//                  showConfirmButton: false,
//                  timer: 3000
//              });
//          })
//      }

//      const getStates = async () =>{
//         await vehicleService.getStates().then((response) =>{
//             console.log("states", response.data)
//             setStates(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }

//      const getVehicleUse = async () =>{
//         await vehicleService.getVehicleUse().then((response) =>{
//             console.log("response year", response.data)
//             setVehicleUse(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }
 
//      const getModel = async (make_id:string) =>{
//          console.log(make_id)
//         await vehicleService.getModelsByMakeId(make_id).then((response) =>{
//             console.log("response model", response.data)
//             setModel(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error model", error)
//         })
//     }
 

//     const getVehicleClass = async () =>{
//         await vehicleService.getVehicleClass(limit, offset).then((response) =>{
//             console.log("response getVehicleClass", response.data)
//             setVehicleClass(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error model", error)
//         })
//     }
 

//     const getVehicleColors = async () =>{
//         await vehicleService.getVehicleColor(limit, offset).then((response) =>{
//             console.log("response getVehicleCcolor", response.data)
//             setVehicleColor(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error getVehicleCcolor", error)
//         })
//     }
 
  

//      useEffect(() => {
//          getYear()  
//          getVehicleClass()
//          getVehicleColors()
//          getVehicleUse()
//          getStates()
//      }, [])
//      const [request, setRequest] = useState(false)
//      const { register, watch, handleSubmit, getValues, setValue, formState: { errors } } = useForm<registrationOrderFormType>()

//      useEffect(() => {
//      },[
//         watch('requiredCRM'),
//      ])

//     //  const [orderId, setOrderId] = useState<string>('')
     
//      const onSubmit = async (data: registrationOrderFormType) => {

//         console.log(data)

//         setRequest(true)
//             const newData:registrationOrderFormType = { 
//                 ...data,
//                 state: data.state, 
//                 // stateLiencingOffice:  data.stateLiencingOffice,
//                 // stateRequestingOffice:  data.stateRequestingOffice,
//                 registrationService: service_id, 
//                 vehicleMake: data.vehicleMake,  
//                 vehicleYear: data.vehicleYear,                
//             }

//             setGreyButton(true)

//             console.log(newData)

//             // processFilesForUpload('jsncjkndcsd')


//             // return

//             await vehicleRegistrationService.orderRegistrationService(newData).then((response)=>{
//                 console.log(response)
//                 const orderId: string = response.data.data.uuid
//                 // setOrderId(orderId)
//                 // 8c6e6f78-5960-4c7c-9bd9-4aa71746b786
//                 setRequest(false)
//                 // 
//                 if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//                     processFilesForUpload(orderId)
//                 }
//                 // 
//                 navigate(user ? `/myaccount/registration/${orderId}/registration-summary`: `/registration/${orderId}/registration-summary`, { replace: true});
            
//                 setGreyButton(false)
//             }, error =>{
//                 console.log("onSubmit error", newData)
//                 setRequest(false)
//                 setGreyButton(false)

//                 Swal.fire({
//                     position: "top-end",
//                     icon: "warning",
//                     text: error.response.data.message,
//                     showConfirmButton: false,
//                     timer: 3000
//                 });
//             })
        
//      };

//      //COO = change of ownership

//     //  const submitCOOOrder = (data:any) => {
//     //     console.log(data)
//     //     processFilesForUpload()
//     //  }

//     const test = () => {
//         console.log(cooFiles)
//         for(const key in cooFiles){
//             console.log(key)
//         }
//     }

//     const [hasUploadedAllFilesForCOO, setHasUploadedAllFilesForCOO] = useState(false)

//     const checkIfHasUploadedAllFilesForCOO = () => {
//         setTimeout(() => {
//             console.log('okay')
//             console.log(cooFiles?.length)
//             if(
                
//                 cooFiles
//                 // cooFiles['PROOF_OF_OWNERSHIP_CERTIFICATE'] &&
//                 // cooFiles['AUTOVIN'] &&
//                 // cooFiles['PICTURE_OF_THE_CHASIS_NUMBER'] &&
//                 // cooFiles['VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER'] &&
//                 // cooFiles['VEHICLE_LICENSE']
//             ){
//                 console.log('here')
//                 setHasUploadedAllFilesForCOO(true)
//             }
//             else{
//                 setHasUploadedAllFilesForCOO(false)
//             }
//         },500)
//     }

//     // const checkIfHasUploadedAllFilesForCOO = () => {
//     //     if(
//     //         cooFiles?.['PROOF_OF_OWNERSHIP_CERTIFICATE'] &&
//     //         cooFiles?.['AUTOVIN'] &&
//     //         cooFiles?.['PICTURE_OF_THE_CHASIS_NUMBER'] &&
//     //         cooFiles?.['VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER'] &&
//     //         cooFiles?.['VEHICLE_LICENSE']
//     //     ){
//     //         setHasUploadedAllFilesForCOO(true)
//     //     }
//     //     else{
//     //         setHasUploadedAllFilesForCOO(false)
//     //     }
//     // }

//     useEffect(() => {

//     }, [hasUploadedAllFilesForCOO])

//     const processFilesForUpload = (orderId: string) => {
//         console.log(processedFiles)
//         for(const key in cooFiles){
//             console.log(key)
//             const processedFile = cooFiles[key]
//             try { 
//                 const storageRef = ref(firebaseStorage, `files/${processedFile.file.name}`); 
//                 const uploadTask = uploadBytesResumable(storageRef, processedFile.file);
     
//                 uploadTask.on("state_changed",
//                     (snapshot) => {
//                         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
//                     },(error) => {
//                         console.log(error)
//                         alert(error);
//                     },() => {
//                         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

//                         console.log(orderId)

//                         const payload: OrderUploadedDocument = {
//                             documentName: processedFile.name,
//                             documentUrl: downloadURL,
//                             orderUuid: orderId
//                         }

//                         uploadCOODocuments(payload)
    
//                             console.log('File uploaded successfully.', downloadURL);
//                             // saveFile(downloadURL)  
//                         });
//                     }
//                     );
    
//               } catch (error) {
//                 Swal.fire({
//                     position: "top-end",
//                     icon: "warning",
//                     text: "Error occured while uploading file, please try again.",
//                     showConfirmButton: false,
//                     timer: 3000
//                 });
//                 console.error('Error uploading file:', error);
//               }
//         }
//      }

//     //  const processFilesForUpload = (orderId: string) => {
//     //     console.log(processedFiles)
//     //     processedFiles.forEach((processedFile) => {
//     //         try { 
//     //             const storageRef = ref(firebaseStorage, `files/${processedFile.file.name}`); 
//     //             const uploadTask = uploadBytesResumable(storageRef, processedFile.file);
     
//     //             uploadTask.on("state_changed",
//     //                 (snapshot) => {
//     //                     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
//     //                 },(error) => {
//     //                     console.log(error)
//     //                     alert(error);
//     //                 },() => {
//     //                     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

//     //                     console.log(orderId)

//     //                     const payload: OrderUploadedDocument = {
//     //                         documentName: processedFile.name,
//     //                         documentUrl: downloadURL,
//     //                         orderUuid: orderId
//     //                     }

//     //                     uploadCOODocuments(payload)
    
//     //                         console.log('File uploaded successfully.', downloadURL);
//     //                         // saveFile(downloadURL)  
//     //                     });
//     //                 }
//     //                 );
    
//     //           } catch (error) {
//     //             Swal.fire({
//     //                 position: "top-end",
//     //                 icon: "warning",
//     //                 text: "Error occured while uploading file, please try again.",
//     //                 showConfirmButton: false,
//     //                 timer: 3000
//     //             });
//     //             console.error('Error uploading file:', error);
//     //           }
//     //     })
//     //  }

//      const uploadCOODocuments = async (payload: OrderUploadedDocument) => {
//         console.log(payload)
//         await orderService.orderUploadDocument(payload).then((response)=>{
//             console.log(response)
//         }, error =>{
//             console.log(error)
//         })
//      }
  
 
     
//      useEffect(()=>{ 
//          setGreyButton(false)
//        }, [watch])
  
    
       
//     const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

//     const handleState = async (e: any) => {
//         const state_id = e.target.value;  
//         console.log(state_id)
//         await vehicleService.getLGAsByStateId(state_id).then((response)=>{
//             console.log("getService", response)
//             setLgas(response.data.data)
//           }, error=>{
//            console.log("getService error", error)
//            Swal.fire({
//                position: "top-end",
//                icon: "warning",
//                text: error.response.data.message,
//                showConfirmButton: false,
//                timer: 3000
//            });
//           })
//       }
      
//       //check if it is COO service
//     //   const totalStep = service_id==='6757be8a-5611-412f-9919-faef90d83865'? 3 : 2;
//     const totalStep = service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 3 : 2;

//     //   const [step, setStep] = useState(1)
//     const [step, setStep] = useState(1)

//       const addStep = () => { 
//           if(step <= 2){
//               setStep(step + 1);
//           }  
//       }
  
//       const remStep = () => { 
//           if(step >= 1){
//               setStep(step - 1);
//           } 
//       }

//     const [serviceDetail, setServiceDetail] = useState<RegistrationServiceType>()
//     const [serviceRequest, setServiceRequest] = useState<boolean>()

//     const getService = async () =>{
//         setServiceRequest(true)
//        await vehicleService.getServiceItem(service_id).then((response)=>{
//          console.log("getService", response)
//          setServiceDetail(response.data.data)
//          setServiceRequest(false)
//        }, error=>{
//         setServiceRequest(false)
//         console.log("getService error", error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             text: error.response.data.message,
//             showConfirmButton: false,
//             timer: 3000
//         });
//        })
//     }

//     useEffect(()=>{
//         getService()
//     }, [service_id])

//     const [documentCount, setDocumentCount] = useState([0, 1, 2, 3, 4]);
//     const [files, setFile] = useState<File[]>([]);
//     const [processedFiles, setProcessedFiles] = useState<{name: string, file: File}[]>([]);

//     const fileTypes = ["JPG", "PNG"];
    
//     const fileIcon:any = {
//         'image/png': png,
//         'image/jpg': jpg,
//         'image/jpeg': jpg,
//         'image/pdf': pdf
//     }

//     const [cooFiles, setCooFiles] = useState<any>()


//     const handleChange = (file: FileList, document:any) => {
//         let fileName = document.name
//         setCooFiles({...cooFiles, [fileName]:{name: fileName, file: file[0]} })
//         checkIfHasUploadedAllFilesForCOO()
//     };

//     const removeCooFile = (name: string) => {
//         const updatedCooFiles = { ...cooFiles };
//         delete updatedCooFiles[name];
//         setCooFiles(updatedCooFiles);
//         checkIfHasUploadedAllFilesForCOO()
//     }

//     const removeFile =(indexToDelete: number)=>{ 

//         const updatedFiles = files.filter((_, index) => index !== indexToDelete);
//         const updatedProcFiles = processedFiles.filter((_, index) => index !== indexToDelete);
//         setFile(updatedFiles); 
//         setProcessedFiles(updatedProcFiles)
//     }

//     const processFileName =(data: {name: string, file: File})=>{ 
//         const checkExist = processedFiles.some(fileItem => fileItem.name === data.name);
        
//         if(!checkExist){
//            setProcessedFiles([...processedFiles, data])
//         }
//     }

//     const doesFileNameExist = (fileName: string): boolean => {
//         return processedFiles.some(fileItem => fileItem.name === fileName);
//     };


//     // const [documentCount, setDocumentCount] = useState([0, 1, 2, 3, 4]);
//     // const [files, setFile] = useState<File[]>([]);
//     // const [processedFiles, setProcessedFiles] = useState<{name: string, file: File}[]>([]);

//     // const fileTypes = ["JPG", "PNG"];
    
//     // const fileIcon:any = {
//     //     'image/png': png,
//     //     'image/jpg': jpg,
//     //     'image/jpeg': jpg,
//     //     'image/pdf': pdf
//     // }

//     // const handleChange = (file: FileList) => {
//     //     console.log([...Array.from(file), ...files]); 

//     //     // setFile([...Array.from(file), ...files]);
//     //     setFile([...files, ...Array.from(file)]);
//     // };

//     // const removeFile =(indexToDelete: number)=>{ 

//     //     const updatedFiles = files.filter((_, index) => index !== indexToDelete);
//     //     const updatedProcFiles = processedFiles.filter((_, index) => index !== indexToDelete);
//     //     setFile(updatedFiles); 
//     //     setProcessedFiles(updatedProcFiles)
//     // }

//     // const processFileName =(data: {name: string, file: File})=>{ 
//     //     const checkExist = processedFiles.some(fileItem => fileItem.name === data.name);
        
//     //     if(!checkExist){
//     //        setProcessedFiles([...processedFiles, data])
//     //     }
//     // }

//     // const doesFileNameExist = (fileName: string): boolean => {
//     //     return processedFiles.some(fileItem => fileItem.name === fileName);
//     // };

//     useEffect(() => {
//         if((processedFiles.length < documentCount.length) && totalStep === 3){
//             setGreyButton(true)
//         }
//     }, [processedFiles])

//     const [greyNextOne, setGreyNextOne] = useState(false)

//     useEffect(() => {
//         if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//             const fields: Array<keyof registrationOrderFormType> = ['vehicleIdentificationNumber', 'plateNumber', 'vehicleType', 'vehicleMake', 'vehicleModel'];
//             const allFieldsFilled = fields.every(field => !!watch(field));
        
//             setGreyNextOne(!allFieldsFilled);
//         }
//         else{
//             if(getValues('requiredCRM')){
//                 const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear', 'nin', 'stateLiencingOffice', 'stateRequestingOffice'];
//                 console.log(fields)
//                 const allFieldsFilled = fields.every(field => !!watch(field));
//                 console.log(!allFieldsFilled)
//                 setGreyNextOne(!allFieldsFilled);
//             }
//             else{
//                 const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear'];
//                 console.log(fields)
//                 const allFieldsFilled = fields.every(field => !!watch(field));
//                 console.log(!allFieldsFilled)
            
//                 setGreyNextOne(!allFieldsFilled);
//             }
//         }
//       }, [
//         watch('vehicleIdentificationNumber'),
//         watch('plateNumber'),
//         watch('vehicleMake'),
//         watch('vehicleModel'),
//         watch('vehicleType'),
//         watch('vehicleColor'),
//         watch('vehicleUse'),
//         watch('vehicleYear'),
//         watch('nin'),
//         watch('chasisNumber'),
//         watch('requiredCRM'),
//         watch('stateLiencingOffice'),
//         watch('stateRequestingOffice')
//       ]);

//     // useEffect(() => {
//     //     if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//     //         console.log('okayyyy')
//     //         const fields: Array<keyof registrationOrderFormType> = ['vehicleIdentificationNumber', 'plateNumber', 'vehicleType', 'vehicleMake', 'vehicleModel'];
//     //         const allFieldsFilled = fields.every(field => !!watch(field));
        
//     //         setGreyNextOne(!allFieldsFilled);
//     //     }
//     //     else{
//     //         console.log('hereee')
//     //         const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear', 'nin'];
//     //         const allFieldsFilled = fields.every(field => !!watch(field));
        
//     //         setGreyNextOne(!allFieldsFilled);
//     //     }
//     //   }, [
//     //     watch('vehicleIdentificationNumber'),
//     //     watch('plateNumber'),
//     //     watch('vehicleMake'),
//     //     watch('vehicleModel'),
//     //     watch('vehicleType'),
//     //     watch('vehicleColor'),
//     //     watch('vehicleUse'),
//     //     watch('vehicleYear'),
//     //     watch('nin')
//     //   ]);


//     const [greyNextTwo, setGreyNextTwo] = useState(false)

//     const [cooDocumentsToUpload, setCooDocumentsToUpload] = useState<any[]>([
//         {
//             name: 'PROOF_OF_OWNERSHIP_CERTIFICATE',
//             label: 'Proof of Ownership'
//         },
//         {
//             name: 'AUTOVIN',
//             label: 'Autovin'
//         },
//         {
//             name: 'PICTURE_OF_THE_CHASIS_NUMBER',
//             label: 'Picture of the Chasis Number'
//         },
//         {
//             name: 'VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER',
//             label: 'Means of ID of New Owner'
//         },
//         {
//             name: 'VEHICLE_LICENSE',
//             label: 'Vehicle License (Up-to-Date)'
//         }
//     ])

//     const [existingOrderDetails, setExistingOrderDetails] = useState<ServiceOrderDataType>()

//     const fetchOrderDetails = async (orderId: string) => {
//         await orderService.getServiceOrderId(orderId).then((response) =>{
//                 console.log(response)
//                 setExistingOrderDetails(response.data.data)
//                 fetchDropdownData(response.data.data)
//                prefillForm(response.data.data)
//            }, error => {
    
//                console.log("error vehicleServices", error)
//                Swal.fire({
//                    position: "top-end",
//                    icon: "warning",
//                    text: error.response.data.message,
//                    showConfirmButton: false,
//                    timer: 3000
//                });
//            })
//     }

//     const fetchDropdownData = (data: ServiceOrderDataType) => {
//         getMake(data.vehicleType.uuid)
//         getModel(data.vehicleMake.uuid)
//     }

//     const prefillForm = (data: ServiceOrderDataType) => {
//         console.log(data)
//         setValue('vehicleIdentificationNumber', '')
//         setValue('plateNumber', data.plateNumber)
//         setValue('vehicleType', data.vehicleType.uuid)
//         // setValue('vehicleMake', data.vehicleMake.uuid)
//         // setValue('vehicleModel', data.vehicleModel.uuid)
//         setValue('vehicleYear', data.vehicleYear.uuid)
//         setValue('vehicleUse', data.vehicleUse.uuid)
//         setValue('vehicleColor', data.vehicleColor)
//         setValue('engineNumber', data.engineNumber)
//         setValue('chasisNumber', data.chasisNumber)
//         // setValue('requiredCRM', data.requiredCRM)
//         setValue('nin', data.nin)
//         setValue('stateLiencingOffice', data.stateLiencingOffice)
//         setValue('stateRequestingOffice', data.stateRequestingOffice)
//         setValue('firstName', data.firstName)
//         setValue('lastName', data.lastName)
//         setValue('email', data.email)
//         setValue('phoneNumber', data.phoneNumber)
//         setValue('address', data.address)
//         setValue('state', LAGOS_STATE_ID)
//         setValue('localGovernment', data.localGovernment.id)
//         setValue('newOwnerFirstName', data.newOwnerFirstName)
//         setValue('newOwnerLastName', data.newOwnerLastName)
//         setValue('newOwnerPhoneNumber', data.newOwnerPhoneNumber)
//         setValue('newOwnerAddress', data.newOwnerAddress)
//     }

//     useEffect(() => {
//         // const fields: Array<keyof registrationOrderFormType> = ['firstName', 'lastName', 'phoneNumber', 'address', 'email', 'localGovernment', 'state'];
//         const fields: Array<keyof registrationOrderFormType> = ['firstName', 'lastName', 'phoneNumber', 'address'];
//         const allFieldsFilled = fields.every(field => !!watch(field));

//         console.log(getValues())
    
//         setGreyNextTwo(!allFieldsFilled);
//       }, [
//         watch('firstName'),
//         watch('lastName'),
//         watch('phoneNumber'),
//         watch('address'),
//         watch('email'),
//         watch('localGovernment'),
//         watch('state')
//       ]);

//       const [serviceType, setServiceType] = useState<string>()

//       useEffect(() => {
//         if(service_id === RENEW_REGISTRATION_SERVICE_ID){
//             setServiceType('renew-registration')
//         }
//         else if(service_id === NEW_REGISTRATION_SERVICE_ID){
//             setServiceType('new-registration')
//         }
//         else if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//             setServiceType('change-of-ownership')
//         }
//       },[service_id])

//       useEffect(() => {
//         if(searchParams.get('step')){
//             setStep(Number(searchParams.get('step')))
//         }
//       }, [searchParams.get('step')])

//       const [existingOrderId, setExistingOrderId] = useState<string>()

//       useEffect(() => {
//         if(searchParams.get('orderId')){
//             let orderId = String(searchParams.get('orderId'))
//             setExistingOrderId(orderId)
//             fetchOrderDetails(orderId)
//         }
//       }, [searchParams.get('orderId')])

//       useEffect(() => {
//         if(existingOrderId){
//             setValue('vehicleMake', String(existingOrderDetails?.vehicleMake.uuid))
//         }
//       }, [make])

//       useEffect(() => {
//         if(existingOrderId){
//             console.log(model)
//             console.log(String(existingOrderDetails?.vehicleModel.uuid))
//             setValue('vehicleModel', String(existingOrderDetails?.vehicleModel.uuid))
//         }
//       }, [model])


//     return (
//         <div className='registration-service-page'>
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col-md-9"> 
//                         <div className="top-header py-4 px-0">
//                             {!serviceRequest && <div className="title">{serviceDetail && serviceDetail?.name}</div>}
//                             {serviceRequest && <div className="title"><Skeleton width="50%" height={20}/></div>}
//                             <div className="desc d-none">Fill the form below to register your newly purchased vehicle or foreign used vehicle.</div>
//                         </div>
//                         <div className="card px-3 px-md-4">
                           
//                             <div className="card-body px-0"> 
//                             {step <= 2 && <div className="row pb-0 mb-0">
//                                     <div className="col-md-6 pb-0 mb-0">
//                                         <div className="section-title">
//                                            Vehicle {step === 2 && "Owner"} Information
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="section-pagination">
//                                             {step} of {totalStep}
//                                         </div>
//                                     </div>
//                                 </div>}

//                             {step === 3 && <div className="row pb-0 mb-0">
//                                     <div className="col-md-6 pb-0 mb-0">
//                                         <div className="section-title">
//                                             Upload Documents
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="section-pagination">
//                                             {step} of {totalStep}
//                                         </div>
//                                     </div>
//                                 </div>}
                               
//                                 <form onSubmit={handleSubmit(onSubmit)} className='VehicleTypeForm'>
//                                     <div className="row"> 
//                                        {step ===1 && <Fragment>

//                                         {totalStep === 3 && <Fragment>
//                                             <div className="col-md-6">  
//                                                 <div className="form-group">
//                                                     <label htmlFor="vehicleIdentificationNumber">Vehicle Identification Number (VIN)</label> 
//                                                     <input type="text" id="vehicleIdentificationNumber" className={`form-control ${errors.vehicleIdentificationNumber ? 'is-invalid' : ''}`} {...register("vehicleIdentificationNumber", { required: "field can not be empty" })}/>
//                                                     {errors.vehicleIdentificationNumber && <small className='error'>{errors.vehicleIdentificationNumber.message}</small>} 
//                                                 </div>  
//                                             </div> 
//                                             <div className="col-md-6">  
//                                                 <div className="form-group">
//                                                     <label htmlFor="licensePlateNumber">License Plate Number</label> 
//                                                     <input type="text" id="licensePlateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
//                                                     {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
//                                                 </div>  
//                                             </div>  
//                                         </Fragment>}

//                                         {serviceType === 'renew-registration' &&
//                                             <div className="col-md-6"> 
//                                                 <div className="form-group">
//                                                     <label htmlFor="nin">Plate Number</label>
//                                                     <input type="text" id="plateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
//                                                     {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
//                                                 </div>  
//                                             </div>
//                                         }

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleClass">Vehicle Class</label>
//                                                 <select id="vehicleClass" className={`form-control ${errors.vehicleType ? 'is-invalid' : ''}`} {...register("vehicleType", { required: "field can not be empty", onChange: (e)=>getMake(e.target.value) })}>
//                                                     <option value="">select class</option>
//                                                     {vehicleClass && vehicleClass.map((vehicleCla: VehicleClassType, index:Key)=>{
//                                                        return <option key={index} value={vehicleCla.uuid}>{vehicleCla.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleType && <small className='error'>{errors.vehicleType.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6">  
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleMakeId">Make</label>
//                                                 <select id="vehicleMakeId" className={`form-control ${errors.vehicleMake ? 'is-invalid' : ''}`} {...register("vehicleMake", { required: "field can not be empty", onChange: (e)=>getModel(e.target.value)})}>
//                                                     <option value="">select make</option> 
//                                                     {make && make.map((mk: makeType, index:Key)=>{
//                                                       return <option key={index} value={mk.uuid}>{mk.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleMake && <small className='error'>{errors.vehicleMake.message}</small>} 
//                                             </div>
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleModel">Model</label>
//                                                 <select id="vehicleModel" className={`form-control ${errors.vehicleModel ? 'is-invalid' : ''}`} {...register("vehicleModel", { required: "field can not be empty" })}>
//                                                     <option value="">select model</option> 
//                                                     {model && model.map((mod: modelType, index:Key)=>{
//                                                     return <option key={index} value={mod.uuid}>{mod.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleModel && <small className='error'>{errors.vehicleModel.message}</small>} 
//                                             </div>
//                                         </div>

//                                          {totalStep !== 3 && <Fragment>
//                                             <div className="col-md-6"> 
//                                                 <div className="form-group">
//                                                     <label htmlFor="year">Vehicle Year</label>
//                                                     <select id="year" className={`form-control ${errors.vehicleYear ? 'is-invalid' : ''}`} {...register("vehicleYear", { required: "field can not be empty" })}>
//                                                         <option value="">select year</option>
//                                                         {vehicleYear && vehicleYear.map((year: VehicleYear, index:Key)=>{
//                                                         return <option key={index} value={year.uuid}>{year.year}</option>
//                                                         })}
//                                                     </select>
//                                                     {errors.vehicleYear && <small className='error'>{errors.vehicleYear.message}</small>} 
//                                                 </div>  
//                                             </div>
                                        

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleUse">Vehicle Use</label>
//                                                 <select id="vehicleUse" className={`form-control ${errors.vehicleUse ? 'is-invalid' : ''}`}{...register("vehicleUse", { required: "field can not be empty" })}>
//                                                     <option value="">select use</option> 
//                                                     {vehicleUse && vehicleUse.map((vehicleUse: VehicleUse, index:Key)=>{
//                                                        return <option key={index} value={vehicleUse.uuid}>{vehicleUse.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleUse && <small className='error'>{errors.vehicleUse.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6">  

//                                             <div className="form-group row">
//                                                 <label htmlFor="color">Vehicle Color</label>
//                                                 <div className={ checkColor === 'd-block' ? 'col-6 pe-3' : 'col-12'}>
//                                                     <select {...checkColor === 'd-none' ? {...register("vehicleColor")} : ''} 
//                                                     onChange={(e) => {e.target.value === 'custom' ? setCheckColor('d-block') : setCheckColor('d-none')}}
//                                                     id="color" className={`form-control ${errors.vehicleColor ? 'is-invalid' : ''}`}>
//                                                         <option value="">select color</option> 
//                                                         <option value="custom">Custom Color</option>
//                                                         {vehicleColor && vehicleColor.map((vehicleCol: VehicleColorType, index:Key)=>{
//                                                         return <option key={index} value={vehicleCol.color}>{vehicleCol.color}</option>
//                                                         })}
//                                                     </select>
//                                                     {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
//                                                 </div>

//                                                 <div className={ checkColor === 'd-block' ? `${checkColor} col-6 ps-3` : `${checkColor}`}>
//                                                     {/* <label htmlFor="chasisNumber">Custom Color</label> */}
//                                                     <input type="text" {...checkColor === 'd-block' ? {...register("vehicleColor")} : ''} id="customColor" className='form-control' placeholder='Type color, e.g purple'/> 
//                                                     {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
//                                                 </div>
//                                             </div> 
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="engineNumber">Engine Number</label>
//                                                 <input type="text" id="engineNumber" className={`form-control ${errors.engineNumber ? 'is-invalid' : ''}`} {...register("engineNumber", { required: "field can not be empty" })}/>
//                                                 {errors.engineNumber && <small className='error'>{errors.engineNumber.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="chasisNumber">Chasis Number</label>
//                                                 <input type="text" id="chasisNumber" className={`form-control ${errors.chasisNumber ? 'is-invalid' : ''}`} {...register("chasisNumber", { required: "field can not be empty" })}/>
//                                                 {errors.chasisNumber && <small className='error'>{errors.chasisNumber.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         </Fragment>}
                                

//                                         <div className="col-md-6"> 
//                                             <div className="form-group d-flex form-crm-input">
//                                                 <input type="checkbox" id="requiredCRM" className='my-auto' {...register("requiredCRM")}/> 
//                                                 <label htmlFor="requiredCRM" className='my-auto'>CMR(Central Motor Registry)</label>
//                                                 {errors.requiredCRM && <small className='error'>{errors.requiredCRM.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         {totalStep !== 3 && <Fragment>


//                                             { getValues('requiredCRM') &&
//                                                 <>
//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="nin">NIN</label>
//                                                             <input type="text" id="nin" className={`form-control ${errors.nin ? 'is-invalid' : ''}`} {...register("nin", { required: "field can not be empty" })}/>
//                                                             {errors.nin && <small className='error'>{errors.nin.message}</small>} 
//                                                         </div>  
//                                                     </div>

//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="stateLiencingOffice">State licensing office</label>
//                                                             <select id="stateLiencingOffice" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('stateLiencingOffice', { required: "This field is required"})}>
//                                                                 <option>select state</option>
//                                                                 {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                             </select>
//                                                             {errors.stateLiencingOffice && <small className='error'>{errors.stateLiencingOffice.message}</small>} 
//                                                         </div>
//                                                     </div>

//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="stateRequestingOffice">State Requesting office</label>
//                                                             <select id="stateRequestingOffice" className={`form-control ${errors.stateRequestingOffice ? 'is-invalid' : ''}`} {...register('stateRequestingOffice', { required: "This field is required"})}>
//                                                                 <option>select state</option>
//                                                                 {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                             </select>
//                                                             {errors.stateRequestingOffice && <small className='error'>{errors.stateRequestingOffice.message}</small>} 
//                                                         </div>
//                                                     </div>
//                                                 </>
//                                             }

//                                         </Fragment>}

                                        
//                                     </Fragment>}


//                                     {step === 2 && <Fragment>
//                                         {totalStep === 3 && <Fragment> 
//                                         <div className="tagline--sub-heading mt-3 fw-bolder text-dark">CURRENT OWNER’S DETAILS</div>
                                             
//                                         </Fragment>}

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="firstName">First Name</label>
//                                                 <input type="text" id="firstName" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} {...register("firstName", { required: "field can not be empty" })}/>
//                                                 {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lastName">Last Name</label>
//                                                 <input type="text" id="lastName" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} {...register("lastName", { required: "field can not be empty" })}/>
//                                                 {errors.lastName && <small className='error'>{errors.lastName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                          {totalStep !== 3 &&<div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
//                                                 {errors.email && <small className='error'>{errors.email.message}</small>} 
//                                             </div>  
//                                         </div>}


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="phoneNumber">Phone Number</label>
//                                                 <input type="number" id="phoneNumber" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} {...register("phoneNumber", { required: "field can not be empty" })}/>
//                                                 {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="address">Address</label>
//                                                 <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register("address", { required: "field can not be empty" })}/>
//                                                 {errors.address && <small className='error'>{errors.address.message}</small>} 
//                                             </div>  
//                                         </div>
 
//                                         {totalStep !== 3 && <Fragment>
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="state">State</label>
//                                                 <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => handleState(e)})}>
//                                                     <option>select state</option>
//                                                     {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                 </select>
//                                                 {errors.state && <small className='error'>{errors.state.message}</small>} 
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lga">L.G.A</label>
//                                                 <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment', { required: "This field is required"})}>
//                                                     <option>select L.G.A</option>
//                                                     {lgas?.map((lgaItem:Lga, index: Key)=><option key={index} value={lgaItem.id}>{lgaItem.name}</option>)}
//                                                 </select>
//                                                 {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
//                                             </div>  
//                                         </div> 
//                                         </Fragment>}

//                                         {totalStep === 3 && <Fragment> 
//                                         <div className="tagline--sub-heading mt-3 fw-bolder text-dark mt-4">NEW OWNER’S DETAILS</div>
                                            
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="firstName">First Name</label>
//                                                 <input type="text" id="firstName" className={`form-control ${errors.newOwnerFirstName ? 'is-invalid' : ''}`} {...register("newOwnerFirstName", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerFirstName && <small className='error'>{errors.newOwnerFirstName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lastName">Last Name</label>
//                                                 <input type="text" id="lastName" className={`form-control ${errors.newOwnerLastName ? 'is-invalid' : ''}`} {...register("newOwnerLastName", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerLastName && <small className='error'>{errors.newOwnerLastName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
//                                                 {errors.email && <small className='error'>{errors.email.message}</small>} 
//                                             </div>  
//                                         </div>

//                                          {/* {totalStep !== 3 && <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.newOwnerEmail ? 'is-invalid' : ''}`} {...register("newOwnerEmail", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerEmail && <small className='error'>{errors.newOwnerEmail.message}</small>} 
//                                             </div>  
//                                         </div>} */}


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="phoneNumber">Phone Number</label>
//                                                 <input type="number" id="phoneNumber" className={`form-control ${errors.newOwnerPhoneNumber ? 'is-invalid' : ''}`} {...register("newOwnerPhoneNumber", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerPhoneNumber && <small className='error'>{errors.newOwnerPhoneNumber.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="address">Address</label>
//                                                 <input type="text" id="address" className={`form-control ${errors.newOwnerAddress ? 'is-invalid' : ''}`} {...register("newOwnerAddress", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerAddress && <small className='error'>{errors.newOwnerAddress.message}</small>} 
//                                             </div>  
//                                         </div>
//                                         </Fragment>
//                                         }
//                                     </Fragment>} 

                                    
//                                     {step === 3 && <Fragment>
//                                         <button type='button' onClick={test}>test</button>
//                                         <div className="tagline-upload mt-3">Upload the necessary documents. Supported file formats include PDF, JPG, and PNG.</div>

//                                         <div className="row mt-5">
//                                             {cooDocumentsToUpload.map(doc => {
//                                                 return (
//                                                     <div className="col-6 mb-5">
//                                                         <div className="d-flex document-title-container">
//                                                             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path d="M9 17.75C8.9 17.75 8.81 17.73 8.71 17.69C8.43 17.58 8.25 17.3 8.25 17V11C8.25 10.59 8.59 10.25 9 10.25C9.41 10.25 9.75 10.59 9.75 11V15.19L10.47 14.47C10.76 14.18 11.24 14.18 11.53 14.47C11.82 14.76 11.82 15.24 11.53 15.53L9.53 17.53C9.39 17.67 9.19 17.75 9 17.75Z" fill="#00163A"/>
//                                                                 <path d="M9.00141 17.7514C8.81141 17.7514 8.62141 17.6814 8.47141 17.5314L6.47141 15.5314C6.18141 15.2414 6.18141 14.7614 6.47141 14.4714C6.76141 14.1814 7.24141 14.1814 7.53141 14.4714L9.53141 16.4714C9.82141 16.7614 9.82141 17.2414 9.53141 17.5314C9.38141 17.6814 9.19141 17.7514 9.00141 17.7514Z" fill="#00163A"/>
//                                                                 <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#00163A"/>
//                                                                 <path d="M22 10.7485H18C14.58 10.7485 13.25 9.41852 13.25 5.99852V1.99852C13.25 1.69852 13.43 1.41852 13.71 1.30852C13.99 1.18852 14.31 1.25852 14.53 1.46852L22.53 9.46852C22.74 9.67852 22.81 10.0085 22.69 10.2885C22.57 10.5685 22.3 10.7485 22 10.7485ZM14.75 3.80852V5.99852C14.75 8.57852 15.42 9.24852 18 9.24852H20.19L14.75 3.80852Z" fill="#00163A"/>
//                                                             </svg>
//                                                             <p className="text-14px font-bold ml-4">{doc.label}</p>
//                                                         </div>
//                                                         <FileUploader handleChange={(e:any) => handleChange(e, doc)} name="file" types={fileTypes}  multiple={true} classes="text-center w-100  mt-3">
//                                                             <div className="file-uploader-container cursor-pointer">
//                                                                 <svg width="20" height="20" className='me-2' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                     <g clip-path="url(#clip0_4956_44565)">
//                                                                         <path d="M16.5004 16.5014L12.5004 12.5014M12.5004 12.5014L8.50045 16.5014M12.5004 12.5014V21.5014M20.8904 18.8914C21.8658 18.3597 22.6363 17.5183 23.0803 16.5001C23.5244 15.4818 23.6167 14.3447 23.3427 13.2681C23.0686 12.1916 22.4439 11.237 21.5671 10.5549C20.6903 9.87283 19.6113 9.50218 18.5004 9.50145H17.2404C16.9378 8.33069 16.3736 7.24378 15.5904 6.32244C14.8072 5.4011 13.8253 4.6693 12.7185 4.18206C11.6118 3.69481 10.409 3.46481 9.20057 3.50933C7.99213 3.55385 6.80952 3.87175 5.74163 4.43911C4.67374 5.00648 3.74836 5.80855 3.03507 6.78503C2.32178 7.76151 1.83914 8.88699 1.62343 10.0768C1.40772 11.2667 1.46455 12.49 1.78966 13.6547C2.11477 14.8194 2.69969 15.8953 3.50045 16.8014" stroke="#00163A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                     </g>
//                                                                     <defs>
//                                                                         <clipPath id="clip0_4956_44565">
//                                                                             <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
//                                                                         </clipPath>
//                                                                     </defs>
//                                                                 </svg> 
//                                                                 Upload File
//                                                             </div>
//                                                         </FileUploader>
//                                                         {
//                                                             cooFiles && cooFiles[doc.name] &&  
//                                                             <div className="d-flex mt-2 uploaded-file-name-container">
//                                                                 <div className="file-type-icon-container"> 
//                                                                     <img src={fileIcon[cooFiles[doc.name].file.type]} alt="" height="15px"/>
//                                                                 </div>
//                                                                 <p className="text-14px">{cooFiles[doc.name].file.name}</p>
//                                                                 <span onClick={()=>removeCooFile(doc.name)} className="cursor-pointer">
//                                                                     <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                         <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                         <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                         <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                     </svg>  
//                                                                 </span>
//                                                             </div>
//                                                         }
//                                                     </div>
//                                                 )
//                                             })

//                                             }
//                                         </div>
                                           

                                        

//                                         <div className="file-upload-list mt-4">
//                                             {files.length > 0 && documentCount.map((item:number)=>{
//                                                 return <div className="row-custom">
//                                                     <div className="col-11-custom">
//                                                         <div key={item} className="card mb-2" data-aos="fade-up">
//                                                             <div className="card-body  py-1">
//                                                                 <div  className="row"> 
//                                                                     <div className="col-md-8"> 
//                                                                         {files[item]? <div className="row h-100">
//                                                                             <div className="col-1 icon text-center my-auto"> 
//                                                                                 <img src={fileIcon[files[item].type]} alt=""/>
//                                                                             </div>

//                                                                             <div className="col-11 col-sm-10 col-lg-8 name my-auto">
//                                                                                {files[item].name}
//                                                                             </div>  

//                                                                             <div className="col-12 col-lg-3 size my-auto text-lg-end mt-1 mt-md-auto">
//                                                                                {(files[item].size/100000)}MB
//                                                                             </div>

//                                                                         </div>: <Skeleton></Skeleton>}
//                                                                     </div> 

//                                                                     <div className="col-md-4"> 
//                                                                         <select className="form-control mb-3 mb-md-0 mt-1 mt-md-0" onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>processFileName({name: e.target.value, file: files[item]})}>
//                                                                             <option>Select Document</option>
//                                                                             {<Fragment>
//                                                                                 <option value={'AUTOVIN'}>Autovin</option>
//                                                                                 <option value={'PROOF_OF_OWNERSHIP_CERTIFICATE'}>Proof of Ownership Certificate</option>
//                                                                                 <option value={'PICTURE_OF_THE_CHASIS_NUMBER'}>Picture of the Chasis Number</option>
//                                                                                 <option value={'VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER'}>Valid Means of Identification of the new owner</option>
//                                                                                 <option value={'VEHICLE_LICENSE'}>Vehicle License</option> 
//                                                                             </Fragment>}
//                                                                         </select> 
//                                                                     </div> 
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div> 
//                                                     <div className="col-1-custom remove my-auto text-center"> 
//                                                         <span onClick={()=>removeFile(Number(item))}>
//                                                             <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                 <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                 <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                             </svg>  
//                                                         </span>
//                                                     </div>  
//                                                 </div>
                                               
//                                             })}
//                                         {/*files.length > 0 && files.map((fileItem: any, index:Key)=>{ 
//                                             return <div key={index} className="row mb-3" data-aos="fade-up">
//                                                 <div className="col-md-6">
//                                                     <div className="card">
//                                                         <div className="card-body py-2">
//                                                             <div className="row h-100">
//                                                                 <div className="col-1 icon text-center my-auto"> 
//                                                                     <img src={fileIcon[fileItem.type]} alt=""/>
//                                                                 </div>

//                                                                 <div className="col-7 name my-auto">
//                                                                    {fileItem.name}
//                                                                 </div>  

//                                                                 <div className="col-3 size my-auto text-center">
//                                                                    {(fileItem.size/100000)}mb
//                                                                 </div>

//                                                                 <div className="col-1 remove my-auto"> 
//                                                                     <span onClick={()=>removeFile(Number(index))}>
//                                                                         <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                             <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                             <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                             <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                         </svg>  
//                                                                     </span>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="col-md-6">
//                                                     <select className="form-control mt-3 mt-md-0">
//                                                         <option>Select Document</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                            })*/}
//                                         </div>
//                                     </Fragment>} 
//                                 </div> 

//                                     <div className="row h-100"> 
//                                         <div className="col-md-8 my-auto">
//                                         {step === 3 && processedFiles.length > 0 && <div className="circular-progress">
//                                                 <div className='inner my-auto'>
//                                                     <CircularProgressbar value={processedFiles.length} maxValue={0.5} minValue={0.1} text={`${0.5 * 100}%`} />
//                                                 </div>
//                                                 <div className='ms-2 my-auto'>
//                                                   {processedFiles.length} of 5 files uploaded Successfully 
//                                                 </div>
//                                             </div>}
//                                         </div>
//                                         <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 <button className={step >= (totalStep===3? (totalStep - 1): totalStep) ? 'btn btn-primary': "btn btn-primary-outline disabled"} type='button' onClick={remStep}>Prev</button>
//                                             </div>
//                                         </div>
//                                         {step < totalStep && <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 <button className={step <= totalStep ? "btn btn-primary": "btn btn-primary d-none"}  type='button' onClick={addStep} disabled={greyNextOne}>Next</button>
//                                             </div>
//                                         </div>}
//                                         {step === totalStep  && <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 {
//                                                     service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 
//                                                     // <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={processedFiles.length < 5}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> :
//                                                     // <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={!hasUploadedAllFilesForCOO}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> :
//                                                     <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit'>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> :
//                                                     <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={greyNextTwo}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button>
//                                                 }
//                                             </div>
//                                         </div>}
//                                     </div>
//                                 </form>  
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default RegistrationService






// import React, { Fragment, Key, useEffect, useState } from 'react'
// import { firebaseStorage } from "Services/firebaseInitService";
// import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
// import "./RegistrationService.scss"
// import { makeType, RegistrationServiceType } from 'Models';
// import { modelType } from 'Models/modelType';
// import { orderService, vehicleService } from 'Services'; 
// import Swal from 'sweetalert2';  
// import { useForm } from 'react-hook-form'; 
// import { VehicleClassType } from 'Models/vehicleClassType';
// import { VehicleColorType } from 'Models/vehicleColorType';
// import { useNavigate, useParams } from 'react-router-dom';
// import { vehicleRegistrationService } from 'Services/vehicleRegistrationService';
// import { useAuth } from 'Components/AuthProvider/AuthProvider';
// import Skeleton from 'react-loading-skeleton'
// import { FileUploader } from 'react-drag-drop-files';
// import jpg from 'Assets/Icons/jpg.png';
// import png from 'Assets/Icons/png.png';
// import pdf from 'Assets/Icons/pdf.svg';
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import { VehicleYear } from '@/Models/vehicleYear';
// import { VehicleUse } from '@/Models/vehicleUse';
// import { Lga, State } from '@/Models/location';
// import { CHANGE_OF_OWNERSHIP_SERVICE_ID, NEW_REGISTRATION_SERVICE_ID, RENEW_REGISTRATION_SERVICE_ID } from '../../../../Constants/RegistrationConstant';
// import { OrderUploadedDocument } from '@/Models/orderUploadDocument';

// export type registrationOrderFormType = { 
//         registrationService: string | undefined,
//         vehicleMake: string,
//         vehicleModel: string,
//         vehicleYear: string|number,
//         vehicleType: string,
//         vehicleUse: string,
//         vehicleColor: string,
//         chasisNumber: string,
//         engineNumber: string,
//         requiredCRM: string,
//         nin: string,
//         stateLiencingOffice: string,
//         stateRequestingOffice: string,
//         firstName: string,
//         lastName: string,
//         phoneNumber: string,
//         email: string,
//         state: string,
//         localGovernment: string,
//         address: string,
//         vehicleIdentificationNumber?: string,
//         plateNumber? : string
//         newOwnerFirstName?: string;
//         newOwnerLastName?: string;
//         newOwnerPhoneNumber?: string;
//         newOwnerAddress?: string;
//         newOwnerEmail?: string;
// }

// const RegistrationService = () => {
//    const { service_id } = useParams<{service_id: string}>()
//     const limit = 100;
//     const offset = 0; 
//     const navigate = useNavigate(); 
//     const { user } = useAuth(); 
     
//     const [make, setMake] = useState<Array<makeType>>()
//     const [model, setModel] = useState<Array<modelType>>()
//     const [vehicleClass, setVehicleClass] = useState<Array<VehicleClassType>>()
//     const [vehicleColor, setVehicleColor] = useState<Array<VehicleColorType>>()
//     const [vehicleUse, setVehicleUse] = useState<Array<VehicleUse>>()

//     const [states, setStates] = useState<Array<State>>()
//     const [lgas, setLgas] = useState<Array<Lga>>()
    
//     const [checkColor, setCheckColor] = useState<string>('d-none')

//     const [vehicleYear, setVehicleYear] = useState<VehicleYear[]>() 
//     const [greyButton, setGreyButton] = useState<boolean>(false) 

//     const getMake = async (type_id: string) =>{
//         await vehicleService.getMakeByTypeId(type_id).then((response) =>{
//             console.log("response make", response.data.data)
//             setMake(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }
 
//      const getYear = async () =>{
//          await vehicleService.getVehicleYear( limit, offset).then((response) =>{
//              console.log("response year", response.data.data)
//              setVehicleYear(response.data.data)
//          }, error => {
 
//              console.log("error make", error)
//              Swal.fire({
//                  position: "top-end",
//                  icon: "warning",
//                  text: error.response.data.message,
//                  showConfirmButton: false,
//                  timer: 3000
//              });
//          })
//      }

//      const getStates = async () =>{
//         await vehicleService.getStates().then((response) =>{
//             console.log("states", response.data)
//             setStates(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }

//      const getVehicleUse = async () =>{
//         await vehicleService.getVehicleUse().then((response) =>{
//             console.log("response year", response.data)
//             setVehicleUse(response.data.data)
//         }, error => {

//             console.log("error make", error)
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//         })
//     }
 
//      const getModel = async (make_id:string) =>{
//          console.log(make_id)
//         await vehicleService.getModelsByMakeId(make_id).then((response) =>{
//             console.log("response model", response.data)
//             setModel(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error model", error)
//         })
//     }
 

//     const getVehicleClass = async () =>{
//         await vehicleService.getVehicleClass(limit, offset).then((response) =>{
//             console.log("response getVehicleClass", response.data)
//             setVehicleClass(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error model", error)
//         })
//     }
 

//     const getVehicleColors = async () =>{
//         await vehicleService.getVehicleColor(limit, offset).then((response) =>{
//             console.log("response getVehicleCcolor", response.data)
//             setVehicleColor(response.data.data) 
//         }, error => {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 text: error.response.data.message,
//                 showConfirmButton: false,
//                 timer: 3000
//             });
//             console.log("error getVehicleCcolor", error)
//         })
//     }
 
  

//      useEffect(() => {
//          getYear()  
//          getVehicleClass()
//          getVehicleColors()
//          getVehicleUse()
//          getStates()
//      }, [])
//      const [request, setRequest] = useState(false)
//      const { register, watch, handleSubmit, getValues, formState: { errors } } = useForm<registrationOrderFormType>()

//      useEffect(() => {
//      },[
//         watch('requiredCRM'),
//      ])

//     //  const [orderId, setOrderId] = useState<string>('')
     
//      const onSubmit = async (data: registrationOrderFormType) => {

//         console.log(data)

//         setRequest(true)
//             const newData:registrationOrderFormType = { 
//                 ...data,
//                 state: data.state, 
//                 // stateLiencingOffice:  data.stateLiencingOffice,
//                 // stateRequestingOffice:  data.stateRequestingOffice,
//                 registrationService: service_id, 
//                 vehicleMake: data.vehicleMake,  
//                 vehicleYear: data.vehicleYear,                
//             }

//             setGreyButton(true)

//             console.log(newData)


//             // return

//             await vehicleRegistrationService.orderRegistrationService(newData).then((response)=>{
//                 console.log(response)
//                 const orderId: string = response.data.data.uuid
//                 // setOrderId(orderId)
//                 // 8c6e6f78-5960-4c7c-9bd9-4aa71746b786
//                 setRequest(false)
//                 // 
//                 if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//                     processFilesForUpload(orderId)
//                 }
//                 // 
//                 navigate(user ? `/myaccount/registration/${orderId}/registration-summary`: `/registration/${orderId}/registration-summary`, { replace: true});
            
//                 setGreyButton(false)
//             }, error =>{
//                 console.log("onSubmit error", newData)
//                 setRequest(false)
//                 setGreyButton(false)

//                 Swal.fire({
//                     position: "top-end",
//                     icon: "warning",
//                     text: error.response.data.message,
//                     showConfirmButton: false,
//                     timer: 3000
//                 });
//             })
        
//      };

//      //COO = change of ownership

//     //  const submitCOOOrder = (data:any) => {
//     //     console.log(data)
//     //     processFilesForUpload()
//     //  }

//      const processFilesForUpload = (orderId: string) => {
//         console.log(processedFiles)
//         processedFiles.forEach((processedFile) => {
//             try { 
//                 const storageRef = ref(firebaseStorage, `files/${processedFile.file.name}`); 
//                 const uploadTask = uploadBytesResumable(storageRef, processedFile.file);
     
//                 uploadTask.on("state_changed",
//                     (snapshot) => {
//                         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
//                     },(error) => {
//                         console.log(error)
//                         alert(error);
//                     },() => {
//                         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 

//                         console.log(orderId)

//                         const payload: OrderUploadedDocument = {
//                             documentName: processedFile.name,
//                             documentUrl: downloadURL,
//                             orderUuid: orderId
//                         }

//                         uploadCOODocuments(payload)
    
//                             console.log('File uploaded successfully.', downloadURL);
//                             // saveFile(downloadURL)  
//                         });
//                     }
//                     );
    
//               } catch (error) {
//                 Swal.fire({
//                     position: "top-end",
//                     icon: "warning",
//                     text: "Error occured while uploading file, please try again.",
//                     showConfirmButton: false,
//                     timer: 3000
//                 });
//                 console.error('Error uploading file:', error);
//               }
//         })
//      }

//      const uploadCOODocuments = async (payload: OrderUploadedDocument) => {
//         console.log(payload)
//         await orderService.orderUploadDocument(payload).then((response)=>{
//             console.log(response)
//         }, error =>{
//             console.log(error)
//         })
//      }
  
 
     
//      useEffect(()=>{ 
//          setGreyButton(false)
//        }, [watch])
  
    
       
//     const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

//     const handleState = async (e: any) => {
//         const state_id = e.target.value;  
//         console.log(state_id)
//         await vehicleService.getLGAsByStateId(state_id).then((response)=>{
//             console.log("getService", response)
//             setLgas(response.data.data)
//           }, error=>{
//            console.log("getService error", error)
//            Swal.fire({
//                position: "top-end",
//                icon: "warning",
//                text: error.response.data.message,
//                showConfirmButton: false,
//                timer: 3000
//            });
//           })
//       }
      
//       //check if it is COO service
//     //   const totalStep = service_id==='6757be8a-5611-412f-9919-faef90d83865'? 3 : 2;
//     const totalStep = service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 3 : 2;

//     //   const [step, setStep] = useState(1)
//     const [step, setStep] = useState(3)

//       const addStep = () => { 
//           if(step <= 2){
//               setStep(step + 1);
//           }  
//       }
  
//       const remStep = () => { 
//           if(step >= 1){
//               setStep(step - 1);
//           } 
//       }

//     const [serviceDetail, setServiceDetail] = useState<RegistrationServiceType>()
//     const [serviceRequest, setServiceRequest] = useState<boolean>()

//     const getService = async () =>{
//         setServiceRequest(true)
//        await vehicleService.getServiceItem(service_id).then((response)=>{
//          console.log("getService", response)
//          setServiceDetail(response.data.data)
//          setServiceRequest(false)
//        }, error=>{
//         setServiceRequest(false)
//         console.log("getService error", error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             text: error.response.data.message,
//             showConfirmButton: false,
//             timer: 3000
//         });
//        })
//     }

//     useEffect(()=>{
//         getService()
//     }, [service_id])


//     const [documentCount, setDocumentCount] = useState([0, 1, 2, 3, 4]);
//     const [files, setFile] = useState<File[]>([]);
//     const [processedFiles, setProcessedFiles] = useState<{name: string, file: File}[]>([]);

//     const fileTypes = ["JPG", "PNG"];
    
//     const fileIcon:any = {
//         'image/png': png,
//         'image/jpg': jpg,
//         'image/jpeg': jpg,
//         'image/pdf': pdf
//     }

//     const handleChange = (file: FileList) => {
//         console.log([...Array.from(file), ...files]); 

//         // setFile([...Array.from(file), ...files]);
//         setFile([...files, ...Array.from(file)]);
//     };

//     const removeFile =(indexToDelete: number)=>{ 

//         const updatedFiles = files.filter((_, index) => index !== indexToDelete);
//         const updatedProcFiles = processedFiles.filter((_, index) => index !== indexToDelete);
//         setFile(updatedFiles); 
//         setProcessedFiles(updatedProcFiles)
//     }

//     const processFileName =(data: {name: string, file: File})=>{ 
//         const checkExist = processedFiles.some(fileItem => fileItem.name === data.name);
        
//         if(!checkExist){
//            setProcessedFiles([...processedFiles, data])
//         }
//     }

//     const doesFileNameExist = (fileName: string): boolean => {
//         return processedFiles.some(fileItem => fileItem.name === fileName);
//     };

//     useEffect(() => {
//         if((processedFiles.length < documentCount.length) && totalStep === 3){
//             setGreyButton(true)
//         }
//     }, [processedFiles])

//     const [greyNextOne, setGreyNextOne] = useState(false)

//     useEffect(() => {
//         if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//             const fields: Array<keyof registrationOrderFormType> = ['vehicleIdentificationNumber', 'plateNumber', 'vehicleType', 'vehicleMake', 'vehicleModel'];
//             const allFieldsFilled = fields.every(field => !!watch(field));
        
//             setGreyNextOne(!allFieldsFilled);
//         }
//         else{
//             if(getValues('requiredCRM')){
//                 const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear', 'nin', 'stateLiencingOffice', 'stateRequestingOffice'];
//                 console.log(fields)
//                 const allFieldsFilled = fields.every(field => !!watch(field));
//                 console.log(!allFieldsFilled)
//                 setGreyNextOne(!allFieldsFilled);
//             }
//             else{
//                 const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear'];
//                 console.log(fields)
//                 const allFieldsFilled = fields.every(field => !!watch(field));
//                 console.log(!allFieldsFilled)
            
//                 setGreyNextOne(!allFieldsFilled);
//             }
//         }
//       }, [
//         watch('vehicleIdentificationNumber'),
//         watch('plateNumber'),
//         watch('vehicleMake'),
//         watch('vehicleModel'),
//         watch('vehicleType'),
//         watch('vehicleColor'),
//         watch('vehicleUse'),
//         watch('vehicleYear'),
//         watch('nin'),
//         watch('chasisNumber'),
//         watch('requiredCRM'),
//         watch('stateLiencingOffice'),
//         watch('stateRequestingOffice')
//       ]);

//     // useEffect(() => {
//     //     if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//     //         console.log('okayyyy')
//     //         const fields: Array<keyof registrationOrderFormType> = ['vehicleIdentificationNumber', 'plateNumber', 'vehicleType', 'vehicleMake', 'vehicleModel'];
//     //         const allFieldsFilled = fields.every(field => !!watch(field));
        
//     //         setGreyNextOne(!allFieldsFilled);
//     //     }
//     //     else{
//     //         console.log('hereee')
//     //         const fields: Array<keyof registrationOrderFormType> = ['vehicleMake', 'vehicleModel', 'vehicleType', 'vehicleColor', 'vehicleUse', 'vehicleYear', 'nin'];
//     //         const allFieldsFilled = fields.every(field => !!watch(field));
        
//     //         setGreyNextOne(!allFieldsFilled);
//     //     }
//     //   }, [
//     //     watch('vehicleIdentificationNumber'),
//     //     watch('plateNumber'),
//     //     watch('vehicleMake'),
//     //     watch('vehicleModel'),
//     //     watch('vehicleType'),
//     //     watch('vehicleColor'),
//     //     watch('vehicleUse'),
//     //     watch('vehicleYear'),
//     //     watch('nin')
//     //   ]);



//     const [greyNextTwo, setGreyNextTwo] = useState(false)

//     useEffect(() => {
//         // const fields: Array<keyof registrationOrderFormType> = ['firstName', 'lastName', 'phoneNumber', 'address', 'email', 'localGovernment', 'state'];
//         const fields: Array<keyof registrationOrderFormType> = ['firstName', 'lastName', 'phoneNumber', 'address'];
//         const allFieldsFilled = fields.every(field => !!watch(field));

//         console.log(getValues())
    
//         setGreyNextTwo(!allFieldsFilled);
//       }, [
//         watch('firstName'),
//         watch('lastName'),
//         watch('phoneNumber'),
//         watch('address'),
//         watch('email'),
//         watch('localGovernment'),
//         watch('state')
//       ]);

//       const [serviceType, setServiceType] = useState<string>()

//       useEffect(() => {
//         if(service_id === RENEW_REGISTRATION_SERVICE_ID){
//             setServiceType('renew-registration')
//         }
//         else if(service_id === NEW_REGISTRATION_SERVICE_ID){
//             setServiceType('new-registration')
//         }
//         else if(service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID){
//             setServiceType('change-of-ownership')
//         }
//       },[service_id])


//     return (
//         <div className='registration-service-page'>
//             <div className="container">
//                 <div className="row justify-content-center">
//                     <div className="col-md-9"> 
//                         <div className="top-header py-4 px-0">
//                             {!serviceRequest && <div className="title">{serviceDetail && serviceDetail?.name}</div>}
//                             {serviceRequest && <div className="title"><Skeleton width="50%" height={20}/></div>}
//                             <div className="desc d-none">Fill the form below to register your newly purchased vehicle or foreign used vehicle.</div>
//                         </div>
//                         <div className="card px-3 px-md-4">
                           
//                             <div className="card-body px-0"> 
//                             {step <= 2 && <div className="row pb-0 mb-0">
//                                     <div className="col-md-6 pb-0 mb-0">
//                                         <div className="section-title">
//                                            Vehicle {step === 2 && "Owner"} Information
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="section-pagination">
//                                             {step} of {totalStep}
//                                         </div>
//                                     </div>
//                                 </div>}

//                             {step === 3 && <div className="row pb-0 mb-0">
//                                     <div className="col-md-6 pb-0 mb-0">
//                                         <div className="section-title">
//                                             Upload Documents
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className="section-pagination">
//                                             {step} of {totalStep}
//                                         </div>
//                                     </div>
//                                 </div>}
                               
//                                 <form onSubmit={handleSubmit(onSubmit)} className='VehicleTypeForm'>
//                                     <div className="row"> 
//                                        {step ===1 && <Fragment>

//                                         {totalStep === 3 && <Fragment>
//                                             <div className="col-md-6">  
//                                                 <div className="form-group">
//                                                     <label htmlFor="vehicleIdentificationNumber">Vehicle Identification Number (VIN)</label> 
//                                                     <input type="text" id="vehicleIdentificationNumber" className={`form-control ${errors.vehicleIdentificationNumber ? 'is-invalid' : ''}`} {...register("vehicleIdentificationNumber", { required: "field can not be empty" })}/>
//                                                     {errors.vehicleIdentificationNumber && <small className='error'>{errors.vehicleIdentificationNumber.message}</small>} 
//                                                 </div>  
//                                             </div> 
//                                             <div className="col-md-6">  
//                                                 <div className="form-group">
//                                                     <label htmlFor="licensePlateNumber">License Plate Number</label> 
//                                                     <input type="text" id="licensePlateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
//                                                     {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
//                                                 </div>  
//                                             </div>  
//                                         </Fragment>}

//                                         {serviceType === 'renew-registration' &&
//                                             <div className="col-md-6"> 
//                                                 <div className="form-group">
//                                                     <label htmlFor="nin">Plate Number</label>
//                                                     <input type="text" id="plateNumber" className={`form-control ${errors.plateNumber ? 'is-invalid' : ''}`} {...register("plateNumber", { required: "field can not be empty" })}/>
//                                                     {errors.plateNumber && <small className='error'>{errors.plateNumber.message}</small>} 
//                                                 </div>  
//                                             </div>
//                                         }

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleClass">Vehicle Class</label>
//                                                 <select id="vehicleClass" className={`form-control ${errors.vehicleType ? 'is-invalid' : ''}`} {...register("vehicleType", { required: "field can not be empty", onChange: (e)=>getMake(e.target.value) })}>
//                                                     <option value="">select class</option>
//                                                     {vehicleClass && vehicleClass.map((vehicleCla: VehicleClassType, index:Key)=>{
//                                                        return <option key={index} value={vehicleCla.uuid}>{vehicleCla.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleType && <small className='error'>{errors.vehicleType.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6">  
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleMakeId">Make</label>
//                                                 <select id="vehicleMakeId" className={`form-control ${errors.vehicleMake ? 'is-invalid' : ''}`} {...register("vehicleMake", { required: "field can not be empty", onChange: (e)=>getModel(e.target.value)})}>
//                                                     <option value="">select make</option> 
//                                                     {make && make.map((mk: makeType, index:Key)=>{
//                                                       return <option key={index} value={mk.uuid}>{mk.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleMake && <small className='error'>{errors.vehicleMake.message}</small>} 
//                                             </div>
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleModel">Model</label>
//                                                 <select id="vehicleModel" className={`form-control ${errors.vehicleModel ? 'is-invalid' : ''}`} {...register("vehicleModel", { required: "field can not be empty" })}>
//                                                     <option value="">select model</option> 
//                                                     {model && model.map((mod: modelType, index:Key)=>{
//                                                     return <option key={index} value={mod.id}>{mod.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleModel && <small className='error'>{errors.vehicleModel.message}</small>} 
//                                             </div>
//                                         </div>

//                                          {totalStep !== 3 && <Fragment>
//                                             <div className="col-md-6"> 
//                                                 <div className="form-group">
//                                                     <label htmlFor="year">Vehicle Year</label>
//                                                     <select id="year" className={`form-control ${errors.vehicleYear ? 'is-invalid' : ''}`} {...register("vehicleYear", { required: "field can not be empty" })}>
//                                                         <option value="">select year</option>
//                                                         {vehicleYear && vehicleYear.map((year: VehicleYear, index:Key)=>{
//                                                         return <option key={index} value={year.uuid}>{year.year}</option>
//                                                         })}
//                                                     </select>
//                                                     {errors.vehicleYear && <small className='error'>{errors.vehicleYear.message}</small>} 
//                                                 </div>  
//                                             </div>
                                        

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="vehicleUse">Vehicle Use</label>
//                                                 <select id="vehicleUse" className={`form-control ${errors.vehicleUse ? 'is-invalid' : ''}`}{...register("vehicleUse", { required: "field can not be empty" })}>
//                                                     <option value="">select use</option> 
//                                                     {vehicleUse && vehicleUse.map((vehicleUse: VehicleUse, index:Key)=>{
//                                                        return <option key={index} value={vehicleUse.uuid}>{vehicleUse.name}</option>
//                                                     })}
//                                                 </select>
//                                                 {errors.vehicleUse && <small className='error'>{errors.vehicleUse.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6">  

//                                             <div className="form-group row">
//                                                 <label htmlFor="color">Vehicle Color</label>
//                                                 <div className={ checkColor === 'd-block' ? 'col-6 pe-3' : 'col-12'}>
//                                                     <select {...checkColor === 'd-none' ? {...register("vehicleColor")} : ''} 
//                                                     onChange={(e) => {e.target.value === 'custom' ? setCheckColor('d-block') : setCheckColor('d-none')}}
//                                                     id="color" className={`form-control ${errors.vehicleColor ? 'is-invalid' : ''}`}>
//                                                         <option value="">select color</option> 
//                                                         <option value="custom">Custom Color</option>
//                                                         {vehicleColor && vehicleColor.map((vehicleCol: VehicleColorType, index:Key)=>{
//                                                         return <option key={index} value={vehicleCol.color}>{vehicleCol.color}</option>
//                                                         })}
//                                                     </select>
//                                                     {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
//                                                 </div>

//                                                 <div className={ checkColor === 'd-block' ? `${checkColor} col-6 ps-3` : `${checkColor}`}>
//                                                     {/* <label htmlFor="chasisNumber">Custom Color</label> */}
//                                                     <input type="text" {...checkColor === 'd-block' ? {...register("vehicleColor")} : ''} id="customColor" className='form-control' placeholder='Type color, e.g purple'/> 
//                                                     {errors.vehicleColor && <small className='error'>{errors.vehicleColor.message}</small>} 
//                                                 </div>
//                                             </div> 
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="engineNumber">Engine Number</label>
//                                                 <input type="text" id="engineNumber" className={`form-control ${errors.engineNumber ? 'is-invalid' : ''}`} {...register("engineNumber", { required: "field can not be empty" })}/>
//                                                 {errors.engineNumber && <small className='error'>{errors.engineNumber.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="chasisNumber">Chasis Number</label>
//                                                 <input type="text" id="chasisNumber" className={`form-control ${errors.chasisNumber ? 'is-invalid' : ''}`} {...register("chasisNumber", { required: "field can not be empty" })}/>
//                                                 {errors.chasisNumber && <small className='error'>{errors.chasisNumber.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         </Fragment>}
                                

//                                         <div className="col-md-6"> 
//                                             <div className="form-group d-flex form-crm-input">
//                                                 <input type="checkbox" id="requiredCRM" className='my-auto' {...register("requiredCRM")}/> 
//                                                 <label htmlFor="requiredCRM" className='my-auto'>CMR(Central Motor Registry)</label>
//                                                 {errors.requiredCRM && <small className='error'>{errors.requiredCRM.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         {totalStep !== 3 && <Fragment>


//                                             { getValues('requiredCRM') &&
//                                                 <>
//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="nin">NIN</label>
//                                                             <input type="text" id="nin" className={`form-control ${errors.nin ? 'is-invalid' : ''}`} {...register("nin", { required: "field can not be empty" })}/>
//                                                             {errors.nin && <small className='error'>{errors.nin.message}</small>} 
//                                                         </div>  
//                                                     </div>

//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="stateLiencingOffice">State licensing office</label>
//                                                             <select id="stateLiencingOffice" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('stateLiencingOffice', { required: "This field is required"})}>
//                                                                 <option>select state</option>
//                                                                 {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                             </select>
//                                                             {errors.stateLiencingOffice && <small className='error'>{errors.stateLiencingOffice.message}</small>} 
//                                                         </div>
//                                                     </div>

//                                                     <div className="col-md-6"> 
//                                                         <div className="form-group">
//                                                             <label htmlFor="stateRequestingOffice">State Requesting office</label>
//                                                             <select id="stateRequestingOffice" className={`form-control ${errors.stateRequestingOffice ? 'is-invalid' : ''}`} {...register('stateRequestingOffice', { required: "This field is required"})}>
//                                                                 <option>select state</option>
//                                                                 {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                             </select>
//                                                             {errors.stateRequestingOffice && <small className='error'>{errors.stateRequestingOffice.message}</small>} 
//                                                         </div>
//                                                     </div>
//                                                 </>
//                                             }

//                                         </Fragment>}

                                        
//                                     </Fragment>}


//                                     {step === 2 && <Fragment>
//                                         {totalStep === 3 && <Fragment> 
//                                         <div className="tagline--sub-heading mt-3 fw-bolder text-dark">CURRENT OWNER’S DETAILS</div>
                                             
//                                         </Fragment>}

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="firstName">First Name</label>
//                                                 <input type="text" id="firstName" className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} {...register("firstName", { required: "field can not be empty" })}/>
//                                                 {errors.firstName && <small className='error'>{errors.firstName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lastName">Last Name</label>
//                                                 <input type="text" id="lastName" className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} {...register("lastName", { required: "field can not be empty" })}/>
//                                                 {errors.lastName && <small className='error'>{errors.lastName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                          {totalStep !== 3 &&<div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
//                                                 {errors.email && <small className='error'>{errors.email.message}</small>} 
//                                             </div>  
//                                         </div>}


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="phoneNumber">Phone Number</label>
//                                                 <input type="number" id="phoneNumber" className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} {...register("phoneNumber", { required: "field can not be empty" })}/>
//                                                 {errors.phoneNumber && <small className='error'>{errors.phoneNumber.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="address">Address</label>
//                                                 <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register("address", { required: "field can not be empty" })}/>
//                                                 {errors.address && <small className='error'>{errors.address.message}</small>} 
//                                             </div>  
//                                         </div>
 
//                                         {totalStep !== 3 && <Fragment>
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="state">State</label>
//                                                 <select id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => handleState(e)})}>
//                                                     <option>select state</option>
//                                                     {states?.map((stateItem:State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                                 </select>
//                                                 {errors.state && <small className='error'>{errors.state.message}</small>} 
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lga">L.G.A</label>
//                                                 <select id="lga" className={`form-control ${errors.localGovernment ? 'is-invalid' : ''}`} {...register('localGovernment', { required: "This field is required"})}>
//                                                     <option>select L.G.A</option>
//                                                     {lgas?.map((lgaItem:Lga, index: Key)=><option key={index} value={lgaItem.id}>{lgaItem.name}</option>)}
//                                                 </select>
//                                                 {errors.localGovernment && <small className='error'>{errors.localGovernment.message}</small>} 
//                                             </div>  
//                                         </div> 
//                                         </Fragment>}

//                                         {totalStep === 3 && <Fragment> 
//                                         <div className="tagline--sub-heading mt-3 fw-bolder text-dark mt-4">NEW OWNER’S DETAILS</div>
                                            
//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="firstName">First Name</label>
//                                                 <input type="text" id="firstName" className={`form-control ${errors.newOwnerFirstName ? 'is-invalid' : ''}`} {...register("newOwnerFirstName", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerFirstName && <small className='error'>{errors.newOwnerFirstName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="lastName">Last Name</label>
//                                                 <input type="text" id="lastName" className={`form-control ${errors.newOwnerLastName ? 'is-invalid' : ''}`} {...register("newOwnerLastName", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerLastName && <small className='error'>{errors.newOwnerLastName.message}</small>} 
//                                             </div>  
//                                         </div>

//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: "field can not be empty" })}/>
//                                                 {errors.email && <small className='error'>{errors.email.message}</small>} 
//                                             </div>  
//                                         </div>

//                                          {/* {totalStep !== 3 && <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="email">Email Address</label>
//                                                 <input type="email" id="email" className={`form-control ${errors.newOwnerEmail ? 'is-invalid' : ''}`} {...register("newOwnerEmail", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerEmail && <small className='error'>{errors.newOwnerEmail.message}</small>} 
//                                             </div>  
//                                         </div>} */}


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="phoneNumber">Phone Number</label>
//                                                 <input type="number" id="phoneNumber" className={`form-control ${errors.newOwnerPhoneNumber ? 'is-invalid' : ''}`} {...register("newOwnerPhoneNumber", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerPhoneNumber && <small className='error'>{errors.newOwnerPhoneNumber.message}</small>} 
//                                             </div>  
//                                         </div>


//                                         <div className="col-md-6"> 
//                                             <div className="form-group">
//                                                 <label htmlFor="address">Address</label>
//                                                 <input type="text" id="address" className={`form-control ${errors.newOwnerAddress ? 'is-invalid' : ''}`} {...register("newOwnerAddress", { required: "field can not be empty" })}/>
//                                                 {errors.newOwnerAddress && <small className='error'>{errors.newOwnerAddress.message}</small>} 
//                                             </div>  
//                                         </div>
//                                         </Fragment>
//                                         }
//                                     </Fragment>} 

                                    
//                                     {step === 3 && <Fragment>
//                                         <div className="tagline-upload mt-3">Upload the necessary documents and select the corresponding fields from the dropdown menu. Supported file formats include PDF, JPG, and PNG.</div>
                                           
//                                         <FileUploader handleChange={handleChange} name="file" types={fileTypes}  multiple={true} classes="text-center w-100  mt-3">
//                                             <div className="file-uploader-container">
//                                                 <svg width="20" height="20" className='me-2' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                     <g clip-path="url(#clip0_4956_44565)">
//                                                         <path d="M16.5004 16.5014L12.5004 12.5014M12.5004 12.5014L8.50045 16.5014M12.5004 12.5014V21.5014M20.8904 18.8914C21.8658 18.3597 22.6363 17.5183 23.0803 16.5001C23.5244 15.4818 23.6167 14.3447 23.3427 13.2681C23.0686 12.1916 22.4439 11.237 21.5671 10.5549C20.6903 9.87283 19.6113 9.50218 18.5004 9.50145H17.2404C16.9378 8.33069 16.3736 7.24378 15.5904 6.32244C14.8072 5.4011 13.8253 4.6693 12.7185 4.18206C11.6118 3.69481 10.409 3.46481 9.20057 3.50933C7.99213 3.55385 6.80952 3.87175 5.74163 4.43911C4.67374 5.00648 3.74836 5.80855 3.03507 6.78503C2.32178 7.76151 1.83914 8.88699 1.62343 10.0768C1.40772 11.2667 1.46455 12.49 1.78966 13.6547C2.11477 14.8194 2.69969 15.8953 3.50045 16.8014" stroke="#186AFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                     </g>
//                                                     <defs>
//                                                         <clipPath id="clip0_4956_44565">
//                                                             <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
//                                                         </clipPath>
//                                                     </defs>
//                                                 </svg> 
//                                                 Drop files to attach or Browse file
//                                             </div>
//                                         </FileUploader>

//                                         <div className="document-progress">
//                                             <div className="container">
//                                                 <div className="row d-flex justify-content-between">
//                                                     <div className="col text-center mb-1">
//                                                         <div className={`badge px-3 ${doesFileNameExist('AUTOVIN') && "active"}`}>
//                                                         1 | <span className='ms-1'>Autovin</span> 
//                                                         </div>
//                                                     </div>
//                                                     <div className="col text-center mb-1">
//                                                         <div className={`badge px-3 ${doesFileNameExist('PROOF_OF_OWNERSHIP_CERTIFICATE') && "active"}`}>
//                                                         2 | <span className='ms-1'>Proof of Ownership</span> 
//                                                         </div>
//                                                     </div>
//                                                     <div className="col text-center mb-1">
//                                                         <div className={`badge px-3 ${doesFileNameExist('PICTURE_OF_THE_CHASIS_NUMBER') && "active"}`}>
//                                                         3 | <span className='ms-1'>Picture of the Chassis Number </span> 
//                                                         </div>
//                                                     </div>
//                                                     <div className="col text-center mb-1">
//                                                         <div className={`badge px-3 ${doesFileNameExist('VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER') && "active"}`}>
//                                                         4 | <span className='ms-1' >Means of ID of New Owner </span> 
//                                                         </div>
//                                                     </div>

//                                                     <div className="col text-center mb-1">
//                                                         <div className={`badge px-3 ${doesFileNameExist('VEHICLE_LICENSE') && "active"}`}>
//                                                         5 | <span className='ms-1'>Vehicle License</span> 
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="file-upload-list mt-4">
//                                             {files.length > 0 && documentCount.map((item:number)=>{
//                                                 return <div className="row-custom">
//                                                     <div className="col-11-custom">
//                                                         <div key={item} className="card mb-2" data-aos="fade-up">
//                                                             <div className="card-body  py-1">
//                                                                 <div  className="row"> 
//                                                                     <div className="col-md-8"> 
//                                                                         {files[item]? <div className="row h-100">
//                                                                             <div className="col-1 icon text-center my-auto"> 
//                                                                                 <img src={fileIcon[files[item].type]} alt=""/>
//                                                                             </div>

//                                                                             <div className="col-11 col-sm-10 col-lg-8 name my-auto">
//                                                                                {files[item].name}
//                                                                             </div>  

//                                                                             <div className="col-12 col-lg-3 size my-auto text-lg-end mt-1 mt-md-auto">
//                                                                                {(files[item].size/100000)}MB
//                                                                             </div>

//                                                                         </div>: <Skeleton></Skeleton>}
//                                                                     </div> 

//                                                                     <div className="col-md-4"> 
//                                                                         <select className="form-control mb-3 mb-md-0 mt-1 mt-md-0" onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>processFileName({name: e.target.value, file: files[item]})}>
//                                                                             <option>Select Document</option>
//                                                                             {<Fragment>
//                                                                                 <option value={'AUTOVIN'}>Autovin</option>
//                                                                                 <option value={'PROOF_OF_OWNERSHIP_CERTIFICATE'}>Proof of Ownership Certificate</option>
//                                                                                 <option value={'PICTURE_OF_THE_CHASIS_NUMBER'}>Picture of the Chasis Number</option>
//                                                                                 <option value={'VALID_MEANS_OF_IDENTIFICATION_OF_THE_NEW_OWNER'}>Valid Means of Identification of the new owner</option>
//                                                                                 <option value={'VEHICLE_LICENSE'}>Vehicle License</option> 
//                                                                             </Fragment>}
//                                                                         </select> 
//                                                                     </div> 
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div> 
//                                                     <div className="col-1-custom remove my-auto text-center"> 
//                                                         <span onClick={()=>removeFile(Number(item))}>
//                                                             <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                 <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                 <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                             </svg>  
//                                                         </span>
//                                                     </div>  
//                                                 </div>
                                               
//                                             })}
//                                         {/*files.length > 0 && files.map((fileItem: any, index:Key)=>{ 
//                                             return <div key={index} className="row mb-3" data-aos="fade-up">
//                                                 <div className="col-md-6">
//                                                     <div className="card">
//                                                         <div className="card-body py-2">
//                                                             <div className="row h-100">
//                                                                 <div className="col-1 icon text-center my-auto"> 
//                                                                     <img src={fileIcon[fileItem.type]} alt=""/>
//                                                                 </div>

//                                                                 <div className="col-7 name my-auto">
//                                                                    {fileItem.name}
//                                                                 </div>  

//                                                                 <div className="col-3 size my-auto text-center">
//                                                                    {(fileItem.size/100000)}mb
//                                                                 </div>

//                                                                 <div className="col-1 remove my-auto"> 
//                                                                     <span onClick={()=>removeFile(Number(index))}>
//                                                                         <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                             <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                             <path d="M9.16992 14.8319L14.8299 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                             <path d="M14.8299 14.8319L9.16992 9.17188" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
//                                                                         </svg>  
//                                                                     </span>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="col-md-6">
//                                                     <select className="form-control mt-3 mt-md-0">
//                                                         <option>Select Document</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                            })*/}
//                                         </div>
//                                     </Fragment>} 
//                                 </div> 

//                                     <div className="row h-100"> 
//                                         <div className="col-md-8 my-auto">
//                                         {step === 3 && processedFiles.length > 0 && <div className="circular-progress">
//                                                 <div className='inner my-auto'>
//                                                     <CircularProgressbar value={processedFiles.length} maxValue={0.5} minValue={0.1} text={`${0.5 * 100}%`} />
//                                                 </div>
//                                                 <div className='ms-2 my-auto'>
//                                                   {processedFiles.length} of 5 files uploaded Successfully 
//                                                 </div>
//                                             </div>}
//                                         </div>
//                                         <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 <button className={step >= (totalStep===3? (totalStep - 1): totalStep) ? 'btn btn-primary': "btn btn-primary-outline disabled"} type='button' onClick={remStep}>Prev</button>
//                                             </div>
//                                         </div>
//                                         {step < totalStep && <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 <button className={step <= totalStep ? "btn btn-primary": "btn btn-primary d-none"}  type='button' onClick={addStep} disabled={greyNextOne}>Next</button>
//                                             </div>
//                                         </div>}
//                                         {step === totalStep  && <div className="col-6 col-md-2">  
//                                             <div className="form-group"> 
//                                                 {/* <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={greyButton || greyNextTwo}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> */}
//                                                 {
//                                                     service_id === CHANGE_OF_OWNERSHIP_SERVICE_ID ? 
//                                                     <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={processedFiles.length < 5}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button> :
//                                                     <button className={step < totalStep ? "btn btn-primary d-none": "btn btn-primary"} type='submit' disabled={greyNextTwo}>Submit {request && <span className='spinner-border spinner-border-sm'></span>}</button>
//                                                 }
//                                             </div>
//                                         </div>}
//                                     </div>
//                                 </form>  
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default RegistrationService
