import { Link } from 'react-router-dom';
import './RegFAQ.scss';

const RegFAQ = () => {
    return (
        <div className="RegFAQ">
            <div className="faq pb-4">
                <div className="container">
                    <div className="card border-0 pb-4 pt-5">
                        <div className="card-header mb-2 text-center">
                             Frequently Asked Questions
                        </div>
                        <div className="card-header2 mb-4 mx-auto text-center">
                            We’ve provided answers to some questions
                        </div>
                        <div className="card-body">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingOne">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How long does it take for me to register my vehicle and my documents delivered
                                    </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        ANS: Once you register your vehicle with our website, processing of your vehicle documents starts immediately and soft copies are delivered to your email in less than a week after registration, while hard copies documents are delivered to you in the range of 1 to 2 weeks after registrations.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header" id="headingTwo border-0 shadow-0">
                                    <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        How much does it cost to register my vehicle?
                                    </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingThree">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        How can i pay for vehicle registration?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="headingFour">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        What is the difference between Renew Registration and change of ownership?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading5">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        What documents do I need to register my vehicle?
                                        </button>
                                    </h2>
                                    <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                    </div>
                                </div>
                                
                                <div className="accordion-item mb-3 shadow-0" data-aos="fade-up">
                                    <h2 className="accordion-header border-0 shadow-0" id="heading6">
                                        <button className="accordion-button collapsed border-0 shadow-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        Can someone else register my vehicle on my behalf?
                                        </button>
                                    </h2>
                                    <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 text-center d-flex justify-content-center mt-5">
                                <Link to="/frequently-asked-questions">
                                    <button className='btn btn-faq'>
                                        See all FAQS
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegFAQ;