import React, { FC, Key, useEffect, useState } from 'react' 
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServiceDeliveryType, ServiceOrderDataType } from 'Models';
import { deliveryService, orderService, userService, vehicleService } from 'Services';
import Swal from 'sweetalert2';
import { vehicleServiceDocument } from 'Models/vehicleServiceType';
import "./RegistrationSummaryItem.scss"
import { CurrencyFormatter } from 'Helpers';
import { useForm, useFormState, Controller } from 'react-hook-form';

import { VehicleDocument } from '@/Models/vehicleDocument';
import { Lga, State } from '@/Models/location';
import { CHANGE_OF_OWNERSHIP_SERVICE_ID, LAGOS_STATE_ID, NEW_REGISTRATION_SERVICE_ID, RENEW_REGISTRATION_SERVICE_ID } from '../../../../../Constants/RegistrationConstant';
import { ReplaceCharacter } from '../../../../../Helpers/ReplaceCharacter';
import CustomToastr from '../../../../../Components/CustomToastr/CustomToastr';
import { ToastContainer, toast } from "react-toastify";
import PhoneNumberInput from '../../../../../Components/PhoneNumberInput/PhoneNumberInput';


interface propsType {
    module?: string | undefined,
    refreshServiceOrder?: ()=>void
    vehicleOrderServices?: ServiceOrderDataType | undefined
} 

interface vehicleOrderServiceDocumentType {
    id: string;
    time: string;
    name: string;
    vehicleClassName: string;
    subClassName: string;
    vehicleClassSize: string;
    orderType: string;
    costPrice: string;
    sellingPrice: string;
    isRequired: boolean;
}

type formType = {
    address: string,
    localGovernmentUuid: string,
    state: string,
    contact_phone: string,
    primary: boolean,
    contact_name: string,
  }

const RegistrationSummaryItem:FC<propsType> = ({module, vehicleOrderServices, refreshServiceOrder}) => {

    console.log(vehicleOrderServices)

   const navigate = useNavigate();

   const limit = 100;
   const offset = 0;
   
   const { service_order_id } = useParams<{ service_order_id: string }>()
    
    const { control, register, watch, handleSubmit, trigger, formState: { errors, isValid }, setValue, getValues } = useForm<formType>();

    const [selectState, setSelectedState] = useState<string>("")

    const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

    const [requestAddr, setRequestAddr] = useState(false)
    const [newAddress, setNewAddress] = useState(false)


    const [cssPhNumber, setCssPhNum]  = useState<string>('')
    const [phoneNumbers, setPhoneNumbers] = useState<string>('')

    const [newVehicleRegistrationDocuments, setNewVehicleRegistrationDocuments] = useState<VehicleDocument[]>()

    const [states, setStates] = useState<Array<State>>()
    const [lgas, setLgas] = useState<Array<Lga>>()
    const [processingDeliveryDetails, setProcessingDeliveryDetails] = useState<boolean>(false)

    const [showDeliveryDetailsForm, setShowDeliveryDetailsForm] = useState<boolean>(false)

    const [showVehicleOwnerInfoPanel, setShowVehicleOwnerInfoPanel] = useState<boolean>(true)
    const [showDeliveryDetailsPanel, setShowDeliveryDetailsPanel] = useState<boolean>(true)

    const toggleVehicleOwnerInfoPanel = () => {
        setShowVehicleOwnerInfoPanel(!showVehicleOwnerInfoPanel)
    }


    const toggleDeliveryDetailsPanel = () => {
        setShowDeliveryDetailsPanel(!showDeliveryDetailsPanel)
    }

    const handleState = async (e: any) => {
        const state_id = e?.target?.value || e;  
        await vehicleService.getLGAsByStateId(state_id).then((response)=>{
            console.log("getService", response)
            setLgas(response.data.data)
          }, error=>{
           console.log("getService error", error)
           Swal.fire({
               position: "top-end",
               icon: "warning",
               text: error.response.data.message,
               showConfirmButton: false,
               timer: 3000
           });
          })
    }

    const submit = async (data: formType) =>{
        setRequestAddr(true)

        const newData:any = [
            { 
                ...data,
            state: selectState,  
            primary: true, 
            }
        ]

        await userService.createDeliveryAddress(newData).then((response)=>{ 
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Saved",
                text: "Delivery address addeed successfully",
                showConfirmButton: false,
                timer: 1600
            });

            setRequestAddr(false)
            setNewAddress(!newAddress) 
            
        }, error =>{ 
            setRequestAddr(false)
            console.log("error address", error.response) 

            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Error",
                text: error?.response?.data?.error,
                showConfirmButton: true
            });
        }) 
    }

  const validatePhoneNumber = (value: string) => {
      return true
    // Regular expression to validate phone number with country code
    const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
    return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
  };
  
  const handleChange = () => {  
    trigger('contact_phone'); 
  }; 

  const checkPhNo = (phNumber: string) => {
    
    if(phNumber.length === 14 && phNumber.substring(0, 4) === '+234') {
        setPhoneNumbers(phNumber)
        setCssPhNum('')
    } else if (phNumber.length === 11 && phNumber.substring(0, 1) === '0') {
        setCssPhNum('');
        setPhoneNumbers(`+234${phNumber.slice(1)}`)
    } else if(phNumber.length === 0 && !phNumber) {
        setCssPhNum('')
    }
     else {
        setCssPhNum('Invalid')
    }
  }

  const [serviceDeliveryType, setServiceDeliveryType] = useState<ServiceDeliveryType[]>()

  const getServiceTypes = async () => {
    await deliveryService.getServiceTypePricesByServiceId(`${vehicleOrderServices?.registrationService.uuid}`).then((response)=>{
        console.log("getServiceType data", response.data.data)
        setServiceDeliveryType(response.data.data)
    }, error => {
        console.log("error getServiceType", error.response) 

        Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error,
            showConfirmButton: true
          });
    })
  }

  const getStates = async () =>{
    await vehicleService.getStates().then((response) =>{
        console.log("states", response.data)
        setStates(response.data.data)
    }, error => {

        console.log("error make", error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    })
}

  useEffect(()=>{
    getStates()
  }, [])

  useEffect(() => {
    if(vehicleOrderServices?.registrationService.uuid){
        getServiceTypes()
    }
  }, [vehicleOrderServices?.registrationService.uuid])

  useEffect(() => {
    let lagos = states?.find((state: State) => state.name.toLowerCase() === 'lagos')
    setValue('state', lagos ? lagos.uuid : LAGOS_STATE_ID)
    handleState(lagos ? lagos.uuid : LAGOS_STATE_ID)
  }, [states])

  useEffect(() => {
    setNewVehicleRegistrationDocuments(vehicleOrderServices?.registrationService.documents)
  }, [vehicleOrderServices?.registrationService.documents])

  const [selectServiceDeliveryType, setSelectServiceDeliveryType] = useState<ServiceDeliveryType>()

  const handleServiceDeliveryType = async (e:React.ChangeEvent<HTMLInputElement>) => {
     console.log("handleServiceDeliveryType", e.target.value)
     const serviceType:ServiceDeliveryType = JSON.parse(e.target.value);
     console.log(serviceType)
     processServiceDeliveryType(serviceType)
  }

  const [documentCheckedState, setDocumentCheckedState] = useState([]);

  const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => { 
    console.log(selecttype)
    await deliveryService.updateVehicleTypePriceOrder({
       amount: +selecttype.price,
       vehicleTypePriceId: selecttype.uuid,
       orderUuid: `${service_order_id}`
    }).then((response)=>{   
        console.log(response)
        refreshServiceOrder && refreshServiceOrder()
    }, error => {
        console.log("error getServiceType", error.response) 

        Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error,
            showConfirmButton: true
          });
    })
  }


const [deliveryDetails, setDeliveryDetails] = useState<any>({
    amount: vehicleOrderServices?.totalAmount,
    orderId: service_order_id,
    localGovernmentUuid: null,
    address: null,
    contact_name: null,
    contact_phone: null
})

  const handleDeliveryForm = (e:React.ChangeEvent<HTMLInputElement>) => {
      console.log(e)
    setDeliveryDetails(
        {
            ...deliveryDetails,
            [e.target.name]: e.target.value,
            amount: vehicleOrderServices?.totalAmount,
            contact_phone: getValues('contact_phone')
        }
    )
  }

  const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked)
    setShowDeliveryDetailsForm(!showDeliveryDetailsForm)
    console.log(showDeliveryDetailsForm)
  }

//   const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
//         console.log(e.target.checked)
//       setShowDeliveryDetailsForm(e.target.checked)
//       console.log(showDeliveryDetailsForm)
//   }

  const processDeliveryInformation = async () => {
    //   console.log(deliveryDetails)
      const payload = {...deliveryDetails, contact_phone: getValues('contact_phone')}
      console.log(payload)
    //   return
      setProcessingDeliveryDetails(true)
      await deliveryService.saveDeliveryDetails(payload).then((response)=>{   
            console.log(response)
            refreshServiceOrder && refreshServiceOrder()
            setProcessingDeliveryDetails(false)
            setShowDeliveryDetailsForm(false)
            toast(<CustomToastr message={`Delivery details added successfully`}/>);
        }, error => {
            setProcessingDeliveryDetails(false)
            console.log("error getServiceType", error.response) 

            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Error",
                text: error?.response?.data?.error,
                showConfirmButton: true
                });
        })
  }

    const [deliveryDetailsFormIsValid, setDeliveryDetailsFormIsValid] = useState<boolean>(false)
    useEffect(() => {
        const fields: Array<keyof formType> = ['address', 'localGovernmentUuid', 'state', 'contact_phone', 'contact_name'];
        const allFieldsFilled = fields.every(field => !!watch(field));

        setDeliveryDetailsFormIsValid(allFieldsFilled)
    
      }, [
        watch('address'),
        watch('localGovernmentUuid'),
        watch('state'),
        watch('contact_phone'),
        watch('contact_name')
      ]);



 const [requestPayment, setRequestPayment] = useState(false)

 const makePayment = async () => {
    const payload = {
        orderId: `${service_order_id}`,
        totalOrderAmount: Number(vehicleOrderServices?.totalAmount)
    }
    setRequestPayment(true)
    console.log(payload)
    await orderService.initiatePayment(payload).then((response)=>{
        console.log(response)
         setRequestPayment(false)
         console.log("Payment", response.data.data)

         window.location.href = response.data.data.data
    }, error =>{
        console.log(error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error?.message,
            showConfirmButton: true
        });
        setRequestPayment(false)
    })
 }

 const [cmrChecked, setCmrChecked] = useState(false)

 const [temporaryPngImageUrl, setTemporaryImageUrl] = useState<string>("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBtZI9TFNRFMf/9/W9tGiV1toXEUpKYh1ECVI/hiZ+btqlDsjgUE0cjTp108iIjo5GTHRQFxcjEzExYdBETdS41KSmGgol5ZtQQsvjnkMoH72F11L+ycv9OPf97v+ec8S9gc5g0Wn0wxIXsHP9LRatmFYwHJ11ApKCDod2VxNC86DO0rALUkKPeLr4O+BqQoO+j+c0NruPcpzmJ/znOW4berujDyFvlxwf42zTFdw49gAd/nO8T6DEmZdyPMT7KumqzfnCLP7PJiUwyuvkxFfpNsxzuuxz5j1+jn3CRH4Etp026G60uEN49buXLxjPZ3i9XgS/FrpvH0qggdQz/Jn8VtrLSTBd9kM6JNch+dFaJXFn8HRcDv2oUlSs+cKMCvmi5pZSA1eko0od338Rl82byBfnMJR7i1/TH8vOKJ02GiYivm6YzrayWORgN5zaXj5DcFtOW/e0I3Y4wT9GfNfZzVDuTSnu0taKQ25V2uA07I2ip6WXgSVn0vF6R4PZ55gqZLGwtPL8LZ1e8t/CKe9V5aGwJ4qAqx3vhvs4h6o8bnDqMwLsrhJwVaarDT2BR2jUTWwnkU6n40LY71N69ofMUyTnvlRCVt+nlO9Yc4KLWEk1N//mAtYFSqICxluflOVZpFIpj2EY3y3LCqJGTS1m8frfQ9lqo5OLmuPkMjsomVugXiEtAAAAAElFTkSuQmCC")

const checkDocument = (document:VehicleDocument) => {
    if(document.documentName.toLowerCase() === 'cmr' && vehicleOrderServices?.requiredCRM){
       return cmrChecked
    }
}

const [isDocumentSelectionValid, setIsDocumentSelectionValid] = useState<boolean>(false)

const [selectedDocuments, setSelectedDocuments] = useState<any[]>([])

const handleSelectDocument = async (index: number, e:any) => {
    const payload = {
        amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
        registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
        order: vehicleOrderServices?.uuid
    }
    addDocumentToOrder(payload)
    checkIfDocumentSelectionIsValid(e.target.value, e.target.checked)
 }

 const checkIfDocumentSelectionIsValid = (data: any, status: boolean) => {
    if(status){
        let docs = selectedDocuments
        docs.push(data)
        setSelectedDocuments(docs)
    }
    else{
        let docs = selectedDocuments
        let updatedDocs = docs.filter(x => x != data)
        setSelectedDocuments(updatedDocs)
    }
 }

 const addDocumentToOrder = async (payload: any) => {
    console.log(payload)
    await orderService.addDocumentToAnOrder(payload).then((response)=>{
        console.log(response)
         console.log("Add document to order", response.data)
         refreshServiceOrder && refreshServiceOrder()
    }, error =>{
        console.log(error)
        Swal.fire({
            position: "top-end",
            icon: "warning",
            title: "Error",
            text: error?.response?.data?.error?.message,
            showConfirmButton: true
        });
    })
 }

 const goToVehicleInformation = () => {
    navigate({
        pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
        search: `?step=1&orderId=${vehicleOrderServices?.uuid}`,
    });
 }

 const goToVehicleOwnerInformation = () => {
    navigate({
        pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
        search: `?step=2&orderId=${vehicleOrderServices?.uuid}`,
    });
 }

 useEffect(() => {
    setCmrChecked(vehicleOrderServices ? vehicleOrderServices?.requiredCRM : false)
 }, [vehicleOrderServices?.requiredCRM])


    return ( 
        <div className="RegistrationSummaryItem row justify-content-center pt-3 pb-5">
            <div className="col-12 col-md-6 col-lg-6 col-xl-5">
                <div className="card border-0 mt-3 mt-md-0 mb-4 px-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="card-header bg-white pt-3 px-0">
                            Vehicle Information
                        </div>
                        <p className="edit-text" onClick={goToVehicleInformation}>Edit</p>
                    </div>
                    <div className="card-body pt-0 px-0">
                        <div className="row border-top pb-2 pt-3">
                            {
                                vehicleOrderServices?.plateNumber &&
                                <>
                                    <div className="col-6">
                                        <div className="label">
                                            Plate Number
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                            {vehicleOrderServices?.plateNumber }
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="col-6">
                                <div className="label">
                                    Make 
                                </div> 
                            </div>
                            <div className="col-6 text-end">
                                <div className="value">
                                    {vehicleOrderServices?.vehicleMake?.name }
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="label">
                                    Model
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value"> 
                                    {vehicleOrderServices?.vehicleModel?.name}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Year
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value">
                                   {vehicleOrderServices?.vehicleYear.year}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Color
                                </div> 
                            </div> 
                            <div className="col-6 text-end">
                                <div className="value text-capitalize">
                                {vehicleOrderServices?.vehicleColor}
                                </div>
                            </div>


                            {vehicleOrderServices?.engineNumber &&
                                <>
                                <div className="col-6">
                                    <div className="label">
                                        Engine number
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.engineNumber}
                                    </div>
                                </div>
                                </>
                            }

                            {vehicleOrderServices?.chasisNumber &&
                                <>
                                <div className="col-6">
                                    <div className="label">
                                        Chasis number
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.chasisNumber}
                                    </div>
                                </div>
                                </>
                            }


                            <div className="col-6">
                                <div className="label">
                                    Vehicle Class
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                  {vehicleOrderServices?.vehicleType?.name}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                    Vehicle Use
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                  {vehicleOrderServices?.vehicleUse?.name}
                                </div>
                            </div>


                            <div className="col-6">
                                <div className="label">
                                   CMR(Central Motor Registry)
                                </div> 
                            </div> 

                            <div className="col-6 text-end">
                                <div className="value">
                                  {vehicleOrderServices?.requiredCRM ? 'Yes' : 'No'}
                                </div>
                            </div>

                            {vehicleOrderServices?.nin &&
                                <>
                                <div className="col-6">
                                    <div className="label">
                                    NIN
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                        {vehicleOrderServices?.nin}
                                    </div>
                                </div>
                                </>
                            }
                            
                            {vehicleOrderServices?.stateLiencingOffice &&
                                <>
                                <div className="col-6">
                                    <div className="label">
                                    State licensing office
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                        {vehicleOrderServices?.stateLiencingOffice?.name}
                                    </div>
                                </div>
                                </>
                            }

                            {vehicleOrderServices?.stateRequestingOffice &&
                                <>
                                <div className="col-6">
                                    <div className="label">
                                    State Requesting office
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                        {vehicleOrderServices?.stateRequestingOffice?.name}
                                    </div>
                                </div>
                                </>
                            }


                        </div>
                    </div>
                </div>

                <div className="card border-0 mt-3 mt-md-0 px-4">
                    <div className="d-flex justify-content-between align-items-center border-bottom">
                        <div className="card-header bg-white pt-3 px-0">
                            Vehicle Owner's Information
                        </div>
                        <div className="edit-text-container">
                            <p className="edit-text mr-4" onClick={goToVehicleOwnerInformation}>Edit</p>
                            {showVehicleOwnerInfoPanel ?
                                <svg onClick={toggleVehicleOwnerInfoPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9219 22C17.4219 22 21.9219 17.5 21.9219 12C21.9219 6.5 17.4219 2 11.9219 2C6.42188 2 1.92188 6.5 1.92188 12C1.92187 17.5 6.42188 22 11.9219 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.92188 12H15.9219" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg> :
                                <svg className="cursor-pointer" onClick={toggleVehicleOwnerInfoPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 12H16" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 16V8" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            }
                        </div>
                    </div>

                    {showVehicleOwnerInfoPanel &&
                    <div className="card-body pt-0 px-0">

                        {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID ?

                            <>
                                <div className="row border-bottom pb-2 mt-2 px-0">
                                    <p className="mb-0 text-14px">REGISTERED OWNER</p>
                                    <div className="col-6">
                                        <div className="label">
                                        First Name
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.firstName}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                        Last Name
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.lastName}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Address
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.address}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Phone number
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.phoneNumber}
                                        </div>
                                    </div>
                                </div>
                                <div className="row border-bottom pb-2 mt-2 px-0">
                                    <p className="mb-0 text-14px">NEW OWNER</p>
                                    <div className="col-6">
                                        <div className="label">
                                        First Name
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.newOwnerFirstName}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                        Last Name
                                        </div> 
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.newOwnerLastName}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Address
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.newOwnerAddress}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Phone number
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.newOwnerPhoneNumber}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="label">
                                            Email
                                        </div> 
                                    </div> 

                                    <div className="col-6 text-end">
                                        <div className="value">
                                        {vehicleOrderServices?.newOwnerEmail}
                                        </div>
                                    </div>
                                </div>  
                            </>
                            
                            :

                            <div className="row border-bottom pb-2 mt-2 px-0">
                                <div className="col-6">
                                    <div className="label">
                                    First Name
                                    </div> 
                                </div>
                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.firstName}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="label">
                                    Last Name
                                    </div> 
                                </div>
                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.lastName}
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="label">
                                        Email
                                    </div> 
                                </div> 
                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.email}
                                    </div>
                                </div> 

                                <div className="col-6">
                                    <div className="label">
                                        Phone number
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.phoneNumber}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="label">
                                        Address
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.address}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="label">
                                        State
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.state.name}
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="label">
                                        LGA
                                    </div> 
                                </div> 

                                <div className="col-6 text-end">
                                    <div className="value">
                                    {vehicleOrderServices?.localGovernment?.name}
                                    </div>
                                </div>
                            </div>
                        }
                          
                        


                        {/* <div className="row  pb-2 mt-2">
                            <div className="col-12"> 
                                <div className="form-group d-flex form-crm-input">
                                    <input type="checkbox" id="requiredCRM" className='my-auto me-2' checked={!showDeliveryDetailsForm}  onChange={handleUseDeliveryDetails}/> 
                                    <label htmlFor="requiredCRM" className='my-auto'>Use same information for delivery (Where documents are sent to)</label>
                                </div>  
                            </div> 
                        </div>   */}


                    </div>
                    }
                </div>

            {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID  &&
                <div className="col-12 mt-3 mt-md-0">
                    <div className="card border-0 mt-4 px-4">
                        <div className="card-header bg-white pt-3 border-bottom px-0">
                            Documents
                        </div>
                        <div className="card-body pt-0 px-0 mt-4">
                            {vehicleOrderServices.uploadedDocuments.map(document => {
                                return (
                                    <div className="d-flex justify-content-between document-container">
                                        <div className="d-flex">
                                            <img src={temporaryPngImageUrl} alt="" height="20px"/>
                                            <p className="doc-name">{ReplaceCharacter(document.documentName, "_", " ")}</p>
                                        </div>
                                        <p className="doc-size">1MB</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }

            </div>  
 
            <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">

                    <div className="col-12 mb-4">
                        <div className="card px-4 py-3 border-0 delivery-details">
                            <div className="card-header profile-title px-0 border-bottom pb-3">
                                Delivery Information
                            </div>

                            {vehicleOrderServices?.localGovernmentOrders.length === 0 &&
                                <div className="row  pb-2 mt-2">
                                    <div className="col-12"> 
                                        <div className="form-group d-flex form-crm-input">
                                            <input type="checkbox" id="requiredCRM" className='my-auto me-2' checked={!showDeliveryDetailsForm}  onChange={handleUseDeliveryDetails}/> 
                                            <label htmlFor="requiredCRM" className='my-auto'>Use Vehicle Owner's Information for documents delivery</label>
                                        </div>  
                                    </div> 
                                </div>
                            }

                            {showDeliveryDetailsForm &&
                                <>
                                <div className="card-body pt-0 px-0">   
                                    <div className='form-group mt-3'>
                                        <label htmlFor="address">Address</label>
                                        <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address', { required: "This field is required", onChange: handleDeliveryForm})}/>
                                        {errors.address && <small className='error'>{errors.address.message}</small>} 
                                    </div>
                                    <div className='form-group mt-3' title="Service available within Lagos. Other locations coming soon.">
                                        <label htmlFor="state">State</label>
                                        <select disabled id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => {handleState(e); handleDeliveryForm(e)}})}>
                                            <option>select state</option>
                                            {states?.map((stateItem: State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
                                        </select>
                                        {errors.state && <small className='error'>{errors.state.message}</small>} 
                                    </div>
                                    <div className='form-group mt-3'>
                                        <label htmlFor="lga">L.G.A</label>
                                        <select id="lga" className={`form-control ${errors.localGovernmentUuid ? 'is-invalid' : ''}`} {...register('localGovernmentUuid', { required: "This field is required", onChange: handleDeliveryForm})}>
                                            <option>select L.G.A</option>
                                            {lgas?.map((lga:Lga, index: Key)=><option key={index} value={lga.id}>{lga.name}</option>)}
                                        </select>
                                        {errors.localGovernmentUuid && <small className='error'>{errors.localGovernmentUuid.message}</small>} 
                                    </div>  
                                    <div className='form-group mt-3'>
                                        <label htmlFor="contactName">Contact Name</label>
                                        <input type="text" id="contactName" className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`} 
                                        {...register("contact_name", { required: "This field is required", onChange: handleDeliveryForm})} />
                                        {errors.contact_name && <small className='error'>{errors.contact_name.message}</small>} 
                                    </div>

                                    <div className='form-group mt-3'>
                                        <label htmlFor="contact_phone">Phone Number</label>
                                        <Controller
                                            name="contact_phone"
                                            control={control}
                                            rules={{ required: "Phone number is required" }}
                                            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                            <>
                                                <PhoneNumberInput
                                                    value={value}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    className={`form-control ${error ? "is-invalid" : ""}`}
                                                />
                                                {error && <div className="invalid-feedback">{error.message}</div>}
                                            </>
                                            )}
                                        />
                                    </div>
                            
                                </div>
                                <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button>
                                </>
                            }   


                            {(vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0) &&
                                <div className="card-body pt-0 px-0">
                                        <div className="row pb-2 pt-3">
                                            <div className="col-6">
                                                <div className="label">
                                                Address
                                                </div> 
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="value">
                                                {vehicleOrderServices?.localGovernmentOrders[0]?.address}
                                                </div>
                                            </div>
                                        
                                            <div className="col-6">
                                                <div className="label">
                                                State
                                                </div> 
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="value">
                                                
                                                </div>
                                            </div>
                                        
                                            <div className="col-6">
                                                <div className="label">
                                                L.G.A
                                                </div> 
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="value">

                                                </div>
                                            </div>
                                        
                                            <div className="col-6">
                                                <div className="label">
                                                Contact Name
                                                </div> 
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="value">
                                                {vehicleOrderServices?.localGovernmentOrders[0]?.contact_name}
                                                </div>
                                            </div>
                                        
                                            <div className="col-6">
                                                <div className="label">
                                                Contact Number
                                                </div> 
                                            </div>
                                            <div className="col-6 text-end">
                                                <div className="value">
                                                {vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone}
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            }





                        </div>
                    </div>

                {/* {(vehicleOrderServices?.localGovernmentOrders && vehicleOrderServices?.localGovernmentOrders.length > 0) &&
                    <div className="col-12 mb-4">
                        <div className="card border-0 px-4">
                            <div className="card-header bg-white pt-3 border-bottom px-0 d-flex justify-content-between">
                                Delivery Information
                                {
                                    showDeliveryDetailsPanel ? 
                                    <svg className="cursor-pointer" onClick={toggleDeliveryDetailsPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9219 22C17.4219 22 21.9219 17.5 21.9219 12C21.9219 6.5 17.4219 2 11.9219 2C6.42188 2 1.92188 6.5 1.92188 12C1.92187 17.5 6.42188 22 11.9219 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.92188 12H15.9219" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg> :
                                    <svg className="cursor-pointer" onClick={toggleDeliveryDetailsPanel} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 12H16" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M12 16V8" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                }


                            </div>
                            {showDeliveryDetailsPanel &&
                            <div className="card-body pt-0 px-0">
                                    <div className="row border-top pb-2 pt-3">
                                        <div className="col-6">
                                            <div className="label">
                                            Address
                                            </div> 
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="value">
                                            {vehicleOrderServices?.localGovernmentOrders[0]?.address}
                                            </div>
                                        </div>
                                    
                                        <div className="col-6">
                                            <div className="label">
                                            State
                                            </div> 
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="value">
                                            
                                            </div>
                                        </div>
                                    
                                        <div className="col-6">
                                            <div className="label">
                                            L.G.A
                                            </div> 
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="value">

                                            </div>
                                        </div>
                                    
                                        <div className="col-6">
                                            <div className="label">
                                            Contact Name
                                            </div> 
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="value">
                                            {vehicleOrderServices?.localGovernmentOrders[0]?.contact_name}
                                            </div>
                                        </div>
                                    
                                        <div className="col-6">
                                            <div className="label">
                                            Contact Number
                                            </div> 
                                        </div>
                                        <div className="col-6 text-end">
                                            <div className="value">
                                            {vehicleOrderServices?.localGovernmentOrders[0]?.contact_phone}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            } 
                        </div>
                    </div>
                } */}
                 
                <div className="card document-details-card p-0 border-0">

                    <div className="card-header px-0 pt-3 mx-4">
                        <div className="row h-100">
                            <div className="col-md-12 text-center my-auto d-inline-flex">
                            Summary
                            </div>
                        </div>
                    </div>

                    <div className="card-body pt-2 px-0"> 
                        <div className="container-fluid">
                            <div className="row px-4">
                            
                                <div className="col-12 ps-0"> 
                                    <div className="document_to_get">Documents you will get</div>
                                </div>

                                {
                                    (vehicleOrderServices?.registrationService.uuid === NEW_REGISTRATION_SERVICE_ID || vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID) &&
                                    (
                                        <>
                                            {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
                                                return (
                                                    (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
                                                    <div key={index} className="col-md-6">
                                                        <div className="row label-inner">
                                                            <p>{doc.documentName}</p>              
                                                        </div>     
                                                    </div>     
                                                )
                                                
                                            })}
                                        </>
                                    )
                                }

                                {
                                    (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
                                    (
                                        <>
                                            {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
                                                return (
                                                    (doc.documentName.toLowerCase() === 'cmr' && !vehicleOrderServices.requiredCRM ) ? '' :
                                                    <div key={index} className="col-md-6">
                                                        <div className="row label-inner">
                                                               <div className="col-1 px-0">
                                                                   <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className="active" value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
                                                               </div>
               
                                                               <div className="col-11">
                                                                   <label htmlFor={doc.documentName}>{doc.documentName}</label>
                                                               </div>  
                                                           </div>     
                                                    </div>  
                                                )
                                                
                                            })}
                                        </>
                                    )
                                }

                            </div>
                        </div>

                        <div className="service-type px-3 py-3 mt-3">

                            <div className="container-fluid h-100">
                                <div className="row h-100">
                                    <div className="col-12 my-auto">
                                       <div className="title">Service type</div> 
                                       {serviceDeliveryType && serviceDeliveryType.map((serviceType:ServiceDeliveryType, index:Key)=>{ 
                                        return <div className="row h-100 mt-2" key={index}>
                                            <div className="col-8 type my-auto d-flex h-100">
                                               <input defaultChecked={serviceType.default} type="radio" name="serice_type" id={`standard_delivery_${serviceType.uuid}`} value={JSON.stringify(serviceType)} onChange={handleServiceDeliveryType}/> <label htmlFor={`standard_delivery_${serviceType.uuid}`} className='ms-2 my-auto'> {serviceType.service_name} - {serviceType.registrationService.processingTime}  days </label>
                                            </div>
                                            <div className="col-4 amount h-100 text-end">
                                               ₦{CurrencyFormatter(Number(serviceType.price))}
                                            </div>
                                        </div>
                                         })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="processing_time px-4">
                           <div className="row h-100">
                            <div className="col-6 title my-auto">Processing Time</div>
                            <div className="col-6 time my-auto">{vehicleOrderServices?.registrationService.processingTime} days</div>
                           </div>
                        </div>

                        <div className="total-balance px-4 border-0"> 
                            <div className="row h-100 pt-4">    
                                <div className="col-6 my-auto">
                                    Total
                                </div>
                                <div className="col-6 text-end my-auto">
                                   ₦{CurrencyFormatter(Number(vehicleOrderServices?.totalAmount))}
                                </div> 
                            </div> 
                        </div>
                    </div>
                </div> 

                {
                    (vehicleOrderServices?.registrationService.uuid === NEW_REGISTRATION_SERVICE_ID || vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID) &&
                    <button className="btn checkout-btn" disabled={showDeliveryDetailsForm && !deliveryDetailsFormIsValid} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
                }

                {
                    (vehicleOrderServices?.registrationService.uuid === RENEW_REGISTRATION_SERVICE_ID) &&
                    <button className="btn checkout-btn" disabled={(showDeliveryDetailsForm && !deliveryDetailsFormIsValid) || selectedDocuments.length < 1} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
                }
             
            </div>  




            <div className="col-12 col-md-6 col-lg-6 col-xl-5"></div>

            <ToastContainer 
                hideProgressBar={true}
                pauseOnHover
                autoClose={5000}
            />


        </div> 
    )
}

export default RegistrationSummaryItem



//  const checkDocument = (document:VehicleDocument) => {
//      if(document.documentName.toLowerCase() === 'cmr'){
//         return cmrChecked
//      }

//      if(document.isRequired){
//         return true
//      }
//      else{
//         return false
//      }
//  }

//  const handleSelectDocument = async (index: number, e:any) => {
//     if(e.target.value.toLowerCase() === 'cmr'){
//         setCmrChecked(e.target.checked)
//         const payload = {
//             amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
//             registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
//             order: vehicleOrderServices?.uuid
//         }
//         addDocumentToOrder(payload)
//     }
//  }



{/* <div className="card-body pt-2 px-0"> 
                        <div className="container-fluid">
                            <div className="row px-4">
                            
                                <div className="col-12 ps-0"> 
                                    <div className="document_to_get">Documents you will get</div>
                                </div>

                                    {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
                                    return <div key={index} className="col-md-6">
                                    <div className="row label-inner">
                                                <div className="col-1 px-0">
                                                    <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className={doc.isRequired? "": "active"} value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
                                                </div>

                                                <div className="col-11">
                                                    <label htmlFor={doc.documentName}>{doc.documentName}</label>
                                                </div>  
                                            </div>     
                                        </div>     
                                    })}
                            </div>
                        </div> */}



// import React, { FC, Key, useEffect, useState } from 'react' 
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { ServiceDeliveryType, ServiceOrderDataType } from 'Models';
// import { deliveryService, orderService, userService, vehicleService } from 'Services';
// import Swal from 'sweetalert2';
// import { vehicleServiceDocument } from 'Models/vehicleServiceType';
// import "./RegistrationSummaryItem.scss"
// import { CurrencyFormatter } from 'Helpers';
// import { useForm, useFormState } from 'react-hook-form';

// // import lgas from "Data/lgaNG.json"
// import { VehicleDocument } from '@/Models/vehicleDocument';
// import { Lga, State } from '@/Models/location';
// import { CHANGE_OF_OWNERSHIP_SERVICE_ID, LAGOS_STATE_ID } from '../../../../../Constants/RegistrationConstant';
// import { ReplaceCharacter } from '../../../../../Helpers/ReplaceCharacter';
// import CustomToastr from '../../../../../Components/CustomToastr/CustomToastr';
// import { ToastContainer, toast } from "react-toastify";


// interface propsType {
//     module?: string | undefined,
//     refreshServiceOrder?: ()=>void
//     vehicleOrderServices?: ServiceOrderDataType | undefined
// } 

// interface vehicleOrderServiceDocumentType {
//     id: string;
//     time: string;
//     name: string;
//     vehicleClassName: string;
//     subClassName: string;
//     vehicleClassSize: string;
//     orderType: string;
//     costPrice: string;
//     sellingPrice: string;
//     isRequired: boolean;
// }

// type formType = {
//     address: string,
//     localGovernmentUuid: string,
//     state: string,
//     contact_phone: string,
//     primary: boolean,
//     contact_name: string,
//   }

// const RegistrationSummaryItem:FC<propsType> = ({module, vehicleOrderServices, refreshServiceOrder}) => {

//     console.log(vehicleOrderServices)

//    const navigate = useNavigate();

//    const limit = 100;
//    const offset = 0;
   
//    const { service_order_id } = useParams<{ service_order_id: string }>()
    
//     const { register, watch, handleSubmit, trigger, formState: { errors, isValid }, setValue } = useForm<formType>();

//     const [selectState, setSelectedState] = useState<string>("")

//     const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

//     const [requestAddr, setRequestAddr] = useState(false)
//     const [newAddress, setNewAddress] = useState(false)


//     const [cssPhNumber, setCssPhNum]  = useState<string>('')
//     const [phoneNumbers, setPhoneNumbers] = useState<string>('')

//     const [newVehicleRegistrationDocuments, setNewVehicleRegistrationDocuments] = useState<VehicleDocument[]>()

//     const [states, setStates] = useState<Array<State>>()
//     const [lgas, setLgas] = useState<Array<Lga>>()
//     const [processingDeliveryDetails, setProcessingDeliveryDetails] = useState<boolean>(false)

//     const [showDeliveryDetailsForm, setShowDeliveryDetailsForm] = useState<boolean>(false)

//     const handleState = async (e: any) => {
//         const state_id = e?.target?.value || e;  
//         await vehicleService.getLGAsByStateId(state_id).then((response)=>{
//             console.log("getService", response)
//             setLgas(response.data.data)
//           }, error=>{
//            console.log("getService error", error)
//            Swal.fire({
//                position: "top-end",
//                icon: "warning",
//                text: error.response.data.message,
//                showConfirmButton: false,
//                timer: 3000
//            });
//           })
//     }

//     const submit = async (data: formType) =>{
//         setRequestAddr(true)

//         const newData:any = [
//             { 
//                 ...data,
//             state: selectState,  
//             primary: true, 
//             }
//         ]

//         await userService.createDeliveryAddress(newData).then((response)=>{ 
//             Swal.fire({
//                 position: "top-end",
//                 icon: "success",
//                 title: "Saved",
//                 text: "Delivery address addeed successfully",
//                 showConfirmButton: false,
//                 timer: 1600
//             });

//             setRequestAddr(false)
//             setNewAddress(!newAddress) 
            
//         }, error =>{ 
//             setRequestAddr(false)
//             console.log("error address", error.response) 

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//             });
//         }) 
//     }

//   const validatePhoneNumber = (value: string) => {
//       return true
//     // Regular expression to validate phone number with country code
//     const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
//     return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
//   };
  
//   const handleChange = () => {  
//     trigger('contact_phone'); 
//   }; 

//   const checkPhNo = (phNumber: string) => {
    
//     if(phNumber.length === 14 && phNumber.substring(0, 4) === '+234') {
//         setPhoneNumbers(phNumber)
//         setCssPhNum('')
//     } else if (phNumber.length === 11 && phNumber.substring(0, 1) === '0') {
//         setCssPhNum('');
//         setPhoneNumbers(`+234${phNumber.slice(1)}`)
//     } else if(phNumber.length === 0 && !phNumber) {
//         setCssPhNum('')
//     }
//      else {
//         setCssPhNum('Invalid')
//     }
//     // console.log(phoneNumbers, phNumber)
//   }

//   const [serviceDeliveryType, setServiceDeliveryType] = useState<ServiceDeliveryType[]>()

// //   const getDocumentsYouWillGet = async () => {
// //       await vehicleService.getDocumentsYouWillGet().then((response) => {
// //         console.log(response)
// //         setNewVehicleRegistrationDocuments(response.data.data)
// //       }, error => {
// //         console.log("error getDocuments", error.response) 

// //         Swal.fire({
// //             position: "top-end",
// //             icon: "warning",
// //             title: "Error",
// //             text: error?.response?.data?.error,
// //             showConfirmButton: true
// //           });
// //     })
// //   }


// //   useEffect(() => {
// //     setDocumentCheckedState(new Array(newVehicleRegistrationDocuments?.length).fill(false))
// //   },[newVehicleRegistrationDocuments])

//   const getServiceTypes = async () => {
//     await deliveryService.getServiceTypePricesByServiceId(`${vehicleOrderServices?.registrationService.uuid}`).then((response)=>{
//         console.log("getServiceType data", response.data.data)
//         setServiceDeliveryType(response.data.data)
//     }, error => {
//         console.log("error getServiceType", error.response) 

//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error,
//             showConfirmButton: true
//           });
//     })
//   }

//   const getStates = async () =>{
//     await vehicleService.getStates().then((response) =>{
//         console.log("states", response.data)
//         setStates(response.data.data)
//     }, error => {

//         console.log("error make", error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             text: error.response.data.message,
//             showConfirmButton: false,
//             timer: 3000
//         });
//     })
// }

//   useEffect(()=>{
//     // getDocumentsYouWillGet()
//     getStates()
//   }, [])

//   useEffect(() => {
//     if(vehicleOrderServices?.registrationService.uuid){
//         getServiceTypes()
//     }
//   }, [vehicleOrderServices?.registrationService.uuid])

//   useEffect(() => {
//     let lagos = states?.find((state: State) => state.name.toLowerCase() === 'lagos')
//     setValue('state', lagos ? lagos.uuid : LAGOS_STATE_ID)
//     handleState(lagos ? lagos.uuid : LAGOS_STATE_ID)
//   }, [states])

//   useEffect(() => {
//     setNewVehicleRegistrationDocuments(vehicleOrderServices?.registrationService.documents)
//   }, [vehicleOrderServices?.registrationService.documents])

//   const [selectServiceDeliveryType, setSelectServiceDeliveryType] = useState<ServiceDeliveryType>()

//   const handleServiceDeliveryType = async (e:React.ChangeEvent<HTMLInputElement>) => {
//      console.log("handleServiceDeliveryType", e.target.value)
//      const serviceType:ServiceDeliveryType = JSON.parse(e.target.value);
//      console.log(serviceType)
//      processServiceDeliveryType(serviceType)
//     //  setSelectServiceDeliveryType(serviceType) 
//   }

// //   const handleSelectDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
// //       console.log(e.target.value)
// //   }

//   const [documentCheckedState, setDocumentCheckedState] = useState([]);

//   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => { 
//     console.log(selecttype)
//     await deliveryService.updateVehicleTypePriceOrder({
//        amount: +selecttype.price,
//        vehicleTypePriceId: selecttype.uuid,
//        orderUuid: `${service_order_id}`
//     }).then((response)=>{   
//         console.log(response)
//         refreshServiceOrder && refreshServiceOrder()
//     }, error => {
//         console.log("error getServiceType", error.response) 

//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error,
//             showConfirmButton: true
//           });
//     })
//     // dfdsfs
//   }


// const [deliveryDetails, setDeliveryDetails] = useState<any>({
//     amount: vehicleOrderServices?.totalAmount,
//     orderId: service_order_id,
//     localGovernmentUuid: null,
//     address: null,
//     contact_name: null,
//     contact_phone: null
// })

//   const handleDeliveryForm = (e:React.ChangeEvent<HTMLInputElement>) => {
//       console.log(e)
//     setDeliveryDetails(
//         {
//             ...deliveryDetails,
//             [e.target.name]: e.target.value,
//             amount: vehicleOrderServices?.totalAmount
//         }
//     )
//   }

//   const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
//       setShowDeliveryDetailsForm(e.target.checked)
//   }

//   const processDeliveryInformation = async () => {
//       console.log(deliveryDetails)
//       setProcessingDeliveryDetails(true)
//       await deliveryService.saveDeliveryDetails(deliveryDetails).then(()=>{   
//             refreshServiceOrder && refreshServiceOrder()
//             setProcessingDeliveryDetails(false)
//             setShowDeliveryDetailsSummary(true)
//             setShowDeliveryDetailsForm(false)
//             toast(<CustomToastr message={`Delivery details added successfully`}/>);
//         }, error => {
//             setProcessingDeliveryDetails(false)
//             console.log("error getServiceType", error.response) 

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//                 });
//         })
//   }

// //   //update service delivery type
// //   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => { 
// //     // if(deliveryDetails.state)
// //    // await deliveryService.updateOrderDeliveryService(service_order_id, {
// //     //    amount: selecttype.amount,
// //     //    deliveryFeeServiceId: selecttype.id,
// //     //    state:  JSON.parse(deliveryDetails.state).name,
// //     // }).then(()=>{   
// //     //     refreshServiceOrder && refreshServiceOrder()
// //     // }, error => {
// //     //     console.log("error getServiceType", error.response) 

// //     //     Swal.fire({
// //     //         position: "top-end",
// //     //         icon: "warning",
// //     //         title: "Error",
// //     //         text: error?.response?.data?.error,
// //     //         showConfirmButton: true
// //     //       });
// //     // })
// //  }


// //  useEffect(() => {
// //     if(selectServiceDeliveryType && deliveryDetails.state){
// //         processServiceDeliveryType(selectServiceDeliveryType) 
// //     }
// //  }, [selectServiceDeliveryType, deliveryDetails])

//     const [showDeliveryDetailsSummary, setShowDeliveryDetailsSummary] = useState<boolean>(false)

//     const [deliveryDetailsFormIsValid, setDeliveryDetailsFormIsValid] = useState<boolean>(false)
//     useEffect(() => {
//         const fields: Array<keyof formType> = ['address', 'localGovernmentUuid', 'state', 'contact_phone', 'contact_name'];
//         const allFieldsFilled = fields.every(field => !!watch(field));

//         setDeliveryDetailsFormIsValid(allFieldsFilled)
    
//       }, [
//         watch('address'),
//         watch('localGovernmentUuid'),
//         watch('state'),
//         watch('contact_phone'),
//         watch('contact_name')
//       ]);



//  const [requestPayment, setRequestPayment] = useState(false)

//  const makePayment = async () => {
//     const payload = {
//         orderId: `${service_order_id}`,
//         totalOrderAmount: Number(vehicleOrderServices?.totalAmount)
//     }
//     // const payload = {
//     //     "orderId": "0cd97726-3341-4cef-acdf-7c3e48169fef",
//     //     "totalOrderAmount": 30500
//     // }
//     setRequestPayment(true)
//     console.log(payload)
//     await orderService.initiatePayment(payload).then((response)=>{
//         console.log(response)
//          setRequestPayment(false)
//          console.log("Payment", response.data.data)

//          window.location.href = response.data.data.data
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//         setRequestPayment(false)
//     })
//  }

//  const [cmrChecked, setCmrChecked] = useState(false)

//  const [temporaryPngImageUrl, setTemporaryImageUrl] = useState<string>("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBtZI9TFNRFMf/9/W9tGiV1toXEUpKYh1ECVI/hiZ+btqlDsjgUE0cjTp108iIjo5GTHRQFxcjEzExYdBETdS41KSmGgol5ZtQQsvjnkMoH72F11L+ycv9OPf97v+ec8S9gc5g0Wn0wxIXsHP9LRatmFYwHJ11ApKCDod2VxNC86DO0rALUkKPeLr4O+BqQoO+j+c0NruPcpzmJ/znOW4berujDyFvlxwf42zTFdw49gAd/nO8T6DEmZdyPMT7KumqzfnCLP7PJiUwyuvkxFfpNsxzuuxz5j1+jn3CRH4Etp026G60uEN49buXLxjPZ3i9XgS/FrpvH0qggdQz/Jn8VtrLSTBd9kM6JNch+dFaJXFn8HRcDv2oUlSs+cKMCvmi5pZSA1eko0od338Rl82byBfnMJR7i1/TH8vOKJ02GiYivm6YzrayWORgN5zaXj5DcFtOW/e0I3Y4wT9GfNfZzVDuTSnu0taKQ25V2uA07I2ip6WXgSVn0vF6R4PZ55gqZLGwtPL8LZ1e8t/CKe9V5aGwJ4qAqx3vhvs4h6o8bnDqMwLsrhJwVaarDT2BR2jUTWwnkU6n40LY71N69ofMUyTnvlRCVt+nlO9Yc4KLWEk1N//mAtYFSqICxluflOVZpFIpj2EY3y3LCqJGTS1m8frfQ9lqo5OLmuPkMjsomVugXiEtAAAAAElFTkSuQmCC")

//  const checkDocument = (document:VehicleDocument) => {
//      if(document.documentName.toLowerCase() === 'cmr'){
//         return cmrChecked
//      }

//      if(document.isRequired){
//         return true
//      }
//      else{
//         return false
//      }
//  }

//  const handleSelectDocument = async (index: number, e:any) => {
//     console.log(index)
//     console.log(e)
//     if(e.target.value.toLowerCase() === 'cmr'){
//         setCmrChecked(e.target.checked)
//         const payload = {
//             amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
//             registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
//             order: vehicleOrderServices?.uuid
//         }
//         addDocumentToOrder(payload)
//     }
//  }

//  const addDocumentToOrder = async (payload: any) => {
//     console.log(payload)
//     await orderService.addDocumentToAnOrder(payload).then((response)=>{
//         console.log(response)
//          console.log("Add document to order", response.data)
//          refreshServiceOrder && refreshServiceOrder()
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//     })
//  }

//  const goToVehicleInformation = () => {
//     navigate({
//         pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
//         search: `?step=1&orderId=${vehicleOrderServices?.uuid}`,
//     });
//  }

//  const goToVehicleOwnerInformation = () => {
//     navigate({
//         pathname: `/registration-service/${vehicleOrderServices?.registrationService.uuid}`,
//         search: `?step=2&orderId=${vehicleOrderServices?.uuid}`,
//     });
//  }

//  useEffect(() => {
//     setCmrChecked(vehicleOrderServices ? vehicleOrderServices?.requiredCRM : false)
//  }, [vehicleOrderServices?.requiredCRM])

// //  useEffect(() => {

// //  },[processingDeliveryDetails])

//     return ( 
//         <div className="RegistrationSummaryItem row justify-content-center pt-3 pb-5">
//             <div className="col-12 col-md-6 col-lg-6 col-xl-5">
//                 <div className="card border-0 mt-3 mt-md-0 mb-4 px-4">
//                     <div className="d-flex justify-content-between align-items-center">
//                         <div className="card-header bg-white pt-3 px-0">
//                             Vehicle Information
//                         </div>
//                         <p className="edit-text" onClick={goToVehicleInformation}>Edit</p>
//                     </div>
//                     <div className="card-body pt-0 px-0">
//                         <div className="row border-top pb-2 pt-3">
//                             {
//                                 vehicleOrderServices?.plateNumber &&
//                                 <>
//                                     <div className="col-6">
//                                         <div className="label">
//                                             Plate Number
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                             {vehicleOrderServices?.plateNumber }
//                                         </div>
//                                     </div>
//                                 </>
//                             }
//                             <div className="col-6">
//                                 <div className="label">
//                                     Make 
//                                 </div> 
//                             </div>
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                     {vehicleOrderServices?.vehicleMake?.name }
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Model
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value"> 
//                                     {vehicleOrderServices?.vehicleModel?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Year
//                                 </div> 
//                             </div> 
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                    {vehicleOrderServices?.vehicleYear.year}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Color
//                                 </div> 
//                             </div> 
//                             <div className="col-6 text-end">
//                                 <div className="value text-capitalize">
//                                 {vehicleOrderServices?.vehicleColor}
//                                 </div>
//                             </div>


//                             {vehicleOrderServices?.engineNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Engine number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.engineNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.chasisNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Chasis number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.chasisNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }


//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Class
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleType?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Use
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleUse?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                    CMR(Central Motor Registry)
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.requiredCRM ? 'Yes' : 'No'}
//                                 </div>
//                             </div>

//                             {vehicleOrderServices?.nin &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     NIN
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.nin}
//                                     </div>
//                                 </div>
//                                 </>
//                             }
                            
//                             {vehicleOrderServices?.stateLiencingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State licensing office
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateLiencingOffice}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.stateRequestingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State Requesting office
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateRequestingOffice}
//                                     </div>
//                                 </div>
//                                 </>
//                             }


//                         </div>
//                     </div>
//                 </div>

//                 <div className="card border-0 mt-3 mt-md-0 px-4">
//                     <div className="d-flex justify-content-between align-items-center border-bottom">
//                         <div className="card-header bg-white pt-3 px-0">
//                             Vehicle Owner's Information
//                         </div>
//                         <p className="edit-text" onClick={goToVehicleOwnerInformation}>Edit</p>
//                     </div>

//                     <div className="card-body pt-0 px-0">

//                         {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID ?

//                             <>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0 text-14px">REGISTERED OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.firstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.lastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.address}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.phoneNumber}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0 text-14px">NEW OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerFirstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerLastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerAddress}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerPhoneNumber}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Email
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerEmail}
//                                         </div>
//                                     </div>
//                                 </div>  
//                             </>
                            
//                             :

//                             <div className="row border-bottom pb-2 mt-2 px-0">
//                                 <div className="col-6">
//                                     <div className="label">
//                                     First Name
//                                     </div> 
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.firstName}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                     Last Name
//                                     </div> 
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.lastName}
//                                     </div>
//                                 </div>


//                                 <div className="col-6">
//                                     <div className="label">
//                                         Email
//                                     </div> 
//                                 </div> 
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.email}
//                                     </div>
//                                 </div> 

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Phone number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.phoneNumber}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Address
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.address}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         State
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
                                    
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         LGA
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.localGovernment?.name}
//                                     </div>
//                                 </div>
//                             </div>
//                         }
                          
                        


//                         <div className="row  pb-2 mt-2">
//                             <div className="col-12"> 
//                                 <div className="form-group d-flex form-crm-input">
//                                     <input type="checkbox" id="requiredCRM" className='my-auto me-2'  onChange={handleUseDeliveryDetails}/> 
//                                     <label htmlFor="requiredCRM" className='my-auto'>Use different information for delivery details (The address your documents get delivered to)</label>
//                                 </div>  
//                             </div> 
//                         </div>  


//                     </div>
//                 </div>

//                 {showDeliveryDetailsForm &&
//                 <div className="col-12 mt-4">
//                     <div className="card px-4 py-3 border-0 delivery-details">
//                         <div className="card-header profile-title px-0 border-bottom pb-3">
//                             Delivery Detailss
//                         </div>
//                         <div className="card-body pt-0 px-0">   
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="address">Address</label>
//                                 <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address', { required: "This field is required", onChange: handleDeliveryForm})}/>
//                                 {errors.address && <small className='error'>{errors.address.message}</small>} 
//                             </div>
//                             <div className='form-group mt-3' title="Service available within Lagos. Other locations coming soon.">
//                                 <label htmlFor="state">State</label>
//                                 <select disabled id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => {handleState(e); handleDeliveryForm(e)}})}>
//                                     <option>select state</option>
//                                     {states?.map((stateItem: State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                 </select>
//                                 {errors.state && <small className='error'>{errors.state.message}</small>} 
//                             </div>
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="lga">L.G.A</label>
//                                 <select id="lga" className={`form-control ${errors.localGovernmentUuid ? 'is-invalid' : ''}`} {...register('localGovernmentUuid', { required: "This field is required", onChange: handleDeliveryForm})}>
//                                     <option>select L.G.A</option>
//                                     {lgas?.map((lga:Lga, index: Key)=><option key={index} value={lga.id}>{lga.name}</option>)}
//                                 </select>
//                                 {errors.localGovernmentUuid && <small className='error'>{errors.localGovernmentUuid.message}</small>} 
//                             </div>  
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="contactName">Contact Name</label>
//                                 <input type="text" id="contactName" className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`} 
//                                 {...register("contact_name", { required: "This field is required", onChange: handleDeliveryForm})} />
//                                 {errors.contact_name && <small className='error'>{errors.contact_name.message}</small>} 
//                             </div>

//                             <div className='form-group mt-3'>
//                                 <label htmlFor="contact_phone">Phone Number</label>
//                                 <input type="text" id="contact_phone" className={`form-control ${cssPhNumber} ${errors.contact_phone ? 'is-invalid' : ''}`} 
//                                 // {...register("contact_phone", { required: "This field is required"})}  onChange={(e) => {checkPhNo(e.target.value); handleChange(); handleDeliveryForm(e)}}/>
//                                 // {...register("contact_phone", { required: "This field is required", validate: validatePhoneNumber, onChange:(e) =>{ handleChange(); handleDeliveryForm(e)}})}  onChange={(e) => {checkPhNo(e.target.value)}}/>
//                                 {...register("contact_phone", { required: "This field is required", validate: validatePhoneNumber, onChange:(e) =>{ handleChange(); handleDeliveryForm(e)}})}/>
//                                 {errors.contact_phone && <small className='error'>{errors.contact_phone.message}</small>} 
//                             </div>
                    
//                         </div>
//                         <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button>
//                     </div>
//                 </div>
//             }

//             {showDeliveryDetailsSummary &&
//                 <div className="col-12 mt-4">
//                     <div className="card border-0 mt-3 mt-md-0 px-4">
//                         <div className="card-header bg-white pt-3 border-bottom px-0">
//                             Delivery Details
//                         </div>
//                         <div className="card-body pt-0 px-0">
//                                 <div className="row border-top pb-2 pt-3">
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Address
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.localGovernmentOrders[0].address}
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         State
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
                                        
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         L.G.A
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">

//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Contact Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.localGovernmentOrders[0].contact_name}
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Contact Number
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.localGovernmentOrders[0].contact_phone}
//                                         </div>
//                                     </div>
//                                 </div>
//                         </div>
//                     </div>
//                 </div>
//             }

//             {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID  &&
//                 <div className="col-12 mt-3 mt-md-0">
//                     <div className="card border-0 mt-4 px-4">
//                         <div className="card-header bg-white pt-3 border-bottom px-0">
//                             Documents
//                         </div>
//                         <div className="card-body pt-0 px-0 mt-4">
//                             {vehicleOrderServices.uploadedDocuments.map(document => {
//                                 return (
//                                     <div className="d-flex justify-content-between document-container">
//                                         <div className="d-flex">
//                                             <img src={temporaryPngImageUrl} alt="" height="20px"/>
//                                             <p className="doc-name">{ReplaceCharacter(document.documentName, "_", " ")}</p>
//                                         </div>
//                                         <p className="doc-size">1MB</p>
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                     </div>
//                 </div>
//             }

//             </div>  
 
//             <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">
                 
//                     <div className="card document-details-card  mt-md-0 p-0 border-0">

//                     <div className="card-header px-0 pt-3 mx-4">
//                         <div className="row h-100">
//                             <div className="col-md-12 text-center my-auto d-inline-flex">
//                             Summary
//                             </div>
//                         </div>
//                     </div>
 
//                     <div className="card-body pt-2 px-0"> 
//                         <div className="container-fluid">
//                             <div className="row px-4">
                            
//                                 <div className="col-12 ps-0"> 
//                                     <div className="document_to_get">Documents you will get</div>
//                                 </div>

//                                     {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
//                                     return <div key={index} className="col-md-6">
//                                     <div className="row label-inner">
//                                                 <div className="col-1 px-0">
//                                                     <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className={doc.isRequired? "": "active"} value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
//                                                 </div>

//                                                 <div className="col-11">
//                                                     <label htmlFor={doc.documentName}>{doc.documentName}</label>
//                                                 </div>  
//                                             </div>     
//                                         </div>     
//                                     })}
//                             </div>
//                         </div>

//                         <div className="service-type px-3 py-3 mt-3">

//                             <div className="container-fluid h-100">
//                                 <div className="row h-100">
//                                     <div className="col-12 my-auto">
//                                        <div className="title">Service type</div> 
//                                        {serviceDeliveryType && serviceDeliveryType.map((serviceType:ServiceDeliveryType, index:Key)=>{ 
//                                         return <div className="row h-100 mt-2" key={index}>
//                                             <div className="col-8 type my-auto d-flex h-100">
//                                                <input type="radio" name="serice_type" id={`standard_delivery_${serviceType.uuid}`} value={JSON.stringify(serviceType)} onChange={handleServiceDeliveryType}/> <label htmlFor={`standard_delivery_${serviceType.uuid}`} className='ms-2 my-auto'> {serviceType.service_name} - {serviceType.registrationService.processingTime}  days </label>
//                                             </div>
//                                             <div className="col-4 amount h-100 text-end">
//                                                ₦{CurrencyFormatter(Number(serviceType.price))}
//                                             </div>
//                                         </div>
//                                          })}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="processing_time px-4">
//                            <div className="row h-100">
//                             <div className="col-6 title my-auto">Processing Time</div>
//                             <div className="col-6 time my-auto">{vehicleOrderServices?.registrationService.processingTime} days</div>
//                            </div>
//                         </div>

//                         <div className="total-balance px-4 border-0"> 
//                             <div className="row h-100 pt-4">    
//                                 <div className="col-6 my-auto">
//                                     Total
//                                 </div>
//                                 <div className="col-6 text-end my-auto">
//                                    ₦{CurrencyFormatter(Number(vehicleOrderServices?.totalAmount))}
//                                 </div> 
//                             </div> 
//                         </div>
//                     </div>
//                 </div> 

//                  <button className="btn checkout-btn" disabled={showDeliveryDetailsForm && !deliveryDetailsFormIsValid} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
             
//             </div>  




//             <div className="col-12 col-md-6 col-lg-6 col-xl-5"></div>

//             <ToastContainer 
//                 hideProgressBar={true}
//                 pauseOnHover
//                 autoClose={5000}
//             />


//         </div> 
//     )
// }

// export default RegistrationSummaryItem





// import React, { FC, Key, useEffect, useState } from 'react' 
// import { Link, useParams } from 'react-router-dom';
// import { ServiceDeliveryType, ServiceOrderDataType } from 'Models';
// import { deliveryService, orderService, userService, vehicleService } from 'Services';
// import Swal from 'sweetalert2';
// import { vehicleServiceDocument } from 'Models/vehicleServiceType';
// import "./RegistrationSummaryItem.scss"
// import { CurrencyFormatter } from 'Helpers';
// import { useForm, useFormState } from 'react-hook-form';

// // import lgas from "Data/lgaNG.json"
// import { VehicleDocument } from '@/Models/vehicleDocument';
// import { Lga, State } from '@/Models/location';
// import { CHANGE_OF_OWNERSHIP_SERVICE_ID, LAGOS_STATE_ID } from '../../../../../Constants/RegistrationConstant';
// import { ReplaceCharacter } from '../../../../../Helpers/ReplaceCharacter';
// import CustomToastr from '../../../../../Components/CustomToastr/CustomToastr';
// import { ToastContainer, toast } from "react-toastify";


// interface propsType {
//     module?: string | undefined,
//     refreshServiceOrder?: ()=>void
//     vehicleOrderServices?: ServiceOrderDataType | undefined
// } 

// interface vehicleOrderServiceDocumentType {
//     id: string;
//     time: string;
//     name: string;
//     vehicleClassName: string;
//     subClassName: string;
//     vehicleClassSize: string;
//     orderType: string;
//     costPrice: string;
//     sellingPrice: string;
//     isRequired: boolean;
// }

// type formType = {
//     address: string,
//     localGovernmentUuid: string,
//     state: string,
//     contact_phone: string,
//     primary: boolean,
//     contact_name: string,
//   }

// const RegistrationSummaryItem:FC<propsType> = ({module, vehicleOrderServices, refreshServiceOrder}) => {

//    const limit = 100;
//    const offset = 0;
   
//    const { service_order_id } = useParams<{ service_order_id: string }>()
    
//     const { register, watch, handleSubmit, trigger, formState: { errors, isValid }, setValue } = useForm<formType>();

//     const [selectState, setSelectedState] = useState<string>("")

//     const [selectLgas, setSelectedLgas] = useState<Array<{name: string, id: number}>>([])

//     const [requestAddr, setRequestAddr] = useState(false)
//     const [newAddress, setNewAddress] = useState(false)


//     const [cssPhNumber, setCssPhNum]  = useState<string>('')
//     const [phoneNumbers, setPhoneNumbers] = useState<string>('')

//     const [newVehicleRegistrationDocuments, setNewVehicleRegistrationDocuments] = useState<VehicleDocument[]>()

//     const [states, setStates] = useState<Array<State>>()
//     const [lgas, setLgas] = useState<Array<Lga>>()
//     const [processingDeliveryDetails, setProcessingDeliveryDetails] = useState<boolean>(false)

//     const [showDeliveryDetailsForm, setShowDeliveryDetailsForm] = useState<boolean>(false)

//     const handleState = async (e: any) => {
//         const state_id = e?.target?.value || e;  
//         await vehicleService.getLGAsByStateId(state_id).then((response)=>{
//             console.log("getService", response)
//             setLgas(response.data.data)
//           }, error=>{
//            console.log("getService error", error)
//            Swal.fire({
//                position: "top-end",
//                icon: "warning",
//                text: error.response.data.message,
//                showConfirmButton: false,
//                timer: 3000
//            });
//           })
//     }

//     const submit = async (data: formType) =>{
//         setRequestAddr(true)

//         const newData:any = [
//             { 
//                 ...data,
//             state: selectState,  
//             primary: true, 
//             }
//         ]

//         await userService.createDeliveryAddress(newData).then((response)=>{ 
//             Swal.fire({
//                 position: "top-end",
//                 icon: "success",
//                 title: "Saved",
//                 text: "Delivery address addeed successfully",
//                 showConfirmButton: false,
//                 timer: 1600
//             });

//             setRequestAddr(false)
//             setNewAddress(!newAddress) 
            
//         }, error =>{ 
//             setRequestAddr(false)
//             console.log("error address", error.response) 

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//             });
//         }) 
//     }

//   const validatePhoneNumber = (value: string) => {
//       return true
//     // Regular expression to validate phone number with country code
//     const phoneRegex = /^\+\d{1,3}-\d{3,14}$/;
//     return phoneRegex.test(value) || 'Phone number must have a leading country code (e.g., +23481456789..)';
//   };
  
//   const handleChange = () => {  
//     trigger('contact_phone'); 
//   }; 

//   const checkPhNo = (phNumber: string) => {
    
//     if(phNumber.length === 14 && phNumber.substring(0, 4) === '+234') {
//         setPhoneNumbers(phNumber)
//         setCssPhNum('')
//     } else if (phNumber.length === 11 && phNumber.substring(0, 1) === '0') {
//         setCssPhNum('');
//         setPhoneNumbers(`+234${phNumber.slice(1)}`)
//     } else if(phNumber.length === 0 && !phNumber) {
//         setCssPhNum('')
//     }
//      else {
//         setCssPhNum('Invalid')
//     }
//     // console.log(phoneNumbers, phNumber)
//   }

//   const [serviceDeliveryType, setServiceDeliveryType] = useState<ServiceDeliveryType[]>()

// //   const getDocumentsYouWillGet = async () => {
// //       await vehicleService.getDocumentsYouWillGet().then((response) => {
// //         console.log(response)
// //         setNewVehicleRegistrationDocuments(response.data.data)
// //       }, error => {
// //         console.log("error getDocuments", error.response) 

// //         Swal.fire({
// //             position: "top-end",
// //             icon: "warning",
// //             title: "Error",
// //             text: error?.response?.data?.error,
// //             showConfirmButton: true
// //           });
// //     })
// //   }


// //   useEffect(() => {
// //     setDocumentCheckedState(new Array(newVehicleRegistrationDocuments?.length).fill(false))
// //   },[newVehicleRegistrationDocuments])

//   const getServiceTypes = async () => {
//     await deliveryService.getServiceTypePricesByServiceId(`${vehicleOrderServices?.registrationService.uuid}`).then((response)=>{
//         console.log("getServiceType data", response.data.data)
//         setServiceDeliveryType(response.data.data)
//     }, error => {
//         console.log("error getServiceType", error.response) 

//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error,
//             showConfirmButton: true
//           });
//     })
//   }

//   const getStates = async () =>{
//     await vehicleService.getStates().then((response) =>{
//         console.log("states", response.data)
//         setStates(response.data.data)
//     }, error => {

//         console.log("error make", error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             text: error.response.data.message,
//             showConfirmButton: false,
//             timer: 3000
//         });
//     })
// }

//   useEffect(()=>{
//     // getDocumentsYouWillGet()
//     getStates()
//   }, [])

//   useEffect(() => {
//     if(vehicleOrderServices?.registrationService.uuid){
//         getServiceTypes()
//     }
//   }, [vehicleOrderServices?.registrationService.uuid])

//   useEffect(() => {
//     let lagos = states?.find((state: State) => state.name.toLowerCase() === 'lagos')
//     setValue('state', lagos ? lagos.uuid : LAGOS_STATE_ID)
//     handleState(lagos ? lagos.uuid : LAGOS_STATE_ID)
//   }, [states])

//   useEffect(() => {
//     setNewVehicleRegistrationDocuments(vehicleOrderServices?.registrationService.documents)
//   }, [vehicleOrderServices?.registrationService.documents])

//   const [selectServiceDeliveryType, setSelectServiceDeliveryType] = useState<ServiceDeliveryType>()

//   const handleServiceDeliveryType = async (e:React.ChangeEvent<HTMLInputElement>) => {
//      console.log("handleServiceDeliveryType", e.target.value)
//      const serviceType:ServiceDeliveryType = JSON.parse(e.target.value);
//      console.log(serviceType)
//      processServiceDeliveryType(serviceType)
//     //  setSelectServiceDeliveryType(serviceType) 
//   }

// //   const handleSelectDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
// //       console.log(e.target.value)
// //   }

//   const [documentCheckedState, setDocumentCheckedState] = useState([]);

//   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => { 
//     console.log(selecttype)
//     await deliveryService.updateVehicleTypePriceOrder({
//        amount: +selecttype.price,
//        vehicleTypePriceId: selecttype.uuid,
//        orderUuid: `${service_order_id}`
//     }).then((response)=>{   
//         console.log(response)
//         refreshServiceOrder && refreshServiceOrder()
//     }, error => {
//         console.log("error getServiceType", error.response) 

//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error,
//             showConfirmButton: true
//           });
//     })
//     // dfdsfs
//   }


// const [deliveryDetails, setDeliveryDetails] = useState<any>({
//     amount: vehicleOrderServices?.totalAmount,
//     orderId: service_order_id,
//     localGovernmentUuid: null,
//     address: null,
//     contact_name: null,
//     contact_phone: null
// })

//   const handleDeliveryForm = (e:React.ChangeEvent<HTMLInputElement>) => {
//       console.log(e)
//     setDeliveryDetails(
//         {
//             ...deliveryDetails,
//             [e.target.name]: e.target.value,
//             amount: vehicleOrderServices?.totalAmount
//         }
//     )
//   }

//   const handleUseDeliveryDetails = (e:React.ChangeEvent<HTMLInputElement>) => {
//       setShowDeliveryDetailsForm(e.target.checked)
//   }

//   const processDeliveryInformation = async () => {
//       console.log(deliveryDetails)
//       setProcessingDeliveryDetails(true)
//       await deliveryService.saveDeliveryDetails(deliveryDetails).then(()=>{   
//             refreshServiceOrder && refreshServiceOrder()
//             setProcessingDeliveryDetails(false)
//             setShowDeliveryDetailsSummary(true)
//             setShowDeliveryDetailsForm(false)
//             toast(<CustomToastr message={`Delivery details added successfully`}/>);
//         }, error => {
//             setProcessingDeliveryDetails(false)
//             console.log("error getServiceType", error.response) 

//             Swal.fire({
//                 position: "top-end",
//                 icon: "warning",
//                 title: "Error",
//                 text: error?.response?.data?.error,
//                 showConfirmButton: true
//                 });
//         })
//   }

// //   //update service delivery type
// //   const processServiceDeliveryType = async (selecttype:ServiceDeliveryType ) => { 
// //     // if(deliveryDetails.state)
// //    // await deliveryService.updateOrderDeliveryService(service_order_id, {
// //     //    amount: selecttype.amount,
// //     //    deliveryFeeServiceId: selecttype.id,
// //     //    state:  JSON.parse(deliveryDetails.state).name,
// //     // }).then(()=>{   
// //     //     refreshServiceOrder && refreshServiceOrder()
// //     // }, error => {
// //     //     console.log("error getServiceType", error.response) 

// //     //     Swal.fire({
// //     //         position: "top-end",
// //     //         icon: "warning",
// //     //         title: "Error",
// //     //         text: error?.response?.data?.error,
// //     //         showConfirmButton: true
// //     //       });
// //     // })
// //  }


// //  useEffect(() => {
// //     if(selectServiceDeliveryType && deliveryDetails.state){
// //         processServiceDeliveryType(selectServiceDeliveryType) 
// //     }
// //  }, [selectServiceDeliveryType, deliveryDetails])

//     const [showDeliveryDetailsSummary, setShowDeliveryDetailsSummary] = useState<boolean>(false)

//     const [deliveryDetailsFormIsValid, setDeliveryDetailsFormIsValid] = useState<boolean>(false)
//     useEffect(() => {
//         const fields: Array<keyof formType> = ['address', 'localGovernmentUuid', 'state', 'contact_phone', 'contact_name'];
//         const allFieldsFilled = fields.every(field => !!watch(field));

//         setDeliveryDetailsFormIsValid(allFieldsFilled)
    
//       }, [
//         watch('address'),
//         watch('localGovernmentUuid'),
//         watch('state'),
//         watch('contact_phone'),
//         watch('contact_name')
//       ]);



//  const [requestPayment, setRequestPayment] = useState(false)

//  const makePayment = async () => {
//     const payload = {
//         orderId: `${service_order_id}`,
//         totalOrderAmount: Number(vehicleOrderServices?.totalAmount)
//     }
//     // const payload = {
//     //     "orderId": "0cd97726-3341-4cef-acdf-7c3e48169fef",
//     //     "totalOrderAmount": 30500
//     // }
//     setRequestPayment(true)
//     console.log(payload)
//     await orderService.initiatePayment(payload).then((response)=>{
//         console.log(response)
//          setRequestPayment(false)
//          console.log("Payment", response.data.data)

//          window.location.href = response.data.data.data
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//         setRequestPayment(false)
//     })
//  }

//  const [cmrChecked, setCmrChecked] = useState(false)

//  const [temporaryPngImageUrl, setTemporaryImageUrl] = useState<string>("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAWCAYAAAAvg9c4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAICSURBVHgBtZI9TFNRFMf/9/W9tGiV1toXEUpKYh1ECVI/hiZ+btqlDsjgUE0cjTp108iIjo5GTHRQFxcjEzExYdBETdS41KSmGgol5ZtQQsvjnkMoH72F11L+ycv9OPf97v+ec8S9gc5g0Wn0wxIXsHP9LRatmFYwHJ11ApKCDod2VxNC86DO0rALUkKPeLr4O+BqQoO+j+c0NruPcpzmJ/znOW4berujDyFvlxwf42zTFdw49gAd/nO8T6DEmZdyPMT7KumqzfnCLP7PJiUwyuvkxFfpNsxzuuxz5j1+jn3CRH4Etp026G60uEN49buXLxjPZ3i9XgS/FrpvH0qggdQz/Jn8VtrLSTBd9kM6JNch+dFaJXFn8HRcDv2oUlSs+cKMCvmi5pZSA1eko0od338Rl82byBfnMJR7i1/TH8vOKJ02GiYivm6YzrayWORgN5zaXj5DcFtOW/e0I3Y4wT9GfNfZzVDuTSnu0taKQ25V2uA07I2ip6WXgSVn0vF6R4PZ55gqZLGwtPL8LZ1e8t/CKe9V5aGwJ4qAqx3vhvs4h6o8bnDqMwLsrhJwVaarDT2BR2jUTWwnkU6n40LY71N69ofMUyTnvlRCVt+nlO9Yc4KLWEk1N//mAtYFSqICxluflOVZpFIpj2EY3y3LCqJGTS1m8frfQ9lqo5OLmuPkMjsomVugXiEtAAAAAElFTkSuQmCC")

//  const checkDocument = (document:VehicleDocument) => {
//      if(document.documentName.toLowerCase() === 'cmr'){
//         return cmrChecked
//      }

//      if(document.isRequired){
//         return true
//      }
//      else{
//         return false
//      }
//  }

//  const handleSelectDocument = async (index: number, e:any) => {
//     console.log(index)
//     console.log(e)
//     if(e.target.value.toLowerCase() === 'cmr'){
//         setCmrChecked(e.target.checked)
//         const payload = {
//             amount: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.price : 0,
//             registrationServiceDocument: newVehicleRegistrationDocuments ? newVehicleRegistrationDocuments[index]?.uuid : '',
//             order: vehicleOrderServices?.uuid
//         }
//         addDocumentToOrder(payload)
//     }
//  }

//  const addDocumentToOrder = async (payload: any) => {
//     console.log(payload)
//     await orderService.addDocumentToAnOrder(payload).then((response)=>{
//         console.log(response)
//          console.log("Add document to order", response.data)
//          refreshServiceOrder && refreshServiceOrder()
//     }, error =>{
//         console.log(error)
//         Swal.fire({
//             position: "top-end",
//             icon: "warning",
//             title: "Error",
//             text: error?.response?.data?.error?.message,
//             showConfirmButton: true
//         });
//     })
//  }

//  useEffect(() => {
//     setCmrChecked(vehicleOrderServices ? vehicleOrderServices?.requiredCRM : false)
//  }, [vehicleOrderServices?.requiredCRM])

// //  useEffect(() => {

// //  },[processingDeliveryDetails])

//     return ( 
//         <div className="RegistrationSummaryItem row justify-content-center pt-3 pb-5">
//             <div className="col-12 col-md-6 col-lg-6 col-xl-5">
//                 <div className="card border-0 mt-3 mt-md-0 mb-4 px-4">
//                     <div className="card-header bg-white pt-3 px-0">
//                         Vehicle Information
//                     </div>
//                     <div className="card-body pt-0 px-0">
//                         <div className="row border-top pb-2 pt-3">
//                             {
//                                 vehicleOrderServices?.plateNumber &&
//                                 <>
//                                     <div className="col-6">
//                                         <div className="label">
//                                             Plate Number
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                             {vehicleOrderServices?.plateNumber }
//                                         </div>
//                                     </div>
//                                 </>
//                             }
//                             <div className="col-6">
//                                 <div className="label">
//                                     Make 
//                                 </div> 
//                             </div>
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                     {vehicleOrderServices?.vehicleMake?.name }
//                                 </div>
//                             </div>

//                             <div className="col-6">
//                                 <div className="label">
//                                     Model
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value"> 
//                                     {vehicleOrderServices?.vehicleModel?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Year
//                                 </div> 
//                             </div> 
//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                    {vehicleOrderServices?.vehicleYear.year}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Color
//                                 </div> 
//                             </div> 
//                             <div className="col-6 text-end">
//                                 <div className="value text-capitalize">
//                                 {vehicleOrderServices?.vehicleColor}
//                                 </div>
//                             </div>


//                             {vehicleOrderServices?.engineNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Engine number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.engineNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.chasisNumber &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                         Chasis number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.chasisNumber}
//                                     </div>
//                                 </div>
//                                 </>
//                             }


//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Class
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleType?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                     Vehicle Use
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.vehicleUse?.name}
//                                 </div>
//                             </div>


//                             <div className="col-6">
//                                 <div className="label">
//                                    CMR(Central Motor Registry)
//                                 </div> 
//                             </div> 

//                             <div className="col-6 text-end">
//                                 <div className="value">
//                                   {vehicleOrderServices?.requiredCRM ? 'Yes' : 'No'}
//                                 </div>
//                             </div>

//                             {vehicleOrderServices?.nin &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     NIN
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.nin}
//                                     </div>
//                                 </div>
//                                 </>
//                             }
                            
//                             {vehicleOrderServices?.stateLiencingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State licensing office
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateLiencingOffice}
//                                     </div>
//                                 </div>
//                                 </>
//                             }

//                             {vehicleOrderServices?.stateRequestingOffice &&
//                                 <>
//                                 <div className="col-6">
//                                     <div className="label">
//                                     State Requesting office
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                         {vehicleOrderServices?.stateRequestingOffice}
//                                     </div>
//                                 </div>
//                                 </>
//                             }


//                         </div>
//                     </div>
//                 </div>

//                 <div className="card border-0 mt-3 mt-md-0 px-4">
//                     <div className="card-header bg-white pt-3 border-bottom px-0">
//                         Vehicle Owner's Information
//                     </div>
//                     <div className="card-body pt-0 px-0">

//                         {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID ?

//                             <>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0">REGISTERED OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.firstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.lastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.address}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.phoneNumber}
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="row border-bottom pb-2 mt-2 px-0">
//                                     <p className="mb-0">NEW OWNER</p>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         First Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerFirstName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                         Last Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerLastName}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Address
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerAddress}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Phone number
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerPhoneNumber}
//                                         </div>
//                                     </div>

//                                     <div className="col-6">
//                                         <div className="label">
//                                             Email
//                                         </div> 
//                                     </div> 

//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         {vehicleOrderServices?.newOwnerEmail}
//                                         </div>
//                                     </div>
//                                 </div>  
//                             </>
                            
//                             :

//                             <div className="row border-bottom pb-2 mt-2 px-0">
//                                 <div className="col-6">
//                                     <div className="label">
//                                     First Name
//                                     </div> 
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.firstName}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                     Last Name
//                                     </div> 
//                                 </div>
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.lastName}
//                                     </div>
//                                 </div>


//                                 <div className="col-6">
//                                     <div className="label">
//                                         Email
//                                     </div> 
//                                 </div> 
//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.email}
//                                     </div>
//                                 </div> 

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Phone number
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.phoneNumber}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         Address
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.address}
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         State
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
                                    
//                                     </div>
//                                 </div>

//                                 <div className="col-6">
//                                     <div className="label">
//                                         LGA
//                                     </div> 
//                                 </div> 

//                                 <div className="col-6 text-end">
//                                     <div className="value">
//                                     {vehicleOrderServices?.localGovernment?.name}
//                                     </div>
//                                 </div>
//                             </div>
//                         }
                          
                        


//                         <div className="row  pb-2 mt-2">
//                             <div className="col-12"> 
//                                 <div className="form-group d-flex form-crm-input">
//                                     <input type="checkbox" id="requiredCRM" className='my-auto me-2'  onChange={handleUseDeliveryDetails}/> 
//                                     <label htmlFor="requiredCRM" className='my-auto'>Use different information for delivery details (The address your documents get delivered to)</label>
//                                 </div>  
//                             </div> 
//                         </div>  


//                     </div>
//                 </div>
//             </div>  
 
//             <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">
                 
//                     <div className="card document-details-card  mt-md-0 p-0 border-0">

//                     <div className="card-header px-0 pt-3 mx-4">
//                         <div className="row h-100">
//                             <div className="col-md-12 text-center my-auto d-inline-flex">
//                             Summary
//                             </div>
//                         </div>
//                     </div>
 
//                     <div className="card-body pt-2 px-0"> 
//                         <div className="container-fluid">
//                             <div className="row px-4">
                            
//                                 <div className="col-12 ps-0"> 
//                                     <div className="document_to_get">Documents you will get</div>
//                                 </div>

//                                     {newVehicleRegistrationDocuments?.map((doc: VehicleDocument, index:number)=>{
//                                     return <div key={index} className="col-md-6">
//                                     <div className="row label-inner">
//                                                 <div className="col-1 px-0">
//                                                     <input type='checkbox' id={doc.documentName} checked={checkDocument(doc)} name={doc.documentName} className={doc.isRequired? "": "active"} value={doc.documentName} onChange={(e) => handleSelectDocument(index, e)}></input>
//                                                 </div>

//                                                 <div className="col-11">
//                                                     <label htmlFor={doc.documentName}>{doc.documentName}</label>
//                                                 </div>  
//                                             </div>     
//                                         </div>     
//                                     })}
//                             </div>
//                         </div>

//                         <div className="service-type px-3 py-3 mt-3">

//                             <div className="container-fluid h-100">
//                                 <div className="row h-100">
//                                     <div className="col-12 my-auto">
//                                        <div className="title">Service type</div> 
//                                        {serviceDeliveryType && serviceDeliveryType.map((serviceType:ServiceDeliveryType, index:Key)=>{ 
//                                         return <div className="row h-100 mt-2" key={index}>
//                                             <div className="col-8 type my-auto d-flex h-100">
//                                                <input type="radio" name="serice_type" id={`standard_delivery_${serviceType.uuid}`} value={JSON.stringify(serviceType)} onChange={handleServiceDeliveryType}/> <label htmlFor={`standard_delivery_${serviceType.uuid}`} className='ms-2 my-auto'> {serviceType.service_name} -  days </label>
//                                             </div>
//                                             <div className="col-4 amount h-100 text-end">
//                                                ₦{CurrencyFormatter(Number(serviceType.price))}
//                                             </div>
//                                         </div>
//                                          })}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="processing_time px-4">
//                            <div className="row h-100">
//                             <div className="col-6 title my-auto">Processing Time</div>
//                             <div className="col-6 time my-auto">{vehicleOrderServices?.registrationService.processingTime} days</div>
//                            </div>
//                         </div>

//                         <div className="total-balance px-4 border-0"> 
//                             <div className="row h-100 pt-4">    
//                                 <div className="col-6 my-auto">
//                                     Total
//                                 </div>
//                                 <div className="col-6 text-end my-auto">
//                                    ₦{CurrencyFormatter(Number(vehicleOrderServices?.totalAmount))}
//                                 </div> 
//                             </div> 
//                         </div>
//                     </div>
//                 </div> 

//                  <button className="btn checkout-btn" disabled={showDeliveryDetailsForm && !deliveryDetailsFormIsValid} onClick={makePayment}>Make Payment {requestPayment && <span className="spinner-border spinner-border-sm"></span>}</button>
             
//             </div>  


//             {showDeliveryDetailsForm &&
//                 <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-4">
//                     <div className="card px-4 py-3 border-0 delivery-details">
//                         <div className="card-header profile-title px-0 border-bottom pb-3">
//                             Delivery Details
//                         </div>
//                         <div className="card-body pt-0 px-0">   
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="address">Address</label>
//                                 <input type="text" id="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} {...register('address', { required: "This field is required", onChange: handleDeliveryForm})}/>
//                                 {errors.address && <small className='error'>{errors.address.message}</small>} 
//                             </div>
//                             <div className='form-group mt-3' title="Service available within Lagos. Other locations coming soon.">
//                                 <label htmlFor="state">State</label>
//                                 <select disabled id="state" className={`form-control ${errors.state ? 'is-invalid' : ''}`} {...register('state', { required: "This field is required", onChange: (e) => {handleState(e); handleDeliveryForm(e)}})}>
//                                     <option>select state</option>
//                                     {states?.map((stateItem: State, index: Key)=><option key={index} value={stateItem.uuid}>{stateItem.name}</option>)}
//                                 </select>
//                                 {errors.state && <small className='error'>{errors.state.message}</small>} 
//                             </div>
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="lga">L.G.A</label>
//                                 <select id="lga" className={`form-control ${errors.localGovernmentUuid ? 'is-invalid' : ''}`} {...register('localGovernmentUuid', { required: "This field is required", onChange: handleDeliveryForm})}>
//                                     <option>select L.G.A</option>
//                                     {lgas?.map((lga:Lga, index: Key)=><option key={index} value={lga.id}>{lga.name}</option>)}
//                                 </select>
//                                 {errors.localGovernmentUuid && <small className='error'>{errors.localGovernmentUuid.message}</small>} 
//                             </div>  
//                             <div className='form-group mt-3'>
//                                 <label htmlFor="contactName">Contact Name</label>
//                                 <input type="text" id="contactName" className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`} 
//                                 {...register("contact_name", { required: "This field is required", onChange: handleDeliveryForm})} />
//                                 {errors.contact_name && <small className='error'>{errors.contact_name.message}</small>} 
//                             </div>

//                             <div className='form-group mt-3'>
//                                 <label htmlFor="contact_phone">Phone Number</label>
//                                 <input type="text" id="contact_phone" className={`form-control ${cssPhNumber} ${errors.contact_phone ? 'is-invalid' : ''}`} 
//                                 // {...register("contact_phone", { required: "This field is required"})}  onChange={(e) => {checkPhNo(e.target.value); handleChange(); handleDeliveryForm(e)}}/>
//                                 // {...register("contact_phone", { required: "This field is required", validate: validatePhoneNumber, onChange:(e) =>{ handleChange(); handleDeliveryForm(e)}})}  onChange={(e) => {checkPhNo(e.target.value)}}/>
//                                 {...register("contact_phone", { required: "This field is required", validate: validatePhoneNumber, onChange:(e) =>{ handleChange(); handleDeliveryForm(e)}})}/>
//                                 {errors.contact_phone && <small className='error'>{errors.contact_phone.message}</small>} 
//                             </div>
                    
//                         </div>
//                         <button className="btn checkout-btn" disabled={!deliveryDetailsFormIsValid} onClick={processDeliveryInformation}>Save Details {processingDeliveryDetails && <span className="spinner-border spinner-border-sm"></span>}</button>
//                     </div>
//                 </div>
//             }

//             {!showDeliveryDetailsSummary &&
//                 <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-4">
//                     <div className="card border-0 mt-3 mt-md-0 px-4">
//                         <div className="card-header bg-white pt-3 border-bottom px-0">
//                             Delivery Details
//                         </div>
//                         <div className="card-body pt-0 px-0">
//                                 <>
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Address
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         --
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         State
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         --
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         L.G.A
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         --
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Contact Name
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         --
//                                         </div>
//                                     </div>
                                
//                                     <div className="col-6">
//                                         <div className="label">
//                                         Contact Number
//                                         </div> 
//                                     </div>
//                                     <div className="col-6 text-end">
//                                         <div className="value">
//                                         --
//                                         </div>
//                                     </div>
//                                 </>
//                         </div>
//                     </div>
//                 </div>
//             }

//             {vehicleOrderServices?.registrationService.uuid === CHANGE_OF_OWNERSHIP_SERVICE_ID  &&
//                 <div className="col-12 col-md-6 col-lg-6 col-xl-5 mt-3 mt-md-0">
//                     <div className="card border-0 mt-4 px-4">
//                         <div className="card-header bg-white pt-3 border-bottom px-0">
//                             Documents
//                         </div>
//                         <div className="card-body pt-0 px-0 mt-4">
//                             {vehicleOrderServices.uploadedDocuments.map(document => {
//                                 return (
//                                     <div className="d-flex justify-content-between document-container">
//                                         <div className="d-flex">
//                                             <img src={temporaryPngImageUrl} alt="" height="20px"/>
//                                             <p className="doc-name">{ReplaceCharacter(document.documentName, "_", " ")}</p>
//                                         </div>
//                                         <p className="doc-size">1MB</p>
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                     </div>
//                 </div>
//             }

//             <div className="col-12 col-md-6 col-lg-6 col-xl-5"></div>

//             <ToastContainer 
//                 hideProgressBar={true}
//                 pauseOnHover
//                 autoClose={5000}
//             />


//         </div> 
//     )
// }

// export default RegistrationSummaryItem
