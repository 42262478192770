import React, { useState } from 'react'
import './PhoneNumberInput.scss'
import { countries } from './countries'

interface PhoneInputProps {
    value: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    className?: string;
}


const PhoneNumberInput: React.FC<PhoneInputProps> = ({ value, onChange, onBlur, className }) => {

    const [selectedCountry, setSelectedCountry] = useState<any>({
            name: "Nigeria",
            code: "NG",
            phoneCode: "+234",
            flag: "🇳🇬",
            digitLength: 10
    })

    const selectCountry = (country:any) => {
        setSelectedCountry(country)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneNumber = `${selectedCountry.phoneCode}${e.target.value}`;
        onChange(phoneNumber);
      };

  return (
    <div className='phone-number-input'>
        <p className="country-code">{selectedCountry.phoneCode}</p>
        <input
            type="number"
            value={value?.replace(selectedCountry.phoneCode, '')}
            onChange={handleInputChange}
            onBlur={onBlur}
            className={className}
            placeholder="Enter phone number"
        />
        {/* <input
            type="text"  // Use 'text' to prevent scientific notation issues with large numbers
            value={value?.replace(selectedCountry.phoneCode, '')}
            onChange={(e) => {
                const phone = e.target.value.replace(/\D/g, ''); // Only allow digits
                if (phone.length <= selectedCountry.digitLength) {
                    handleInputChange(e); // Call your handler only if within limit
                }
            }}
            onBlur={onBlur}
            className={className}
            placeholder="Enter phone number"
            maxLength={selectedCountry.digitLength} // Optional safeguard
        /> */}
        <div>
            <div className="dropdown">
            <button className="dropdown-toggl" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="flag-container">
                    {selectedCountry.flag}
                    <div className="caret-container">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9386 1.71289L8.04859 6.60289C7.47109 7.18039 6.52609 7.18039 5.94859 6.60289L1.05859 1.71289" stroke="#6A80A3" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </button>
            <div className="dropdown-menu phone-number-dropdown-menu" aria-labelledby="dropdownMenuButton">
                {countries.map(country => {
                    return (
                        <p className="cursor-pointer" key={country.code} onClick={() => selectCountry(country)}><span className="flag">{country.flag}</span> {country.phoneCode}</p>
                    )
                })}
            </div>
            </div>
        </div>
    </div>
  )
}

export default PhoneNumberInput